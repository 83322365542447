<template>
  <div class="d-print-none">
    <v-btn
      fab
      fixed
      right
      bottom
      @click="show({contentType: pageContentType, expandedSections: []})"
      class="help-border"
      :title="$t('helpBarStrings.openButtonTitle')"
      v-if="!visible && buttonVisible"
    >
          <v-icon tag="span">help_outline</v-icon>
    </v-btn>
    <v-navigation-drawer v-model="visible"
      app
      right
      :width="$vuetify.breakpoint.xsOnly ? '90%' : '40%'"
      temporary>
      <v-card flat>
        <v-btn
          fab
          :small="$vuetify.breakpoint.smAndUp"
          :x-small="$vuetify.breakpoint.xsOnly"
          @click="hide()"
          class="help-border"
          fixed
          top
          right
          :title="$t('helpBarStrings.closeButtonTitle')"
        >
          <template class="d-flex flex-column">
            <v-icon tag="span">close</v-icon>
          </template>
        </v-btn>
        <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{$t('helpBarStrings.title')}}</h2></v-card-title>
        <v-card-subtitle>
          <h2 class="text-h2 mt-10 mb-7">{{$t(`enums.helpBarContentTypes.${contentType}`)}}</h2>
        </v-card-subtitle>
        <v-card-text>
          <help-content></help-content>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
.fixed-position-middle {
  top: 40%;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import HelpBarContentTypes from "@/enums/HelpBarContentTypes";
import HelpContent from "./components/HelpContent";

export default {
  name: "HelpBar",
  components: {
    HelpContent
  },
  methods: {
    ...mapMutations({
      show: "helpBarStore/show",
      hide: "helpBarStore/hide"
    })
  },
  computed: {
    ...mapGetters({
      visibleSetting: "helpBarStore/visible",
      contentType: "helpBarStore/contentType",
      expandedSections: "helpBarStore/expandedSections"
    }),
    pageContentType(){
        switch (this.$route.name) {
        case "createApplication":
          return HelpBarContentTypes.Application;
        case "orgApplications":
          return HelpBarContentTypes.OrgApplications;
        case "orgApplicationDetails":
          return HelpBarContentTypes.ApplicationPreview;
        case "backoffice":
          return HelpBarContentTypes.OrgSettings;
        case "applications":
          return HelpBarContentTypes.Applications;
        default:
          return HelpBarContentTypes.None;
      }
    },
    visible: {
      get() {
        return this.visibleSetting;
      },
      set(value) {
        if (!value) {
          this.hide();
        }
      }
    },
    buttonVisible() {
      return this.pageContentType !== HelpBarContentTypes.None;
    }
  }
};
</script>