<template>
  <v-card>
    <v-card-title class="py-0"
      ><h2 class="text-h2 mt-10 mb-7">
        {{ $t("financialDimensionStrings.title") }}
      </h2>
    </v-card-title>
    <v-card-text>
      <v-data-table
        item-key="id"
        :headers="headers"
        :items="financialDimensions"
        :no-data-text="
          this.$t('financialDimensionStrings.dimensionsTable.noData')
        "
        :no-results-text="
          this.$t('financialDimensionStrings.dimensionsTable.noResults')
        "
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <financial-dimension-edit-dialog
              :dimension="selectedFinancialDimension"
              :dimensionCategories="categoryTypes"
              :otherDimensions="otherDimensions"
              @cancelled="onEditCancel"
              @addNew="onAddNew"
              @saved="onSaved"
            >
            </financial-dimension-edit-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.categoryType="{ item }">{{
          getCategoryName(item.categoryType)
        }}</template>
        <template v-slot:item.active="{ item }">{{
          getBoolString(item.active)
        }}</template>
        <template v-slot:item.editAction="{ item }">
          <v-btn
            small
            rounded
            class="primary-inverted-button my-2"
            @click="onEdit(item)"
            >{{ $t("commonStrings.edit") }}</v-btn
          >
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { api, urls } from "@/utils/api";
import FinancialDimensionEditDialog from "./FinancialDimensionEditDialog.vue";
import FinancialDimensionCategoryTypes from "@/enums/FinancialDimensionCategoryTypes";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "FinancialDimensionSettings",
  components: { FinancialDimensionEditDialog },
  props: {
    selectedOrgId: Number
  },
  data() {
    return {
      orgConfiguration: {},
      selectedFinancialDimension: {},
      categoryTypes: [],
      headers: [
        {
          text: this.$t(
            "financialDimensionStrings.dimensionsTable.headers.category"
          ),
          value: "categoryType"
        },
        {
          text: this.$t(
            "financialDimensionStrings.dimensionsTable.headers.name"
          ),
          value: "name"
        },
        {
          text: this.$t(
            "financialDimensionStrings.dimensionsTable.headers.code"
          ),
          value: "code"
        },
        {
          text: this.$t(
            "financialDimensionStrings.dimensionsTable.headers.active"
          ),
          value: "active"
        },
        {
          value: "editAction",
          sortable: false
        }
      ],
      financialDimensions: []
    };
  },
  computed: {
    ...mapGetters({
      currentOrgId: "contextStore/orgId",
      urlState: "utilsRestStore/urlState"
    }),
    loading() {
      return this.urlState(urls.v1.orgs.financialDimensions(this.orgId));
    },
    orgId() {
      return this.selectedOrgId ?? this.currentOrgId;
    },
    otherDimensions() {
      return this.financialDimensions.filter(
        x => x.id !== this.selectedFinancialDimension.id
      );
    }
  },
  methods: {
    ...mapActions({
      getOrgConfiguration: "settings/orgConfigurationStore/getOrgConfiguration"
    }),
    async getCategories() {
      const settings = await this.getOrgConfiguration(this.orgId);
      const customizedCategories =
        settings.financialDimensionCategories
          .filter(x => x.categoryName)
          .map(x => ({
            value: x.categoryType,
            text: x.categoryName
          })) ?? [];

      const fixedCategories = Object.values(
        FinancialDimensionCategoryTypes
      ).map(x => ({
        value: x,
        text: this.$t(`enums.financialDimensionCategoryTypes.${x}`)
      }));

      const result = [
        fixedCategories[0],
        fixedCategories[1],
        ...customizedCategories
      ];

      return result;
    },
    getCategoryName(categoryType) {
      const category = this.categoryTypes.find(x => x.value === categoryType);
      if (category) {
        return category.text;
      }

      return this.$t("commonStrings.notDefined");
    },
    getBoolString(item) {
      if (item) {
        return this.$t("commonStrings.yes");
      } else {
        return this.$t("commonStrings.no");
      }
    },
    setDefaultFinancialDimension(id) {
      this.$set(this, "selectedFinancialDimension", {
        id: id,
        orgId: this.orgId,
        categoryType: 0,
        code: "",
        name: "",
        active: true
      });
    },
    onEdit(item) {
      this.$set(this, "selectedFinancialDimension", item);
    },
    onAddNew() {
      this.setDefaultFinancialDimension(0);
    },
    onEditCancel() {
      this.setDefaultFinancialDimension(-1);
    },
    async onSaved() {
      this.setDefaultFinancialDimension(-1);
      await this.fetch();
    },
    async fetch() {
      try {
        const response = await api.v1.orgs.financialDimensions(this.orgId);
        if (response.status === 200 && response.data) {
          this.financialDimensions = response.data;
        }
      } catch (error) {
        await handleError(error);
      }
    }
  },
  async mounted() {
    this.orgConfiguration = await this.getOrgConfiguration(this.orgId);
    await this.fetch();
    this.categoryTypes = await this.getCategories();
  }
};
</script>
