<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="py-0">
          <h2 class="text-h2 mt-10 mb-7">
            {{ $t("communicationPanelStrings.title") }}
          </h2>
        </v-card-title>
        <v-card-text>
          <v-row v-if="canAddComment">
            <v-col>
              <v-alert
                type="info"
                border="left"
                colored-border
                dense
                prominent
                dismissible
                elevation="1"
              >
                {{ $t("communicationPanelStrings.visibilityInfoText") }}<br />
                <br />
                {{ $t("communicationPanelStrings.senderEmailInfoText") }}<br />
                {{ $t("communicationPanelStrings.recipientEmailInfoText")
                }}<br />
                {{
                  $t("communicationPanelStrings.recipientPersonEmailInfoText")
                }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" order-md="2" v-if="canAddComment">
              <v-card color="background-active">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h3 class="text-h3">
                        {{ $t("communicationPanelStrings.newCommentSubtitle") }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        multiple
                        item-value="id"
                        item-text="name"
                        chips
                        small-chips
                        deletable-chips
                        :items="displayOrgs"
                        v-model="reply.recipients"
                        :label="
                          $t(
                            'communicationPanelStrings.placeholders.recipients'
                          )
                        "
                        name="payingOrgs"
                        dense
                        outlined
                        background-color="white"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <validation-observer ref="observer">
                        <validation-provider
                          rules="min:3|max:500"
                          :name="
                            $t(
                              'communicationPanelStrings.placeholders.replyContent'
                            )
                          "
                          v-slot="{ errors }"
                        >
                          <v-textarea
                            :label="
                              $t(
                                'communicationPanelStrings.placeholders.replyContent'
                              )
                            "
                            v-model="reply.content"
                            clearable
                            :error-messages="errors"
                            outlined
                            dense
                            counter="500"
                            rows="4"
                            auto-grow
                            background-color="white"
                            @input="$emit('comment-changed', reply.content)"
                          ></v-textarea>
                        </validation-provider>
                      </validation-observer>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        rounded
                        class="primary-button"
                        :block="$vuetify.breakpoint.xsOnly"
                        :disabled="!reply.content || sendingReply"
                        @click="sendReply"
                        :loading="sendingReply"
                      >
                        {{
                          $t("communicationPanelStrings.labels.sendReplyBtn")
                        }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
              order-md="2"
              v-if="showCannotAddCommentWarning"
            >
              <v-alert type="warning">
                {{ $t("communicationPanelStrings.disabledText") }}
              </v-alert>
            </v-col>
            <v-col cols="12" md="6" order-md="2" v-if="isArchived">
              <v-alert type="warning">
                {{ $t("communicationPanelStrings.disabledByArchivedText") }}
              </v-alert>
            </v-col>
            <v-col cols="12" md="6" order-md="1">
              <v-card>
                <v-card-text>
                  <v-row class="background-active temp-margin-fix">
                    <v-col>
                      <v-row>
                        <v-col>
                          <h3 class="text-h5">
                            {{ $t("communicationPanelStrings.filterSubtitle") }}
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <button-filter
                            v-model="communicationTypeFilter"
                            :initial="communicationTypeFilter"
                            :options="communicationTypeOptions"
                            :clearing.sync="clearingCommunicationTypeFilter"
                            mandatory
                            css-class="flex-column flex-md-row"
                          ></button-filter>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            :label="
                              $t(
                                'communicationPanelStrings.labels.filterCommentsForOrg'
                              )
                            "
                            background-color="white"
                            multiple
                            item-value="id"
                            item-text="name"
                            chips
                            small-chips
                            deletable-chips
                            :items="displayOrgs"
                            v-model="orgsRelatedComments"
                            dense
                            outlined
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="hasFilteredEvents">
                      <v-timeline dense>
                        <template v-for="(event, index) in filteredEvents">
                          <comment-bubble
                            v-if="isComment(event)"
                            :comment="event"
                            :key="index"
                            :own-comment="isOwnComment(event)"
                          ></comment-bubble>
                          <log-event
                            v-else
                            :event="event"
                            :key="index"
                          ></log-event>
                        </template>
                      </v-timeline>
                    </v-col>
                    <v-col v-if="!hasFilteredEvents">
                      <em>{{
                        $t("communicationPanelStrings.emptyCommentSection")
                      }}</em>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import LogEvent from "./components/LogEvent";
import CommentBubble from "./components/CommentBubble";
import { getOrgDisplayName } from "@/utils/org";
import { api, urls } from "@/utils/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import validationErrors from "@/mixins/validationErrors";
import ButtonFilter from "@/views/common/components/ButtonFilter";
import CommunicationTypes from "@/enums/CommunicationTypes";
import { mapGetters } from "vuex";
import { handleError } from "@/utils/errorHandler";
import ApplicationDatabaseSource from "@/enums/ApplicationDatabaseSource";

export default {
  name: "CommunicationPanel",
  props: {
    log: Array,
    orgs: Array,
    comments: Array,
    applicationId: String,
    applicationPersonId: Number,
    currentUser: Object,
    currentOrgId: Number,
    canWriteAsOrg: Boolean,
    isDraft: Boolean,
    initializing: Boolean,
    source: Number
  },
  mixins: [validationErrors],
  components: {
    LogEvent,
    CommentBubble,
    ValidationProvider,
    ValidationObserver,
    ButtonFilter
  },
  data: function () {
    return {
      reply: {
        content: "",
        orgId: null,
        personId: null,
        recipients: []
      },
      communicationTypeFilter: this.getCommunicationTypeOptions().map(
        x => x.value
      ),
      communicationTypeOptions: this.getCommunicationTypeOptions(),
      orgsRelatedComments: [],
      clearingCommunicationTypeFilter: false
    };
  },
  computed: {
    ...mapGetters({
      urlState: "utilsRestStore/urlState"
    }),
    filteredEvents() {
      let events = [];

      if (this.log && this.showLogEvents) {
        for (const logEvent of this.log) {
          events.push(logEvent);
        }
      }

      if (this.comments && this.showComments) {
        for (const comment of this.comments) {
          if (
            !this.orgsRelatedComments.length ||
            comment.recipients.some(x => this.orgsRelatedComments.includes(x))
          ) {
            events.push(comment);
          }
        }
      }

      events = events.sort((x, y) => new Date(x.date) - new Date(y.date));

      return events;
    },
    hasFilteredEvents() {
      return this.filteredEvents?.length;
    },
    showLogEvents() {
      return this.communicationTypeFilter.includes(CommunicationTypes.Log);
    },
    showComments() {
      return this.communicationTypeFilter.includes(CommunicationTypes.Comment);
    },
    showCannotAddCommentWarning() {
      return !this.initializing && !this.canAddComment && !this.isArchived;
    },
    commentAsReceiver() {
      return this.applicationPersonId !== this.currentPersonId;
    },
    currentPersonId() {
      if (!this.currentUser) {
        return null;
      }

      return parseInt(this.currentUser.personid, 10);
    },
    displayOrgs() {
      return this.orgs.map(x => ({ id: x.id, name: getOrgDisplayName(x) }));
    },
    sendingReply() {
      if (this.applicationId) {
        return this.urlState(urls.v1.applications.reply(this.applicationId));
      }
      return false;
    },
    canAddComment() {
      return (
        !this.isArchived &&
        !this.isDraft &&
        (this.canWriteAsOrg ||
          this.applicationPersonId === this.currentPersonId)
      );
    },
    isArchived() {
      return this.source === ApplicationDatabaseSource.Archived;
    }
  },
  methods: {
    isComment(event) {
      return event.logEventType === undefined || event.logEventType === null;
    },
    isOwnComment(comment) {
      if (!this.isComment(comment)) {
        return false;
      }

      if (comment.sender.orgId) {
        return comment.sender.orgId === this.currentOrgId;
      }

      return comment.sender.personId === this.currentPersonId;
    },
    async sendReply() {
      // validation
      const isDataValid = await this.$refs.observer.validate();

      if (!isDataValid) {
        this.showValidationErrorSnackbar(this.$refs.observer);
        return;
      }

      // init reply sender
      if (this.commentAsReceiver) {
        this.reply.orgId = this.currentOrgId;
      } else {
        this.reply.orgId = null;
      }

      this.reply.personId = this.currentPersonId;

      // send reply
      try {
        const response = await api.v1.applications.reply(
          this.applicationId,
          this.reply
        );

        if (response.status === 201) {
          this.reply.content = "";
          this.reply.recipients = [];

          this.$emit("reply", response.data);
          this.$emit("comment-changed", this.reply.content);
        }
      } catch (error) {
        await handleError(error);
      }
    },
    getCommunicationTypeOptions() {
      return [
        {
          value: CommunicationTypes.Log,
          label: this.$t("communicationPanelStrings.labels.showLogEvents")
        },
        {
          value: CommunicationTypes.Comment,
          label: this.$t("communicationPanelStrings.labels.showComments")
        }
      ];
    }
  },
  mounted() {
    this.clearingCommunicationTypeFilter = true;
  }
};
</script>

<style scoped>
/* Should probably be fixed through markup or other css fixes, but this at least properly aligns the active background with the borders for now*/
.background-active.temp-margin-fix {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  margin: -16px;
  padding: 12px 12px 0 12px;
}
</style>