<template>
  <v-skeleton-loader type="article, button" :loading="loading">
    <v-card>
      <validation-observer ref="observer">
        <v-card-title class="py-0">
          <h1 class="text-h2 mt-10 mb-7">
            {{ $t("generalSettingsStrings.title") }}
          </h1>
          <v-icon
            tag="span"
            class="ml-3"
            @click.stop="onGeneralSettingsHelpClick"
            >help_outline</v-icon
          >
        </v-card-title>
        <v-card-text>
          <common-settings
            :org-configuration="orgConfiguration"
            :relation-activation-rights="relationActivationRights"
            :disable-critical-settings="disableCriticalSettings"
          >
          </common-settings>
          <h2 class="text-h2 mt-10 mb-7">
            {{ $t("generalSettingsStrings.paymentMethodsTitle") }}
          </h2>
          <buypass-settings
            :org-configuration="orgConfiguration"
            :relation-activation-rights="relationActivationRights"
            :org-id="selectedOrgId"
          >
          </buypass-settings>
          <product-account-settings
            :product-accounts="orgConfiguration.productAccounts"
            :org-type-id="selectedOrgTypeId"
          >
          </product-account-settings>
          <integration-settings
            :org-configuration="orgConfiguration"
            :integration-configurations="integrationConfigurations"
            :org-type-id="selectedOrgTypeId"
            :disable-critical-settings="disableCriticalSettings"
          >
          </integration-settings>
          <financial-dimension-categories
          :org-configuration="orgConfiguration"
          ></financial-dimension-categories>
        </v-card-text>

        <v-card-actions>
          <v-btn
            rounded
            class="primary-button"
            @click="save"
            :disabled="!hasChanges || isSaving"
            :loading="isSaving"
            >{{ $t("generalSettingsStrings.save") }}</v-btn
          >
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
import ObjectId from "bson-objectid";
import { compare } from "fast-json-patch";
import { api, urls } from "@/utils/api";
import { copy } from "@/utils/object";
import { mapGetters, mapMutations } from "vuex";
import { ValidationObserver } from "vee-validate";
import _ from "lodash";
import validationErrors from "@/mixins/validationErrors";
import CommonSettings from "./CommonSettings.vue";
import BuypassSettings from "./BuypassSettings.vue";
import ProductAccountSettings from "./ProductAccountSettings.vue";
import IntegrationSettings from "./IntegrationSettings.vue";
import claimPanel from "@/mixins/claimPanel";
import HelpBarContentTypes from "@/enums/HelpBarContentTypes";
import Products from "@/enums/Products";
import AccountingIdValues from "@/enums/AccountingIdValues";
import FinancialDimensionCategories from "./FinancialDimensionCategories.vue";
import { handleError } from "@/utils/errorHandler";
import OrgTypes from '@/enums/OrgTypes';

export default {
  name: "GeneralSettings",
  components: {
    ValidationObserver,
    CommonSettings,
    BuypassSettings,
    ProductAccountSettings,
    IntegrationSettings,
    FinancialDimensionCategories
  },
  mixins: [validationErrors, claimPanel],
  data() {
    return {
      loading: true,
      originalState: {},
      orgConfiguration: {
        orgId: null,
        isActive: false,
        canChildrenActivate: false,
        integrationSettings: [],
        buypassSettings: {
          isActive: false,
          settlementAccountId: null
        }
      },
      relationActivationRights: {
        isActivationPermitted: false,
        hasChildrenRequiringActivationPermission: false,
        isChildPermissionDeactivationPermitted: false,
        areBuypassSettingsPermitted: false,
        hasChildUsingParentAccount: false
      },
      integrationConfigurations: [],
      financialDimensionCategories: []
    };
  },
  props: {
    selectedOrgId: Number,
    selectedOrgTypeId: Number
  },
  computed: {
    hasChanges() {
      return compare(this.originalState, this.orgConfiguration)
        .length;
    },
    ...mapGetters({
      urlState: "utilsRestStore/urlState",
      isSuperuser: "currentOrgsStore/superuser"
    }),
    isPosting() {
      return this.urlState(urls.v1.orgConfigurations.post());
    },
    isPatching() {
      if (this.originalState?.id) {
        return this.urlState(
          urls.v1.orgConfigurations.patch(this.originalState.id)
        );
      }

      return false;
    },
    isSaving() {
      return this.isPosting || this.isPatching;
    },
    disableCriticalSettings() {
      return this.selectedOrgTypeId === OrgTypes.SF && !this.isSuperuser;
    }
  },
  methods: {
    ...mapMutations({
      setOrgConfiguration: "settings/orgConfigurationStore/setOrgConfiguration"
    }),
    onGeneralSettingsHelpClick() {
      this.showHelpBar(HelpBarContentTypes.OrgSettings, [0]);
    },
    async fetch() {
      try {
        const response = await api.v1.orgs.orgConfigurations(
          this.selectedOrgId
        );
        if (response.status === 200) {
          this.relationActivationRights = copy(
            response.data.relationActivationRights
          );

          if (response.data.orgConfiguration) {
            this.orgConfiguration = copy(response.data.orgConfiguration);
            this.originalState = copy(response.data.orgConfiguration);
          } else {
            this.orgConfiguration = copy(this.getEmptyOrgConfiguration());
            this.$set(this.orgConfiguration, "orgId", this.selectedOrgId);
            this.originalState = copy(this.orgConfiguration);
          }
        }
      } catch (error) {
        await handleError(error);
      }

      try {
        const response = await api.v1.integrationConfigurations.get();
        if (response.status === 200) {
          this.integrationConfigurations = response.data;
        } else {
          this.integrationConfigurations = [];
        }
      } catch (error) {
        await handleError(error);
      }

      this.loading = false;
      this.$emit("general-settings-loaded");
    },
    async save() {
      try {
        const isDataValid = await this.$refs.observer.validate();

        if (!isDataValid) {
          this.showValidationErrorSnackbar(this.$refs.observer);
          return;
        }

        const integrationConfigurationIds = this.orgConfiguration.integrationSettings.map(
          x => x.integrationConfigurationId
        );

        if (
          _.uniq(integrationConfigurationIds).length !==
          integrationConfigurationIds.length
        ) {
          throw this.$t(
            "generalSettingsStrings.inconsistentIntegrationConfigurationsError"
          );
        }

        if (ObjectId.isValid(this.originalState.id)) {
          await this.patchChanges();
        } else {
          // document is new, should be posted
          await this.postChanges();
        }
      } catch (error) {
        await handleError(error);
      }
    },
    async patchChanges() {
      const patchDoc = compare(
        this.originalState,
        this.orgConfiguration
      );

      const response = await api.v1.orgConfigurations.patch(
        this.originalState.id,
        patchDoc
      );
      if (response.status === 204) {
        this.originalState = copy(this.orgConfiguration);
        this.$snacks.add(this.$t("generalSettingsStrings.successfulSaved"),
          "success"
        );
      }
    },
    async postChanges() {
      const response = await api.v1.orgConfigurations.post(
        this.orgConfiguration
      );

      if (response.status === 201) {
        if (response.data?.orgConfiguration) {
          this.orgConfiguration = copy(response.data.orgConfiguration);
          this.originalState = copy(response.data.orgConfiguration);
        }
        this.$snacks.add(this.$t("generalSettingsStrings.successfulSaved"),
          "success"
        );
      }
    },
    getEmptyOrgConfiguration() {
      return {
        orgId: null,
        isActive: false,
        canChildrenActivate: false,
        integrationSettings: [],
        buypassSettings: {
          isActive: false,
          settlementAccountId: null
        },
        productAccounts: [
          {
            productId: Products.PayableAccount,
            accountingId: AccountingIdValues.PayableAccount
          },
          {
            productId: Products.BuypassAccount,
            accountingId: AccountingIdValues.BuypassAccount
          },
          {
            productId: Products.BankAccount,
            accountingId: AccountingIdValues.BankAccount
          }
        ]
      };
    }
  },
  watch: {
    orgConfiguration: {
      handler: function orgConfiguration(orgConfig) {
        this.setOrgConfiguration(orgConfig);
      },
      deep: true
    },
    selectedOrgId: {
      handler: async function selectedOrgId(orgId) {
        if (orgId) {
          await this.fetch();
        }
      },
      immediate: true
    },
    hasChanges(val) {
      this.$emit("has-changes-changed", val);
    }
  }
};
</script>