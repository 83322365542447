const journalStrings = {
  name: "Bilag",
  pageTitle: "@:journalStrings.name",
  pageHeader: "@:journalStrings.name",
  toDetails: "Til kravdetaljer",
  sender: {
    title: "@:commonStrings.sender"
  },
  stillPendingWarning: `Dette kravet er ikke godkjent av alle mottakere.
   Dersom kravet utbetales, og det kommer endringer for den som har utbetalt, må dette håndteres manuelt mellom partene.`,
  receiver: {
    title: "Mottaker"
  },
  meta: {
    title: "Informasjon om kravet",
    name: "Refusjonsnavn:",
    createdDate: "Opprettet:",
    approvedDate: "Godkjent:",
    approvedBy: "Godkjent av:",
    accountNumber: "Kontonummer:",
    uniqueId: "Unik ID:",
    paidDate: "Utbetalt:",
    paidWith: "Utbetalt med:"
  },
  table: {
    title: "Utregning",
    categoryTotal: "Sum:",
    total: "Sum refusjon til utbetaling:",
    header: {
      type: "Type",
      account: "@:commonStrings.financeAccount",
      date: "@:commonStrings.date",
      count: "@:commonStrings.count",
      rate: "Sats",
      amount: "@:commonStrings.amount",
      comment: "@:commonStrings.comment"
    },
    passenger: "@.lower:commonStrings.passenger",
    passengerCount: "@:commonStrings.passengerCount",
    kilometers: "km",
    categories: {
      salary: "Honorar",
      driving: "Kjøregodtgjørelse",
      diet: "Diett",
      dietWithStay: "@:journalStrings.table.categories.diet (med overnatting)",
      dietWithoutStay: "@:journalStrings.table.categories.diet (uten overnatting)",
      dietNightAddition: "@:journalStrings.table.categories.diet (@:dietPanelStrings.stayTableHeaders.nightAddition)",
      receipt: "Kvittering"
    }
  }
};

export default journalStrings;