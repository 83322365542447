import Vue from 'vue';
import App from './App.vue';
import store from '@/store';
import axios from 'axios';

import 'material-design-icons-iconfont/dist/material-design-icons.css';

import vuetify from '@/plugins/vuetify';
import router from '@/plugins/router';
import i18n from '@/plugins/i18n';
import '@/plugins/moment';
import '@/plugins/vee-validate';
import '@/plugins/appinsights';
import { snackbarQueue } from '@nifnpm/vue-snackbar-queue';
import { toMoney } from '@/utils/numeric';
import VueMask from 'v-mask';

import AppLayout from '@/views/common/layouts/AppLayout';
import PublicLayout from '@/views/common/layouts/PublicLayout';

const onError = function (error) {
  store.dispatch("utilsRestStore/setActiveRequest", {
    url: error.config.url,
    state: false
  });
  return Promise.reject(error);
};

axios.interceptors.request.use(function (config) {
  store.dispatch("utilsRestStore/setActiveRequest", {
    url: config.url,
    state: true
  });
  return config;
}, onError);

axios.interceptors.response.use(function (response) {
  store.dispatch("utilsRestStore/setActiveRequest", {
    url: response.config.url,
    state: false
  });
  return response;
}, onError);

Vue.component('app-layout', AppLayout);
Vue.component('public-layout', PublicLayout);

Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_DEV_TOOLS;

Vue.use(snackbarQueue, { i18n: i18n });
Vue.use(VueMask);

Vue.filter('toMoney', toMoney);

export default new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');