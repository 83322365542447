<template>
  <v-dialog v-model="dialog" max-width="500" :key="currentIndex" persistent>
    <template v-slot:activator="{ on }">
      <v-btn :disabled="!enableAddNew" small rounded class="primary-inverted-button" v-on="on">
        <v-icon tag="span" left>mdi-plus</v-icon>
        {{ $t("drivingPanelStrings.labels.addButton") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>
      <v-card-text>
        <validation-observer ref="tripsObserver">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <claim-date-picker
                  :claim="item"
                  :label="$t('drivingPanelStrings.tableHeaders.date')"
                  :allowed-dates="
                    (x) => $moment(x).isSameOrBefore($moment(), 'days')
                  "
                  @claim-date-change="updateTripAmount(item)"
                  :key="currentIndex"
                ></claim-date-picker>
              </v-col>
              <v-col cols="12" md="6">
                <claim-type-picker
                  @claim-type-change="updateTripAmount(item)"
                  :claim="item"
                  configuration-type-property="rateType"
                  :filter-enabled-types-func="filterEnabledVehicleTypes"
                  resources-property="enums.vehicleTypes"
                  model-binding-property="claimTypeId"
                  :validation-name="
                    $t('drivingPanelStrings.tableHeaders.vehicleType')
                  "
                  :label="$t('drivingPanelStrings.tableHeaders.vehicleType')"
                  :key="currentIndex"
                ></claim-type-picker>
              </v-col>
              <v-col cols="12" v-if="canUseSalaryFraction">
                <v-checkbox
                  color="primary"
                  :label="$t('commonStrings.salaryFractionLabel')"
                  v-model="item.usesSalaryFraction"
                  :disabled="!salaryFractionSplitAvailable"
                  :key="currentIndex"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <paying-orgs-picker
                  @paying-orgs-change="updateTripAmount(item)"
                  :claim="item"
                  :filter-enabled-types-func="filterEnabledVehicleTypes"
                  configuration-type-property="rateType"
                  claim-type-property="claimTypeId"
                  :validation-name="
                    $t('drivingPanelStrings.tableHeaders.payingOrgs')
                  "
                  :label="$t('drivingPanelStrings.tableHeaders.payingOrgs')"
                  :key="currentIndex"
                ></paying-orgs-picker>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider
                  rules="required|min:3|max:60"
                  :name="$t('drivingPanelStrings.tableHeaders.from')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="item.from"
                    :label="$t('drivingPanelStrings.tableHeaders.from')"
                    :error-messages="errors"
                    :hint="$t('drivingPanelStrings.hints.from')"
                    counter="60"
                    :key="currentIndex"
                    outlined
                    dense
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider
                  rules="required|min:3|max:60"
                  :name="$t('drivingPanelStrings.tableHeaders.to')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="item.to"
                    :error-messages="errors"
                    :hint="$t('drivingPanelStrings.hints.to')"
                    counter="60"
                    :label="$t('drivingPanelStrings.tableHeaders.to')"
                    :key="currentIndex"
                    outlined
                    dense
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                  rules="required|decimal:1|min_value:1"
                  :name="$t('drivingPanelStrings.validationLabels.tripLength')"
                  v-slot="{ errors }"
                >
                  <decimal-text-field
                    v-model="item.kilometers"
                    :error-messages="errors"
                    :hint="$t('drivingPanelStrings.hints.tripLength')"
                    @input="updateTripAmount(item)"
                    :label="
                      $t('drivingPanelStrings.validationLabels.tripLength')
                    "
                    :key="currentIndex"
                  ></decimal-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                  rules="name_list:2,2|max_list_items:16|max_list_item_length:60"
                  :name="$t('drivingPanelStrings.validationLabels.passengers')"
                  :custom-messages="{
                    name_list: $t(
                      'drivingPanelStrings.customErrors.passengers.name_list'
                    ),
                  }"
                  v-slot="{ errors }"
                >
                  <v-combobox
                    :multiple="true"
                    :chips="true"
                    small-chips
                    :deletable-chips="true"
                    :error-messages="errors"
                    v-model="item.passengers"
                    @change="updatePassengers(item)"
                    :hint="$t('drivingPanelStrings.hints.passengers')"
                    :delimiters="[';', ',']"
                    :append-icon="null"
                    :label="
                      $t('drivingPanelStrings.validationLabels.passengers')
                    "
                    :key="currentIndex"
                    outlined
                    dense
                  ></v-combobox>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <amount-label
                  :label="$t('drivingPanelStrings.tableHeaders.amount')"
                  :value="item.amount"
                ></amount-label>
              </v-col>
            </v-row>
          </v-container>
        </validation-observer>
      </v-card-text>
      <v-card-actions class="pa-5 flex-column flex-sm-row">
        <v-spacer></v-spacer>
        <v-btn
          rounded
          class="primary-inverted-button mb-2 mb-sm-0"
          @click="cancel"
          :block="$vuetify.breakpoint.xsOnly"
          small
        >
          {{ $t("salaryPanelStrings.labels.cancelButton") }}
        </v-btn>
        <v-btn
          rounded
          class="primary-button ml-0 ml-sm-2"
          @click="save"
          :block="$vuetify.breakpoint.xsOnly"
          small
        >
          {{ saveLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ClaimDatePicker from "../components/ClaimDatePicker";
import ClaimTypePicker from "../components/ClaimTypePicker";
import PayingOrgsPicker from "../components/PayingOrgsPicker";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import validationErrors from "@/mixins/validationErrors";
import AmountLabel from "@/views/common/components/AmountLabel";
import DecimalTextField from "@/views/common/components/DecimalTextField";
import { mapGetters } from "vuex";

export default {
  name: "TripEditDialog",
  components: {
    ClaimDatePicker,
    ClaimTypePicker,
    PayingOrgsPicker,
    ValidationProvider,
    ValidationObserver,
    AmountLabel,
    DecimalTextField
  },
  mixins: [validationErrors],
  data() {
    return {
      dialog: false
    };
  },
  props: {
    item: {
      required: true
    },
    updateTripAmount: {
      required: true
    },
    currentIndex: {
      required: true
    },
    canUseSalaryFraction: {
      required: true
    },
    enableAddNew:{
      required: true,
      type: Boolean
    }
  },
  watch: {
    currentIndex(val) {
      if (val >= 0) {
        this.dialog = true;
      }
    },
    dialog(val) {
      if (val && this.currentIndex < 0 && this.$refs.tripsObserver) {
          this.$refs.tripsObserver.reset();
      }
    }
  },
  computed: {
    ...mapGetters({
      salaryFractionSplitAvailable:
        "application/salaryFractionsStore/salaryFractionSplitAvailable",
      frac: "application/salaryFractionsStore/fractions"
    }),
    saveLabel() {
      if (this.currentIndex < 0) {
        return this.$t("drivingPanelStrings.labels.insertButton");
      } else {
        return this.$t("drivingPanelStrings.labels.updateButton");
      }
    },
    formTitle() {
      if (this.currentIndex < 0) {
        return this.$t("drivingPanelStrings.labels.insertEditDialogTitle");
      } else {
        return this.$t("drivingPanelStrings.labels.updateEditDialogTitle");
      }
    }
  },
  methods: {
    updatePassengers(claim) {
      claim.passengers = claim.passengers.map(x =>
        x.trim().replace(/\s\s+/g, " ")
      );

      return this.updateTripAmount(claim);
    },
    filterEnabledVehicleTypes(conf) {
      return conf["vehicleRates"]["vehicleRateTypes"].filter(x => x.enabled);
    },
    close() {
      this.dialog = false;
    },
    cancel() {
      this.$emit("edit-cancelled");
      this.close();
    },
    async save() {
      const isDataValid = await this.$refs.tripsObserver.validate();

      if (!isDataValid) {
        this.showValidationErrorSnackbar(this.$refs.tripsObserver);
        return;
      }

      if (this.currentIndex >= 0) {
        this.$emit("item-changed", {
          claim: this.item,
          index: this.currentIndex
        });
      } else {
        this.$emit("new-item-added", this.item);
      }

      this.close();
    }
  }
};
</script>