<template>
  <div class="orgFeesInvoice">
    <v-card>
      <v-card-title class="py-0"><h1 class="text-h2 mt-10 mb-7">{{ $t("orgFeesInvoiceStrings.pageTitle") }}</h1></v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <text-field-date-picker
              v-model="fromDate"
              :label="$t('commonStrings.from')"
            ></text-field-date-picker>
          </v-col>
          <v-col cols="12" sm="4">
            <text-field-date-picker
              v-model="toDate"
              :label="$t('commonStrings.to')"
            ></text-field-date-picker>
          </v-col>
          <v-col cols="12" sm="4">
            <v-btn
              :disabled="isExporting"
              :loading="isExporting"
              rounded
              class="primary-button"
              @click="exportOrgFeesInvoice"
              :block="$vuetify.breakpoint.xsOnly">
              {{ $t("orgFeesInvoiceStrings.exportButton") }}
              </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="exportDialog" max-width="500px">
      <v-card>
        <v-card-title>
          {{ $t("orgApplicationsStrings.exportInfoModalStrings.title") }}
        </v-card-title>
        <v-card-text>
          <p>
            {{ $t("orgApplicationsStrings.exportInfoModalStrings.infoP1") }}
          </p>
          <p>
            {{ $t("orgApplicationsStrings.exportInfoModalStrings.infoP2") }}
          </p>
          <p>
            {{ $t("orgApplicationsStrings.exportInfoModalStrings.infoP3") }}
          </p>
        </v-card-text>
        <v-card-actions class="pa-5 flex-column flex-sm-row">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            class="primary-button mb-2 mb-sm-0"
            small
            @click="toggleExportModal">
            {{ $t("commonStrings.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { api, urls } from "@/utils/api";
import TextFieldDatePicker from "@/views/common/components/TextFieldDatePicker";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "OrgFeesInvoicePage",
  components: {
    TextFieldDatePicker
  },
  data() {
    return {
      exportDialog: false,
      fromDate: this.$moment()
        .subtract(1, "months")
        .format(this.$t("commonStrings.dateValueFormat")),
      toDate: this.$moment().format(this.$t("commonStrings.dateValueFormat"))
    };
  },
  computed: {
    ...mapGetters({
      urlState: "utilsRestStore/urlState"
    }),
    isExporting() {
      return this.urlState(urls.v1.applications.exportOrgFeesInvoice());
    }
  },
  methods: {
    toggleExportModal() {
      this.exportDialog = !this.exportDialog;
    },
    async exportOrgFeesInvoice() {
        this.toggleExportModal();
      try {

        const response = await api.v1.applications.exportOrgFeesInvoice(
          {
            fromDate: this.fromDate,
            toDate: this.toDate
          },
          { responseType: "blob" }
        );

        if (response.status === 200) {
          const disposition = response.headers["content-disposition"];
          const name = disposition.split("filename*=UTF-8''")[1];
          const decoded = decodeURIComponent(name);
          const url = window.URL.createObjectURL(response.data);
          //insert temporary link in DOM and trigger click to download file
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", decoded);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
          link.remove();
        }

        if (response.status === 204) {
          this.$snacks.add(this.$t("orgFeesInvoiceStrings.notFoundWarning"),
            "warning"
          );
        }
      } catch (error) {
        await handleError(error);
      }
    }
  }
};
</script>