<template>
  <v-dialog v-model="visible" max-width="450px">
    <v-card>
      <v-card-title>
        {{ $t("previewApplicationPageStrings.confirmRejectApplicationDialog.title") }}
      </v-card-title>
      <v-card-text>
        <p>{{ $t("previewApplicationPageStrings.confirmRejectApplicationDialog.visibilityInfoText") }}</p>
        <p>{{ $t("previewApplicationPageStrings.confirmRejectApplicationDialog.commentInfoText") }}</p>
        <p>{{ $t("previewApplicationPageStrings.confirmRejectApplicationDialog.confirmationInfoText") }}</p>
      </v-card-text>
      <v-card-actions class="pa-5 flex-column flex-sm-row">
        <v-spacer></v-spacer>
        <v-btn
          rounded
          :block="$vuetify.breakpoint.xsOnly"
          class="primary-inverted-button mb-2 mb-sm-0"
          small
          @click="cancelClicked"
        >
          {{
            $t(
              "previewApplicationPageStrings.confirmRejectApplicationDialog.labels.cancelButton"
            )
          }}
        </v-btn>
        <v-btn
          rounded
          :block="$vuetify.breakpoint.xsOnly"
          class="primary-button ml-0 ml-sm-2"
          small
          @click="confirmClicked"
        >
          {{
            $t(
              "previewApplicationPageStrings.confirmRejectApplicationDialog.labels.confirmButton"
            )
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmRejectApplicationDialog",
  props: {
    visible: Boolean
  },
  methods: {
    cancelClicked() {
      this.$emit("cancel");
    },
    confirmClicked() {
      this.$emit("confirm");
    }
  }
};
</script>