import { api } from "@/utils/api";
import { copy } from "@/utils/object";
import Vue from '@/main';

const currentApplicationStore = {
    namespaced: true,
    state: {
        application: {},
        persons: [],
        orgs: [],
        payingOrgIds: []
    },
    mutations: {
        setApplication(state, application) {
            state.application = copy(application);
        },
        setPersons(state, persons) {
            state.persons = copy(persons);
        },
        setOrgs(state, orgs) {
            state.orgs = copy(orgs);
        },
        setPayingOrgIds(state, payingOrgIds) {
            state.payingOrgIds = copy(payingOrgIds);
        },
        addOrg(state, org) {
            state.orgs.push(copy(org));
        },
        setCurrentApplicationIsDraft(state, value) {
            if ('isDraft' in state.application) {
                state.application.isDraft = value;
                state.application.rateConfigurations = null;
            }
        },
        deleteApplication(state) {
            Vue.$set(state, "application", {});
            Vue.$set(state, "persons", []);
            Vue.$set(state, "orgs", []);
            Vue.$set(state, "payingOrgIds", []);
        }
    },
    actions: {
        async fetch(context, id) {
            //First fetch application
            const response = await api.v1.applications.getById(id);

            if (response.status === 200) {
                if (!response.data) {
                    Vue.$t("errorMessages.missingDataFromServer");
                }

                context.commit("setApplication", response.data.application);
                context.commit("setOrgs", response.data.orgs);
                context.commit("setPersons", response.data.persons);
                context.commit("setPayingOrgIds", response.data.payingOrgIds);

                if (response.data.application.federationId) {
                    context.dispatch("settings/orgConfigurationStore/fetch", {
                        orgId: response.data.application.federationId,
                        functionalityId: response.data.application.functionalityId
                    }, { root: true });
                }

                // set initial values for salary fractions
                // for existing applications
                if (response.data.application.claims?.salaries?.length) {
                    const payingOrgIds = response.data.application.claims.salaries.map(x => x.payingOrgs).flat();
                    context.commit("application/salaryFractionsStore/setOrgIds", payingOrgIds, {root: true});
                }
            } else {
                // everything different than 200
                // should be treated as error and
                // handled in router / other components
                throw response;
            }
        },
        async addRelatedOrg(context, orgId) {
            const currentOrgIds = context.state.orgs.map(x => x.id);

            if (currentOrgIds.indexOf(orgId) >= 0) {
                // org already part of application
                return;
            }

            const response = await api.v1.orgs.getById(orgId);

            if (response.status === 200) {
                context.commit("addOrg", response.data);
            } else {
                // everything different than 200
                // should be treated as error and
                // handled in router / other components
                throw response;
            }
        }
    },
    getters: {
        id: state => state.application.id,
        application: state => state.application,
        federationId: state => state.application.federationId,
        persons: state => state.persons,
        orgs: state => state.orgs,
        payingOrgIds: state => state.payingOrgIds
    }
};

export default currentApplicationStore;