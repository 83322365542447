<template>
  <v-skeleton-loader :loading="loading" type="card-heading, table-thead, table-row-divider@8">
    <v-card>
      <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{$t('rateConfigurationStrings.receiptTitle')}}</h2></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="8" xl="6">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                      >{{$t('rateConfigurationStrings.receiptTypeTableActiveHeader')}}</th>
                      <th class="text-left">{{$t('rateConfigurationStrings.receiptTypeTableTypeHeader')}}</th>
                      <th
                        class="text-left"
                      >{{$t('rateConfigurationStrings.receiptTypeTableRequiresAttachmentHeader')}}</th>
                      <th
                        class="text-left"
                      >{{$t('rateConfigurationStrings.receiptTypeTableAccountingHeader')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in filteredReceiptRateTypes" :key="item.rateType">
                      <td>
                        <validation-provider rules :vid="getReceiptEnabledValidationId(item.rateType)">
                          <v-checkbox
                            color="primary"
                            v-model="item.enabled"
                            :aria-label="$t('commonStrings.tableAriaLabel',[$t('rateConfigurationStrings.receiptTypeTableActiveHeader'),
                              $t(`enums.receiptTypes.${item.rateType}`)])"
                          ></v-checkbox>
                        </validation-provider>
                      </td>
                      <td>{{ $t(`enums.receiptTypes.${item.rateType}`) }}</td>
                      <td>
                        <v-checkbox
                          color="primary"
                          v-model="item.requiresAttachment"
                          :aria-label="$t('commonStrings.tableAriaLabel',[$t('rateConfigurationStrings.receiptTypeTableRequiresAttachmentHeader'),
                            $t(`enums.receiptTypes.${item.rateType}`)])"
                          :disabled="!item.enabled"
                        ></v-checkbox>
                      </td>
                      <td>
                        <validation-provider
                          rules="finance_account"
                          :name="$t('rateConfigurationStrings.receiptTypeTableAccountingHeader')"
                          v-slot="{ errors }"
                          :vid="getReceiptAccountingIdValidationId(item.rateType)"
                        >
                          <v-text-field
                            v-model="item.accountingId"
                            outlined
                            dense
                            :error-messages="errors"
                            :aria-label="$t('commonStrings.tableAriaLabel',[$t('rateConfigurationStrings.receiptTypeTableAccountingHeader'),
                              $t(`enums.receiptTypes.${item.rateType}`)])"
                            :disabled="!item.enabled"
                          ></v-text-field>
                        </validation-provider>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import InactiveReceiptTypes from "@/enums/InactiveReceiptTypes";

export default {
  name: "ReceiptSettings",
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    receiptRates: {
      type: Object,
      required: true
    }
  },
  components: {
    ValidationProvider
  },
  computed: {
    filteredReceiptRateTypes() {
      return this.receiptRates.receiptRateTypes.filter(x => Object.values(InactiveReceiptTypes).every(y => y !== x.rateType));
    }
  },
  methods: {
    getReceiptEnabledValidationId(rateType) {
      return `enabled_receiptType_${rateType}`;
    },
    getReceiptAccountingIdValidationId(rateType) {
      return `accountingId_receiptType_${rateType}`;
    }
  }
};
</script>