import { OrgApplicationFiltersLocalStorageKey } from "./localStorageConstants.js";

// Clears any stored date filters on page load, as this may have changed
// since earlier session. Should however be retained when navigating between
// pages.
export const setDefaultSearchCriteriaDates = function (personid) {
  if (!personid) {
    return;
  }

  const existingFilters = JSON.parse(localStorage.getItem((`${OrgApplicationFiltersLocalStorageKey}${personid}`)));

  if (existingFilters) {
    existingFilters.fromDateFilter = "";
    existingFilters.toDateFilter = "";

    localStorage.setItem(`${OrgApplicationFiltersLocalStorageKey}${personid}`, JSON.stringify(existingFilters));
  }
};
