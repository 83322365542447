const publicPageStrings = {
  senderHeader: "Sende inn eller se dine egne krav",
  senderBody: "Vi oppgraderer gradvis våre tjenester. Innsending og behandling av dine egne krav finner du nå i Min idrett.",
  recipientHeader: "Behandle krav for organisasjonen",
  recipientBody: "Behandling av krav på vegne av ditt organisasjonsledd utføres fortsatt i Idrettsoppgjør.",
  senderLoginButton: "Fortsett til Min idrett",
  recipientLoginButton: "Fortsett til Idrettsoppgjør",
  getStarted: "Har du ikke Idrettens ID?",
  getStartedLink: "Kom i gang",
  getStartedLink2: "Opprett ny bruker",
  registrationCaptionText: "Det er gratis og uforpliktende å opprette en Idrettens ID bruker.",
  readMore: "Les mer om Idrettsoppgjør",
  termsLink: "Brukervilkår"
};

export default publicPageStrings;