/*
    RULE 1! A 24 hour day starts at the start of the trip.
    RULE 2! Durations below 6 hours are not eligible.
    RULE 3! Must travel at least 15 Km from home or place of work.
    RULE 4! Partial and full day diet durations must not be mixed, it's either or.
    RULE 5! A duration must have at least 5 hours inbetween 22:00 and 06:00 to count as a full day.
        If a duration starts after 01:00 or ends before 03:00 they are partial day diets.
        To be full days they must therefore span 22:00 - 03:00, or 01:00 - 06:00.
    RULE 6! After each 24 hour days, 6 hours into the next 24 hour duration counts as a full day.
        Less then 6 hours into next day gives nothing.
        So if one day is from 08:00 to 08:00 (next day), then at 14:00 (that day) you are eligible for another day.
    RULE 7! Night addition can either be by itself or in combination with Dorm or Boarding house.
        Night addition can not be combined with lenth type diets, or stays at a hotel.
    RULE 8! Diet deductions does not deducted from the night addition amount, only the length type amount, or stay type amount.
        Diet deductions are done after a rate is divided by paying orgs or salary fraction.
    RULE 9! Stay amounts are divided between payings orgs with rate configurations and an amount > 0 for the type and date the stay was on, or salary fraction.
        If an org does not have a rate configuration or no amount > 0 for the date, the other org will pay the whole amount.
    RULE 10! Night addition is presented by itself in the journal, but combined with the stay everywhere else.
*/

import Vue from "@/main";
import { toMoney, toPercent } from "@/utils/numeric";
import _ from "lodash";
import DietStayTypes from "@/enums/DietStayTypes";
import DietDeductionTypes from "@/enums/DietDeductionTypes";
import DietDurationTypes from "@/enums/DietDurationTypes";
import store from '@/store';
import { calculateSalaryFractionCoefficientForOrg, getSalaryFractionsCountForOrg, getTotalSalaryFractionsCount } from "@/utils/claims/salary";

const getRateConfiguration = store.getters['application/rateConfigurationsStore/rateConfiguration'];

export const getDietStayType = function (stay, dietRates) {
    return dietRates.dietStayRateTypes.find(y => y?.enabled && y.rateType === stay.dietStayTypeId);
};

export const getDietLengthType = function (stay, dietRates) {
    return dietRates.dietLengthRateTypes.find(y => y?.enabled && y.rateType === stay.dietLengthTypeId);
};

export const getNightAdditionType = function (dietRates) {
    return dietRates.dietStayRateTypes.find(y => y?.enabled && y.rateType === DietStayTypes.NightAddition);
};

export const getNightAdditionRate = function (orgId, date) {
    const dietRates = getDietRatesForOrgByDay(orgId, date);

    const nightAdditionRate = dietRates.dietStayRateTypes.find(x => x?.enabled && x.rateType === DietStayTypes.NightAddition);

    if (nightAdditionRate) {
        return nightAdditionRate.rate;
    }

    return 0;
};

export const getDietRateName = function (stay, excludeNightAddition) {
    const nightAdditionName = Vue.$t(`enums.dietStayTypes.${DietStayTypes.NightAddition}`);
    const onlyHasNightAddition = stayOnlyHasNightAddition(stay);

    if (!stay.dietLengthTypeId && !stay.dietStayTypeId && !onlyHasNightAddition) {
        return "";
    }

    if (onlyHasNightAddition) {
        return nightAdditionName;
    }

    let name = "";

    if (stay.dietLengthTypeId) {
        name = Vue.$t(`enums.dietLengthTypes.${stay.dietLengthTypeId}`);
    }

    if (stay.dietStayTypeId) {
        name = Vue.$t(`enums.dietStayTypes.${stay.dietStayTypeId}`);
    }

    if (stay.hasNightAddition && !excludeNightAddition) {
        name = `${name} + ${nightAdditionName}`;
    }

    return name;
};

export const getDietAccountingId = function (stay, rates) {
    if (stay.hasNightAddition) {
        return rates.nightAdditionAccountingId;
    }

    return rates.accountingId;
};

export const getDietRate = function (stay, dietRates) {
    const onlyHasNightAddition = stayOnlyHasNightAddition(stay);

    if (!stay.dietLengthTypeId && !stay.dietStayTypeId && !onlyHasNightAddition) {
        return 0;
    }

    if (onlyHasNightAddition) {
        const rateType = getNightAdditionType(dietRates);

        if (rateType) {
            return rateType.rate;
        }
    }

    if (stay.dietLengthTypeId) {
        const rateType = getDietLengthType(stay, dietRates);

        if (rateType) {
            return rateType.rate;
        }
    }

    if (stay.dietStayTypeId) {
        const rateType = getDietStayType(stay, dietRates);

        if (rateType) {
            return rateType.rate;
        }
    }

    return 0;
};

export const stayOnlyHasNightAddition = function (stay) {
    return stay.hasNightAddition && !stay.dietStayTypeId && !stay.dietLengthTypeId;
};

export const getDietRatesForOrgByDay = function (orgId, date) {
    return getRateConfiguration(orgId, Vue.$moment(date), "dietRates");
};

export const getDietRateForOrg = function (stay, orgId) {
    const dietRates = getDietRatesForOrgByDay(orgId, stay.date);

    if (!dietRates) {
        return 0;
    }

    return getDietRate(stay, dietRates);
};

export const getPayingFractionForOrg = function (orgId, payingOrgs, salaryFraction) {
    if (salaryFraction) {
        return calculateSalaryFractionCoefficientForOrg(orgId);
    }

    if (!payingOrgs) {
        return 0;
    }

    if (payingOrgs?.length) {
        return 1 / payingOrgs.length;
    }

    return 0;
};

export const getStayDeductionRate = function (stay) {
    return _.sum(stay.dietDeductionIds.map(x => getDeductionRate(x)));
};

export const getDividedNightAddition = function (payingPart, nightAdditionRate) {
    if (payingPart) {
        return nightAdditionRate * payingPart;
    }

    return nightAdditionRate;
};

export const getDividedAndSubtractedDietRate = function (rate, payingPart, percentageToSubtract) {
    const amount = getPayingPartRateFraction(rate, payingPart);
    const deductionAmount = getDeductionAmount(amount, percentageToSubtract);

    return amount - deductionAmount;
};

const getPayingPartRateFraction = function (rate, payingPart) {
    if (payingPart) {
        return rate * payingPart;
    }

    return rate;
};

const getDeductionAmount = function (amount, percentageToSubtract) {
    if (percentageToSubtract) {
        return (amount * percentageToSubtract) / 100;
    }

    return 0;
};

export const calculateDietAmountForOrg = function (stay, orgId, payingOrgs, salaryFraction, excludeNightAddition) {
    const payingPart = getPayingFractionForOrg(orgId, payingOrgs, salaryFraction);
    const nightAdditionRate = getNightAdditionRate(orgId, stay.date);

    if (stayOnlyHasNightAddition(stay) && !excludeNightAddition) {
        return getDividedNightAddition(payingPart, nightAdditionRate);
    }

    const rateVal = getDietRateForOrg(stay, orgId);
    const percentage = getStayDeductionRate(stay);

    const subtracted = getDividedAndSubtractedDietRate(rateVal, payingPart, percentage);

    if (stay.hasNightAddition && !excludeNightAddition) {
        return subtracted + getDividedNightAddition(payingPart, nightAdditionRate);
    }

    return subtracted;
};

export const calculateDietAmountForStay = function (stay, payingOrgs, salaryFraction) {
    return _.sum(payingOrgs.map(orgId => calculateDietAmountForOrg(stay, orgId, payingOrgs, salaryFraction)));
};

export const getDaysInPeriod = function (fromDateString, toDateString) {
    const startMoment = Vue.$moment(fromDateString);
    const endMoment = Vue.$moment(toDateString);
    const duration = Vue.$moment.duration(endMoment.diff(startMoment));

    if (duration.asHours() < dietRestrictionConstants.minimalDurationHours) {
        return [];
    }

    return Array.from(
        Vue.$moment.range(startMoment, endMoment).by("days")
    )
        .map(x => x.format())
        .slice(0, 31);
    //Only allow 30 days, this gives us 30 days and some hours.
};

const getPayingOrgsCount = function (payingOrgs) {
    if (payingOrgs) {
        return payingOrgs.length;
    }

    return 0;
};

export const getStayExplanationForOrg = function (orgId, stay, payingOrgs, salaryFraction) {
    const hasNightAddition = stay.hasNightAddition && !stayOnlyHasNightAddition(stay);
    const nightAdditionRate = getNightAdditionRate(orgId, stay.date);
    const nightAdditionLabel = `${toMoney(nightAdditionRate)}`;
    const typeName = getDietRateName(stay);
    const rateValue = getDietRateForOrg(stay, orgId);
    const payingOrgsCount = getPayingOrgsCount(payingOrgs);
    const ratePercentage = getStayDeductionRate(stay);
    let calculation = "";

    if (salaryFraction) {
        calculation = getSalaryFractionExplanation(rateValue, ratePercentage, hasNightAddition, nightAdditionLabel, payingOrgsCount, orgId);
    } else if (payingOrgsCount > 1) {
        calculation = getMultipleOrgStayExplanation(rateValue, ratePercentage, hasNightAddition, nightAdditionLabel, payingOrgsCount);
    } else {
        calculation = getSingleOrgStayExplanation(rateValue, ratePercentage, hasNightAddition, nightAdditionLabel);
    }

    if (calculation) {
        calculation = `${typeName}: ${calculation}`;
    }

    return calculation;
};

const getSalaryFractionExplanation = function (rateValue, ratePercentage, hasNightAddition, nightAdditionLabel, payingOrgsCount, orgId) {
    let calculation = "";

    const parts = getSalaryFractionsCountForOrg(orgId);
    const totalParts = getTotalSalaryFractionsCount();

    if (parts !== totalParts) {
        calculation = Vue.$tc("summaryPanelStrings.salaryFractionExplanation", parts, { amount: toMoney(rateValue), total: totalParts, parts });
    } else {
        calculation = `${toMoney(rateValue)}`;
    }

    if (ratePercentage && rateValue) {
        calculation = `(${calculation}) - ${toPercent(
            ratePercentage / 100
        )} ${Vue.$t(
            "dietPanelStrings.stayTableHeaders.meals"
        ).toLocaleLowerCase()}`;
    }

    if (hasNightAddition) {
        calculation = `(${calculation}) + (${nightAdditionLabel} / ${payingOrgsCount})`;
    }

    return calculation;
};

const getMultipleOrgStayExplanation = function (rateValue, ratePercentage, hasNightAddition, nightAdditionLabel, payingOrgsCount) {
    let calculation = `${toMoney(rateValue)} / ${payingOrgsCount}`;

    if (ratePercentage && rateValue) {
        calculation = `(${calculation}) - ${toPercent(
            ratePercentage / 100
        )} ${Vue.$t(
            "dietPanelStrings.stayTableHeaders.meals"
        ).toLocaleLowerCase()}`;
    }

    if (hasNightAddition) {
        calculation = `(${calculation}) + (${nightAdditionLabel} / ${payingOrgsCount})`;
    }

    return calculation;
};

const getSingleOrgStayExplanation = function (rateValue, ratePercentage, hasNightAddition, nightAdditionLabel) {
    let calculation = `${toMoney(rateValue)}`;

    if (ratePercentage && rateValue) {
        calculation = `${calculation} - ${toPercent(
            ratePercentage / 100
        )} ${Vue.$t(
            "dietPanelStrings.stayTableHeaders.meals"
        ).toLocaleLowerCase()}`;

        if (hasNightAddition) {
            calculation = `(${calculation}) + (${nightAdditionLabel})`;
        }
    } else if (hasNightAddition) {
            calculation = `${calculation} + ${nightAdditionLabel}`;
        }

    return calculation;
};

export const getValidStays = function (stays, excludeNightAddition) {
    return stays.filter(x => x.dietStayTypeId || x.dietLengthTypeId || (stayOnlyHasNightAddition(x) && !excludeNightAddition));
};

export const getDietClaimsForOrg = function (orgId, claims, prefix) {
    if (!claims?.diet?.payingOrgs?.includes(orgId)) {
        return [];
    }

    return getValidStays(claims.diet.stays).map(function (stay, index) {
        return {
            id: [prefix, index].join("_"),
            name: Vue.$moment(stay.date).format(Vue.$t("commonStrings.dateDisplayFormat")),
            amount: calculateDietAmountForOrg(stay, orgId, claims.diet.payingOrgs, claims.diet.usesSalaryFraction),
            details: getStayExplanationForOrg(orgId, stay, claims.diet.payingOrgs, claims.diet.usesSalaryFraction)
        };
    });
};

export const getStayDurationTypes = function (fromDateString, toDateString) {
    const startMoment = Vue.$moment(fromDateString);
    const endMoment = Vue.$moment(toDateString);
    const days = getDaysInPeriod(fromDateString, toDateString);
    const duration = Vue.$moment.duration(endMoment.diff(startMoment));
    const dates = {};
    const durationHours = duration.asHours();

    // We can return early on this and < 24 as we only have one day.
    if (durationHours < 12) {
        dates[startMoment.dayOfYear()] = {
            dietDurationType: DietDurationTypes.SixToTwelveHours
        };
        return dates;
    }

    if (durationHours < 24) {
        dates[startMoment.dayOfYear()] = {
            dietDurationType: DietDurationTypes.Above12Hours
        };
        return dates;
    }

    if (
        days?.length &&
        hasFiveHoursAtNight(fromDateString, toDateString)
    ) {
        //Loop over every day in durationDays and check if they are eligible for DietDurationTypes.WithOvernight
        //by checking if the date + 6 hours is before the end moment. If they are the trip is still not done and you should have another overnight stay,
        //but if current date + hours is more then the endMoment the last day was not eligible for a overnight stay.
        //First date will allways get WithOvernight since hasFiveHoursAtNight must be true for us to be here, and date + 6
        //will be way less then what the end moment must be at this point.
        //One implicit fact here that allows us to just add 6 hours, is that the moment we get from the range for each day has the same time as the starting date.
        days.forEach(d => {
            const date = Vue.$moment(d);
            const key = date.dayOfYear();
            date.add(6, "h");
            const validForNight = date.isSameOrBefore(endMoment);
            if (validForNight) {
                dates[key] = { dietDurationType: DietDurationTypes.WithOvernight };
            } else {
                dates[key] = { dietDurationType: DietDurationTypes.NotEligible };
            }
        });
    }
    return dates;
};

export const getDeductionRate = function (dietDeductionTypeId) {
    const taxRates = store.getters['currentTaxRatePeriodStore/currentPeriod'];

    const deductionRates = {
        [DietDeductionTypes.Breakfast]: getBreakfastDeductionRate(taxRates),
        [DietDeductionTypes.Lunch]: getLunchDeductionRate(taxRates),
        [DietDeductionTypes.Dinner]: getDinnerDeductionRate(taxRates)
    };

    return deductionRates[dietDeductionTypeId];
};

const getBreakfastDeductionRate = function (taxRates) {
    if(taxRates) {
        return taxRates.breakfastDeductionRate;
    }

    return 0;
};

const getLunchDeductionRate = function (taxRates) {
    if(taxRates) {
        return taxRates.lunchDeductionRate;
    }

    return 0;
};

const getDinnerDeductionRate = function (taxRates) {
    if(taxRates) {
        return taxRates.dinnerDeductionRate;
    }

    return 0;
};


export const hasFiveHoursAtNight = function (fromDate, toDate) {
    if (!fromDate || !toDate) {
        return false;
    }

    const range = Vue.$moment.range(fromDate, toDate);
    const durationHours = range.diff('hours');

    // duration is less than 24 hours, not eligible for
    // overnight request
    if (durationHours < 24) {
        return false;
    }

    // duration is at least 25 consecutive hours, will always cover
    // edge cases and be eligible for overnight request by default
    if (durationHours >= 25) {
        return true;
    }

    // handle edge case where the duration is more
    // than 24 hours and less than 25 hours, meaning
    // that there was 24 hours period without covering
    // 5 hours between 22:00 and 06:00
    // this can happen only if start time is between
    // 01:01 and 02:59 inclusively, meaning that maximum amount of
    // night time will be 4 hours 59 minutes, less than needed 5

    const startHour = range.start.hour();
    const startMinute = range.start.minute();

    // minute check is to support edge cases for
    // 01:00 and 03:00, where check should be inclusive
    // otherwise it should be exclusive

    if (startMinute === 0) {
      return (startHour <= dietRestrictionConstants.minimalNotEligibleStartHour || startHour >= dietRestrictionConstants.maximalNotEligibleStartHour);
    }

    return (startHour < dietRestrictionConstants.minimalNotEligibleStartHour || startHour > dietRestrictionConstants.maximalNotEligibleStartHour);
};

export const dietRestrictionConstants = {
    minimalSufficientDistance: 15,
    minimalDurationHours: 6,
    minimalNotEligibleStartHour: 1, // 01:00,
    maximalNotEligibleStartHour: 3 // 03:00
};