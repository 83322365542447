import { api } from "@/utils/api";
import _ from "lodash";
import {
  isBackofficeEnabled,
  isApprovalEnabled,
  isPaymentMethodSelectionEnabled,
  isPaymentSignatureEnabled,
  isPayoutEnabled,
  isOrgApplicationEnabled,
  isDefaultEmailRecipient,
  isSuperuser
} from "@/utils/permissions";
import { handleError } from "@/utils/errorHandler";

const hasElevatedRights = function (item) {
  if (item.backofficeEnabled) {
    return true;
  }
  if (item.approvalEnabled) {
    return true;
  }
  if (item.paymentMethodSelectionEnabled) {
    return true;
  }
  if (item.paymentSignatureEnabled) {
    return true;
  }
  if (item.payoutEnabled) {
    return true;
  }
  return item.orgApplicationEnabled;
};

const currentOrgsStore = {
  namespaced: true,
  state: {
    orgs: [],
    superuser: false,
    functions: [],
    loaded: false
  },
  mutations: {
    setOrgs(state, { orgs, superuser }) {
      state.orgs = orgs;
      state.superuser = superuser;
      state.loaded = true;
    },
    setFunctions(state, functions) {
      state.functions = functions;
    }
  },
  actions: {
    async fetchFunctions(context, payload) {
      if (payload.personId && context.state.functions && !context.state.functions.length) {
        try {
          const functionResponse = await api.v1.persons.functions(
            payload.personId,
            {
              headers: {
                Authorization: `Bearer ${payload.token}`
              }
            }
          );
          if (functionResponse && functionResponse.status === 200 && functionResponse.data) {
            context.commit('setFunctions', functionResponse.data);
          }
        } catch (error) {
          await handleError(error);
        }
      }
    },
    async fetch(context, payload) {
      try {
        const response = await api.v1.persons.orgs(
          payload.personId,
          {
            headers: {
              Authorization: `Bearer ${payload.token}`
            }
          }
        );

        if (response.status === 200) {
          const allOrgs = response.data;
          const functions = context.state.functions;

          const orgsWithElevatedRights = allOrgs
            .map(x => ({
              org: x,
              backofficeEnabled: functions
                .filter(y => y.orgId === x.id)
                .some(y =>
                  isBackofficeEnabled(
                    y.functionTypeId,
                    x.orgTypeId,
                    x.isSingleSport
                  )
                ),
              approvalEnabled: functions
                .filter(y => y.orgId === x.id)
                .some(y =>
                  isApprovalEnabled(
                    y.functionTypeId,
                    x.orgTypeId,
                    x.isSingleSport
                  )
                ),
              paymentMethodSelectionEnabled: functions
                .filter(y => y.orgId === x.id)
                .some(y =>
                  isPaymentMethodSelectionEnabled(
                    y.functionTypeId,
                    x.orgTypeId,
                    x.isSingleSport
                  )
                ),
              paymentSignatureEnabled: functions
                .filter(y => y.orgId === x.id)
                .some(y =>
                  isPaymentSignatureEnabled(
                    y.functionTypeId,
                    x.orgTypeId,
                    x.isSingleSport
                  )
                ),
              payoutEnabled: functions
                .filter(y => y.orgId === x.id)
                .some(y =>
                  isPayoutEnabled(
                    y.functionTypeId,
                    x.orgTypeId,
                    x.isSingleSport
                  )
                ),
              orgApplicationEnabled: functions
                .filter(y => y.orgId === x.id)
                .some(y =>
                  isOrgApplicationEnabled(
                    y.functionTypeId,
                    x.orgTypeId,
                    x.isSingleSport
                  )
                ),
              defaultEmailRecipient: functions
                .filter(y => y.orgId === x.id)
                .some(y =>
                  isDefaultEmailRecipient(
                    y.functionTypeId
                  )
                )

            }))
            .filter(x => hasElevatedRights(x));

          const ordered = _.orderBy(orgsWithElevatedRights, 'org.name', ['asc']);
          const superuser = functions.some(x => isSuperuser(x.functionTypeId));

          context.commit('setOrgs', { orgs: ordered, superuser });
        }
      } catch (error) {
        await handleError(error);
      }
    }
  },
  getters: {
    orgs: state => state.orgs,
    superuser: state => state.superuser,
    loaded: state => state.loaded,
    org: state => id => state.orgs?.find(x => x.org.id === id)
  }
};

export default currentOrgsStore;