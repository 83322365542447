<template>
  <v-skeleton-loader
    :loading="initializing"
    type="table-heading,table-cell@2, divider"
  >
    <v-card :outlined="outlined" :dense="dense" class="text-left fill-height">
      <v-card-title v-if="!dense" class="py-0"><h2 class="text-h2 mt-10 mb-7">{{ title }}</h2></v-card-title>
      <v-card-title v-else class="py-0"><h2 class="text-h2">{{ title }}</h2></v-card-title>
      <v-card-text>
        <v-row class="pb-2">
          <v-col cols="12" xs="12" sm="4" md="2" lg="3" xl="2" v-if="labels" class="py-0 font-weight-bold"> {{
            $t("personInformationStrings.nameLabel")
          }} </v-col>
          <v-col cols="12" xs="12" sm="8" md="10" lg="9" xl="10" class="py-0">{{ name }}</v-col>
        </v-row>
        <v-row class="pb-2">
          <v-col cols="12" xs="12" sm="4" md="2" lg="3" xl="2" v-if="labels" class="py-0 font-weight-bold">{{
            $t("personInformationStrings.addressLabel")
          }}</v-col>
          <v-col cols="12" xs="12" sm="8" md="10" lg="9" xl="10" class="py-0">{{ address }}</v-col>
        </v-row>
        <v-row class="pb-2">
          <v-col cols="12" xs="12" sm="4" md="2" lg="3" xl="2" v-if="labels" class="py-0 font-weight-bold">{{
            $t("personInformationStrings.emailLabel")
          }}</v-col>
          <v-col cols="12" xs="12" sm="8" md="10" lg="9" xl="10" class="py-0">{{ firstEmail }}</v-col>
        </v-row>
        <v-row v-if="person.birthDate" class="pb-2">
          <v-col cols="12" xs="12" sm="4" md="2" lg="3" xl="2" v-if="labels" class="py-0 font-weight-bold">{{
            $t("personInformationStrings.dateOfBirthLabel")
          }}</v-col>
          <v-col cols="12" xs="12" sm="8" md="10" lg="9" xl="10" class="py-0">{{
            person.birthDate | moment($t("commonStrings.dateDisplayFormat"))
          }}</v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
import { api } from "@/utils/api";
import { mapGetters } from "vuex";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "PersonInformation",
  props: {
    initPersonId: Number,
    initApplicationId: String,
    initializing: Boolean,
    title: {
      type: String,
      required: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      person: {}
    };
  },
  computed: {
    ...mapGetters({
      user: "oidcStore/oidcUser"
    }),
    name() {
      return `${this.person.firstName} ${this.person.lastName}`;
    },
    address() {
      return `${this.textWithCommaIfExisting(
        this.person.addressLine1
      )}${this.textWithCommaIfExisting(
        this.person.addressLine2
      )}${this.stringOrEmpty(this.person.postCode)} ${this.person.city}`;
    },
    // Either this or we should replace ; with space or something else that breaks cleanly
    firstEmail() {
      if (this.person.email?.includes(";")) {
        return this.person.email.split(";")[0];
      }

      return this.person.email;
    }
  },
  methods: {
    textWithCommaIfExisting(text) {
      if (text) {
        return `${text}, `;
      }

      return "";
    },
    stringOrEmpty(text) {
      if (text) {
        return text;
      }

      return "";
    },
    async fetchUserInfo(id) {
      try {
        const response = await api.v1.persons.getById(id);

        if (response.status === 200) {
          this.person = response.data;
          this.$emit("update:initPersonId", response.data.personId);
        }
      } catch (error) {
        await handleError(error);
      }

      this.initializing = false;
    },
    async fetchUserInfoByApplication(id) {
      try {
        const response = await api.v1.applications.persons(id);

        if (response.status === 200) {
          this.person = response.data;
        }
      } catch (error) {
        await handleError(error);
      }

      this.initializing = false;
    }
  },
  watch: {
    async initializing(val) {
      if (val) {
        return;
      }

      if (this.initApplicationId) {
        await this.fetchUserInfoByApplication(this.initApplicationId);
      } else {
        await this.fetchUserInfo(this.initPersonId);
      }
    }
  }
};
</script>
