<template>
  <v-container>
    <v-row justify="center">
      <v-progress-circular indeterminate color="primary lighten-1">
      </v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'OidcCallback',
  methods: {
    ...mapActions('oidcStore', [
      'oidcSignInCallback'
    ])
  },
  async created() {
    try {
      const redirectPath = await this.oidcSignInCallback();
      this.$router.push(redirectPath);
    } catch(err) {
      this.$router.push('/signin-oidc-error');
    }
  }
};
</script>
