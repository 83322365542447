import { extend, configure } from 'vee-validate';
import { required, min, max, numeric, min_value, max_value, required_if, integer, length, is_not } from 'vee-validate/dist/rules';
import i18n from './i18n';
import { m } from './moment';

configure({
  // this will be used to generate messages.
  defaultMessage: (_, values) => i18n.t(`validations.${values._rule_}`, values)
});

extend('required', {
  ...required
});

extend("min", {
  ...min
});

extend("max", {
  ...max
});

extend("numeric", {
  ...numeric
});

extend("min_value", {
  ...min_value
});

extend("max_value", {
  ...max_value
});

extend("required_if", {
  ...required_if
});

extend("integer", {
  ...integer
});

extend("length", {
  ...length
});

extend("is_not", {
  ...is_not
});

extend("date", {
  validate: value => {
    const date = m(value, i18n.t('commonStrings.dateDisplayFormat'));

    return date.isValid();
  }
});

extend("time", {
  validate: value => {
    const values = value.split(":");

    const date = m({
      year: 1999,
      month: 1,
      date: 1,
      hour: parseInt(values[0], 10),
      minute: parseInt(values[1], 10)
    });

    return date.isValid();
  }
});

extend("finance_account", {
  validate: value => {
    if (!value?.length) {
      return {
        valid: true
      };
    }
    const re = new RegExp(/^\d{4,5}$/);

    return {
      valid: re.test(value)
    };
  }
});

const getDecimalRegexPart = function (decimals) {
  if (decimals === '*') {
    return '+';
  }

  return `{1,${decimals}}`;
};

extend("decimal", {
  params: ["decimals", "separator"],
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (!value?.length) {
      return {
        valid: true
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value)
      };
    }
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${getDecimalRegexPart(decimals)})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value)
    };
  },
  message: (_, placeholders) => i18n.tc(`validations.decimal`, placeholders.decimals, { ...placeholders })
});

extend("required_if_any", {
  params: ["enabled"],
  validate: (value, { enabled } = {}) => {
    const valid = !enabled || (value && value.length > 0);
    const isRequired = !enabled;

    return {
      valid: !!valid,
      data: {
        required: isRequired
      }
    };
  },
  computesRequired: true,
  hasTarget: true
});

extend("name_list", {
  params: ["min_word_count", "min_word_length"],
  validate: (value, { min_word_count = "1", min_word_length = "1" } = {}) => {
    if (!value?.length) {
      return {
        valid: true
      };
    }

    return {
      valid: value.map(x => x.split(" ")).every(x => x.length >= min_word_count && x.every(y => y.length >= min_word_length))
    };
  }
});

extend("max_list_items", {
  params: ["max_count"],
  validate: (value, { max_count } = {}) => {
    return {
      valid: !value?.length || !max_count || value.length <= max_count
    };
  }
});


extend("max_list_item_length", {
  params: ["max_length"],
  validate: (value, { max_length } = {}) => {
    return {
      valid: value.every(x => x.length <= max_length)
    };
  }
});

const getComputedChecksum = function (remainder) {
  if (remainder > 0) {
    return 11 - remainder;
  }
  return 0;
};

extend("account_number", {
  validate: value => {
    if (!value?.length) {
      return {
        valid: true
      };
    }

    if (value.length !== 11) {
      return {
        valid: false
      };
    }
    if (!(/^\d{11}$/.test(value))) {
      return {
        valid: false
      };
    }

    const checksum = Number.parseInt(value.charAt(10), 10);
    const account = value.substring(0, 10);

    let sum = 0;
    for (let index = 0; index < 10; index++) {
      sum += Number.parseInt(account.charAt(index), 10) * (7 - (index + 2) % 6);
    }

    const remainder = sum % 11;

    const computedChecksum = getComputedChecksum(remainder);

    return {
      valid: computedChecksum === checksum
    };
  }
});

