const httpErrorCodes = {
  networkError: "Nettverksfeil",
  generalError: "Noe gikk galt, prøv igjen senere eller kontakt support om problemet vedvarer.",
  generalErrorWithCode: "Noe gikk galt, prøv igjen senere eller kontakt support om problemet vedvarer. ({0})",
  [400]: "400: Ugyldig forespørsel",
  [401]: "401: Uautorisert",
  [403]: "403: Forbudt",
  [404]: "404: Ressursen finnes ikke",
  [500]: "500: Intern serverfeil"
};

export default httpErrorCodes;