import i18n from "@/plugins/i18n.js";
import _ from 'lodash';
import store from '@/store';
import { getOrgDisplayName } from "@/utils/org";


/*
    Based on a date, build a lookup
    for specific rate configuration group
    with relation of:
        rateTypeId -> array of availableOrgIds

    Data example:
    01.01.2020

    {
        "1": [372, 385],
        "4": [372]
    }

    Explanation:
    RateTypeId "1" was enabled for 2 orgs during the date of 01.01.2020. This means that
    orgs with ids 372, 385 had activate rate configuration setup after (or on) 01.01.2020 where rateTypeId = "1" was marked as enabled.

    For RateTypeId "4" it was only org with id "372"

    Another RateTypeIds (example "2", "3", "5") were not enabled anywhere for given date/period, hence not part of the lookup
*/
export const typeToOrgLookup = function typeToOrgLookup(
    date,
    enabledTypeProp,
    getEnabledTypesFunc,
    payingOrgs
) {
    if (!date) {
        return {};
    }

    let lookup = {};
    const getRateConfiguration = store.getters['application/rateConfigurationsStore/rateConfiguration'];

    for (const org of payingOrgs) {
        const rateConfiguration = getRateConfiguration(org.id, date);

        if (rateConfiguration) {
            const enabledTypes = getEnabledTypesFunc(rateConfiguration);

            lookup = addOrgIdToLookup(lookup, enabledTypeProp, enabledTypes, org.id);
        }
    }

    return lookup;
};

export const addOrgIdToLookup = function addOrgIdToLookup(lookup, enabledTypeProp, enabledTypes, orgId) {
    for (const enabledType of enabledTypes) {
        if (lookup[enabledType[enabledTypeProp]]) {
            lookup[enabledType[enabledTypeProp]].push(orgId);
        } else {
            lookup[enabledType[enabledTypeProp]] = [orgId];
        }
    }

    return lookup;
};

/*
    Get typeToOrg lookup and build payingOrgs by typeToOrg value array and available for application payingOrgs
*/
export const filterAvailablePayingOrgs = function filterAvailablePayingOrgs(claim, configurationTypeProperty, getEnabledTypesFunc, claimTypeProperty) {
    if (!claim.date || !claim[claimTypeProperty]) {
        return [];
    }

    const payingOrgs = store.getters['application/payingOrgsStore/payingOrgs'];

    const typeToOrg = typeToOrgLookup(
        claim.date,
        configurationTypeProperty,
        getEnabledTypesFunc,
        payingOrgs
    );

    const availableClaimTargetIds = typeToOrg[claim[claimTypeProperty]];

    if (!availableClaimTargetIds?.length) {
        return [];
    }

    return payingOrgs.filter(x =>
        availableClaimTargetIds.includes(x.id)
    );
};


/*
    Get typeToOrg lookup and build claimTypes dropdown (available options) based on
    name of rate type and org names.
*/
export const filterAvailableClaimTypes = function filterAvailableClaimTypes(claim, claimTypeProperty, getEnabledTypesFunc, claimResource, filteredPayingOrgs) {
    if (!claim.date) {
        return [];
    }

    let payingOrgs = store.getters['application/payingOrgsStore/payingOrgs'];

    if(filteredPayingOrgs){
        payingOrgs = payingOrgs.filter(org => filteredPayingOrgs.find(f => f === org.id));
    }

    const typeToOrg = typeToOrgLookup(
        claim.date,
        claimTypeProperty,
        getEnabledTypesFunc,
        payingOrgs
    );
    const result = [];
    for (const claimTypeId in typeToOrg) {
        if (Object.hasOwn(typeToOrg, claimTypeId)) {
            const orgNames = payingOrgs
                .filter(x => typeToOrg[claimTypeId].includes(x.id))
                .map(x => getOrgDisplayName(x));
            const receiptTypeName = i18n.t(`${claimResource}.${claimTypeId}`);
            const currentReceiptTypeName = `${receiptTypeName} (${orgNames.join(
                ", "
            )})`;

            result.push({
                id: parseInt(claimTypeId, 10),
                name: receiptTypeName,
                nameWithOrgs: currentReceiptTypeName
            });
        }
    }

    return result;
};


export const getTotalApplicationAmount = function (application) {
    return _.sum(
        Object.values(application.claims)
            .flat()
            .filter(x => x && !x.isDeleted)
            .map(x => x.amount)
    );
};
