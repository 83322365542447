<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" v-if="claim.passengers && claim.passengers.length">{{
        claim.passengers.join(", ")
      }}</span>
      <em v-else>{{ $t("previewApplicationPageStrings.noPassengers") }}</em>
    </template>
    <ul>
      <li v-for="(name, index) in claim.passengers" :key="index">{{ name }}</li>
    </ul>
  </v-tooltip>
</template>

<script>
export default {
  name: "TripPassengersWithTooltip",
  props: {
    claim: {
      required: true
    }
  }
};
</script>