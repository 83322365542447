<template>
  <v-row v-if="canDisplayChildOrgs">
    <v-col cols="12" sm="6" md="3">
      <v-select
        v-model="inputOrgId"
        :items="availableOrgs"
        item-value="id"
        item-text="name"
        :label="$t('generalSettingsStrings.childOrgSelectionPanel.childOrgSelect')"
        outlined
        dense
        @change="onOrgChange"
      ></v-select>
      <v-dialog v-model="confirmChangeOrgDialog" max-width="400px">
        <v-card>
          <v-card-title>{{ $t('generalSettingsStrings.childOrgSelectionPanel.confirmChangeOrgDialogTitle') }}</v-card-title>
          <v-card-text>{{ $t('generalSettingsStrings.childOrgSelectionPanel.confirmChangeOrgDialogText') }}</v-card-text>
          <v-card-actions class="pa-5 flex-column flex-sm-row">
            <v-spacer></v-spacer>
            <v-btn
              rounded
              :block="$vuetify.breakpoint.xsOnly"
              class="primary-inverted-button mb-2 mb-sm-0"
              small
              @click="cancelOrgChange"
            >
              {{ $t("generalSettingsStrings.childOrgSelectionPanel.cancelButton") }}
            </v-btn>
            <v-btn
              rounded
              :block="$vuetify.breakpoint.xsOnly"
              class="primary-button ml-0 ml-sm-2"
              small
              @click="proceedOrgChange"
            >
              {{ $t("generalSettingsStrings.childOrgSelectionPanel.proceedButton") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { api } from "@/utils/api";
import OrgTypes from "@/enums/OrgTypes";
import { getOrgDisplayName } from "@/utils/org";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "ChildOrgSelectionPanel",
  data() {
    return {
      confirmChangeOrgDialog: false,
      inputOrgId: null,
      selectedOrgId: null,
      availableOrgs: []
    };
  },
  props: {
    hasUnsavedChanges: Boolean
  },
  computed: {
    ...mapGetters({
      orgId: "contextStore/orgId",
      orgTypeId: "contextStore/orgTypeId",
      orgsWithPermissions: "currentOrgsStore/orgs"
    }),
    loadedOrgTypeId() {
      if (this.orgId && this.orgTypeId) {
        return this.orgTypeId;
      }

      return null;
    },
    canDisplayChildOrgs() {
      return this.availableOrgs && this.availableOrgs.length > 1;
    },
    currentOrg() {
      if (!this.orgId) {
        return null;
      }

      const orgWithPermissions = this.orgsWithPermissions.find(x => x.org.id === this.orgId);

      if (orgWithPermissions?.org) {
        return orgWithPermissions.org;
      }

      return null;
    },
    selectedOrg() {
      if (this.availableOrgs && this.selectedOrgId) {
        return this.availableOrgs.find(x => x.id === this.selectedOrgId);
      }

      return null;
    }
  },
  methods: {
    async fetchChildOrgs() {
      if (!this.orgId) {
        return;
      }

      try {
        let result = [this.getMappedOrg(this.currentOrg)];
        const response = await api.v1.orgs.children(this.orgId);

        if (response.status === 200 && response.data) {
          result = result.concat(response.data.map(x => this.getMappedOrg(x)));
        }

        this.availableOrgs = result;
      } catch (error) {
        await handleError(error);
      }
    },
    setOrgWithoutChildren() {
      this.availableOrgs = [this.getMappedOrg(this.currentOrg)];
    },
    getMappedOrg(org){
      if (org) {
        return ({id: org.id, name: getOrgDisplayName(org), orgTypeId: org.orgTypeId });
      }

      return null;
    },
    onOrgChange() {
      if (this.hasUnsavedChanges) {
        this.confirmChangeOrgDialog = true;
      } else {
        this.selectedOrgId = this.inputOrgId;
      }
    },
    cancelOrgChange() {
      this.inputOrgId = this.selectedOrgId;
      this.confirmChangeOrgDialog = false;
    },
    proceedOrgChange() {
      this.selectedOrgId = this.inputOrgId;
      this.confirmChangeOrgDialog = false;
    }
  },
  watch: {
    loadedOrgTypeId: {
      handler: async function loadedOrgTypeId(orgTypeId) {
        if (orgTypeId === OrgTypes.KL) {
          await this.fetchChildOrgs();
        } else {
          this.setOrgWithoutChildren();
        }

        this.selectedOrgId = this.orgId;
        this.inputOrgId = this.orgId;
      },
      immediate: true
    },
    selectedOrg: {
      handler: function selectedOrg(org) {
        this.$emit("selected-org-changed", org);
      },
      immediate: true
    }
  }
};
</script>