const menuStrings = {
  backoffice: "@:backofficeStrings.name",
  applications: "@:applicationsStrings.name",
  orgApplications: "@:orgApplicationsStrings.name",
  personalSettings: "Dine innstillinger",
  orgGroupHeader: "For organisasjonen",
  superuserGroupHeader: "For superbruker",
  statistics: "@:statisticsStrings.name",
  orgFeesInvoice: "@:orgFeesInvoiceStrings.pageTitle",
  senderInformation: "@:senderInformationStrings.pageTitle",
  infoMessages: "@:infoMessagesStrings.pageTitle"
};

export default menuStrings;