import { UserContextLocalStorageKey } from '@/utils/localStorageConstants.js';

const parseUserContext = function (context) {
    if (context) {
        return JSON.parse(context);
    }
    return {};
};

const getUpdatedUserContext = function (storedUserContext, orgId, orgTypeId) {
    const userContext = parseUserContext(storedUserContext);

    if (userContext) {
        userContext.orgId = orgId;
        userContext.orgTypeId = orgTypeId;

        return userContext;
    } else {
        return {
            orgId: orgId,
            orgTypeId: orgTypeId
        };
    }
};

const contextStore = {
    namespaced: true,
    state: {
        orgId: null,
        orgTypeId: null,
        initiated: false
    },
    mutations: {
        clearContext(state) {
            state.orgId = null;
            state.orgTypeId = null;
        },
        setOrgId(state, orgId) {
            state.orgId = orgId;
        },
        setOrgTypeId(state, orgTypeId) {
            state.orgTypeId = orgTypeId;
        },
        setInitValue(state, initiated){
            state.initiated = initiated;
        }
    },
    actions: {
        init(context, currentOrgs) {
            if (context.state.initiated) {
                return;
            }

            const user = context.rootGetters["oidcStore/oidcUser"];

            if (currentOrgs?.length) {
                const storedUserContext = localStorage.getItem(`${UserContextLocalStorageKey}${user.personid}`);
                const userContext = parseUserContext(storedUserContext);

                if (userContext?.orgId && currentOrgs.some(x => x.id === userContext.orgId)) {
                    context.commit('setOrgId', userContext.orgId);
                    context.commit('setOrgTypeId', userContext.orgTypeId);
                } else {
                    const updatedUserContext = getUpdatedUserContext(storedUserContext, currentOrgs[0].id, currentOrgs[0].orgTypeId);
                    localStorage.setItem(`${UserContextLocalStorageKey}${user.personid}`, JSON.stringify(updatedUserContext));

                    context.commit('setOrgId', currentOrgs[0].id);
                    context.commit('setOrgTypeId', currentOrgs[0].orgTypeId);
                }
            }

            context.commit('setInitValue', true);
        },
        clear(context) {
            const user = context.rootGetters["oidcStore/oidcUser"];

            if (user?.personid) {
                localStorage.removeItem(`${UserContextLocalStorageKey}${user.personid}`);
            }

            context.commit('clearContext');
        },
        setOrgContext(context, { orgId, orgTypeId }) {
            const user = context.rootGetters["oidcStore/oidcUser"];

            const storedUserContext = localStorage.getItem(`${UserContextLocalStorageKey}${user.personid}`);

            const updatedUserContext = getUpdatedUserContext(storedUserContext, orgId, orgTypeId);
            localStorage.setItem(`${UserContextLocalStorageKey}${user.personid}`, JSON.stringify(updatedUserContext));

            context.commit('setOrgId', orgId);
            context.commit('setOrgTypeId', orgTypeId);
        }
    },
    getters: {
        orgId: state => state.orgId,
        orgTypeId: state => state.orgTypeId,
        initiated: state => state.initiated
    }
};

export default contextStore;