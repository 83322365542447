<template>
  <div>
    <v-row class="justify-center">
      <v-col cols="12" lg="6">
        <div>
          <h2 class="text-h3">
            {{ $t("startPageStrings.pageGuide.panelHeader") }}
          </h2>
          <div class="py-2">
            <span class="hidden-lg-and-up">
              {{ $t("startPageStrings.menuInfo.withIcon1")}}
              <v-icon tag="span">mdi-menu</v-icon>
              {{ $t("startPageStrings.menuInfo.withIcon2")}}
            </span>
            <span class="hidden-md-and-down">
              {{ $t("startPageStrings.menuInfo.withoutIcon")}}
            </span>
            {{ $t("startPageStrings.menuInfo.common") }}
          </div>
          <ul>
            <template v-if="hasBackofficeAccess">
              <li class="py-2">
                <span class="font-weight-bold">{{
                  $t("menuStrings.orgApplications")
                }}</span
                >: {{ $t("startPageStrings.pageGuide.orgApplicationsPage") }}
                <br/>
                <inline-router-link to="/orgapplications">{{ $t("menuStrings.orgApplications") }}</inline-router-link>
              </li>
              <li class="py-2">
                <span class="font-weight-bold">{{
                  $t("menuStrings.backoffice")
                }}</span
                >: {{ $t("startPageStrings.pageGuide.backofficePage") }}
                {{
                  $t(
                    "startPageStrings.pageGuide.backofficePageFederationInfo"
                  )
                }}
                <br/>
                <inline-router-link to="/backoffice">{{ $t("menuStrings.backoffice") }}</inline-router-link>
              </li>
            </template>
            <template v-else-if="hasOrgApplicationAccess">
              <li class="py-2">
                <span class="font-weight-bold">{{
                  $t("menuStrings.orgApplications")
                }}</span
                >:
                {{
                  $t(
                    "startPageStrings.pageGuide.orgApplicationsPageApproverOnly"
                  )
                }}
                <br/>
                <inline-router-link to="/orgapplications">{{ $t("menuStrings.orgApplications") }}</inline-router-link>
              </li>
            </template>
              <li class="py-2">
                <span class="font-weight-bold">{{
                  $t("menuStrings.personalSettings")
                }}</span
                >: {{ $t("startPageStrings.pageGuide.personalSettingsPage") }}
                <br/>
                <a :href="miPersonalSettingsUrl" target="_blank" class="secondary-variant--text">
                  {{ $t("startPageStrings.personalSettingsLink") }}
                  <v-icon tag="span" small>mdi-open-in-new</v-icon>
                </a>
              </li>
          </ul>
        </div>
        <div class="py-2">
          {{ $t("startPageStrings.orgSelectionInfo.common") }}
          <span class="hidden-md-and-up">
            {{ $t("startPageStrings.orgSelectionInfo.withIcon1")
            }}<v-icon tag="span">mdi-account-outline</v-icon
            >{{ $t("startPageStrings.orgSelectionInfo.withIcon2") }}
          </span>
          <span class="hidden-sm-and-down">
            {{ $t("startPageStrings.orgSelectionInfo.withoutIcon") }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" lg="6">
        <div>
          <h2 class="text-h3">Viktig informasjon fra NIF</h2>
            <v-card class="mx-auto my-8" elevation="5">
                <v-card-title>
                  Utbetaling via Buypass er utilgjengelig
                </v-card-title>
                <v-card-subtitle class="py-1">
                  08.04.2024
                </v-card-subtitle>
              <v-card-text class="py-3">
                Utbetalinger via Buypass er dessverre utilgjengelig for alle organisasjoner grunnet vedlikehold og oppgradering av tjenesten. Vi har dessverre ikke et eksakt tidsestimat når tjenesten igjen vil bli tilgjengelig. I mellomtiden ber vi deg benytte utbetaling via bank eller integrasjon. Vi beklager ulempene dette medfører.
              </v-card-text>
            </v-card>

            <v-card class="mx-auto my-8" elevation="5">
                <v-card-title>
                  Integrasjon med regnskapssystem er nå tilgjengelig for Idrettsoppgjør
                </v-card-title>
                <v-card-subtitle class="py-1">
                  08.04.2024
                </v-card-subtitle>
              <v-card-text class="py-3">
                Idrettsoppgjør har nå mulighet for integrasjon med regnskapssystemer. Med integrasjon kan du enkelt overføre bilag og oppgjørene til regnskapssystemet ditt. Dette er både tidsbesparende og kostnadsbesparende. Integrasjonen levereres av vår samarbeidspartner IIZY AS. <a href="https://iizy.no/integrasjoner/idrettsoppgjor/" target="_blank" title="Gå til IIZY.no for mer informasjon">Les mer om integrasjonen og hvordan du kan komme i gang her</a>. Du kan lese mer om samarbeidet på <a href="https://www.idrettsforbundet.no/nyheter/2023/nif-har-signert-en-langsiktig-samarbeidsavtale-med-integrasjonsspesialisten-iizy-as/" target="_blank" title="les mer på nif.no">NIF sine nettsider</a>.
              </v-card-text>
            </v-card>

            <v-card class="mx-auto my-8" elevation="5">
                <v-card-title>
                  Beløpsgrense for honorar og godtgjørelse fra frivillige organisasjoner
                </v-card-title>
                <v-card-subtitle class="py-1">
                  08.04.2024
                </v-card-subtitle>
              <v-card-text class="py-3">
                Beløpsgrensen for honorar og godtgjørelse fra frivillige organisasjoner er <strong>10 000 kroner</strong>. Dette betyr at frivillige organisasjoner kan gi honorar og godtgjørelse til frivillige uten å betale arbeidsgiveravgift, skatt og merverdiavgift. Les mer om reglene for honorar og godtgjørelse hos <a href="https://www.skatteetaten.no/person/skatt/hjelp-til-riktig-skatt/arbeid-trygd-og-pensjon/hobby-ekstrainntekt-og-smajobber/mottar-du-lonn-fra-en-frivillig-eller-veldedig-organisasjon/" target="_blank" title="les mer om beløpsgrenser hos skatteetaten">Skatteetaten</a>. Idrettslaget er ansvarlig for å føre oversikt over utbetalinger og beløp per mottaker. Det anbefales å benytte et regnskapssystem og integrasjon for å ivareta regelverket når det gjelder honorarer og godtgjørelser.
              </v-card-text>
            </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InlineRouterLink from "@/views/common/components/InlineRouterLink";

export default {
  name: "StartPage",
  components: {
    InlineRouterLink
  },
  computed: {
    ...mapGetters({
      orgsWithPermissions: "currentOrgsStore/orgs"
    }),
    hasBackofficeAccess() {
      return this.orgsWithPermissions?.some(x => x.backofficeEnabled);
    },
    hasOrgApplicationAccess() {
      return this.orgsWithPermissions?.some(x => x.orgApplicationEnabled);
    },
    miPersonalSettingsUrl() {
      return process.env.VUE_APP_SENDER_LOGIN_URL + "/innsender/varslinger";
    }
  }
};
</script>