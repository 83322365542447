<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, value }">
      <v-btn rounded text v-on="on" class="hidden-sm-and-down inverted--text"
        >{{ nameAndOrgContext
        }}<v-icon class="ml-2" tag="span">{{
          value ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
      <v-btn rounded icon v-on="on" class="hidden-md-and-up inverted--text">
        <v-icon tag="span">mdi-account-outline</v-icon>
      </v-btn>
    </template>
    <v-list two-line max-height="500">
      <v-list-item disabled class="hidden-md-and-up context-switch-current"
        >{{ $t("contextSwitchStrings.loggedInAs") }}:
        {{ nameAndOrgContext }}</v-list-item
      >
      <v-list-item class="white" @click="signOut">
        <v-list-item-content>
          <v-list-item-title class="body-1">{{
            $t("contextSwitchStrings.signOutMenuItem")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        class="white"
        v-for="(item, index) in orgs"
        :key="index"
        @click="setContext(item.org)"
      >
        <v-list-item-content>
          <v-list-item-title
            :class="{
              'body-1': true,
              'font-weight-bold': currentOrgId == item.org.id,
            }"
            >{{ orgDisplayName(item.org) }}</v-list-item-title
          >
          <v-list-item-subtitle>{{
            orgTypeDisplayName(item.org)
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import {
  getOrgDisplayName,
  getOrgTypeDisplayName,
  getOrgNameWithOrgTypeCode
} from "@/utils/org";

export default {
  name: "ContextSwitch",
  props: {
    name: String,
    orgs: {
      default: () => [],
      type: Array
    }
  },
  methods: {
    ...mapActions({
      clearContext: "contextStore/clear",
      setOrgContext: "contextStore/setOrgContext",
      oidcSignOut: "oidcStore/signOutOidc"
    }),
    ...mapMutations({
      setCurrentOrgId: "contextStore/setOrgId",
      setCurrentOrgTypeId: "contextStore/setOrgTypeId"
    }),
    signOut() {
      this.clearContext();
      this.oidcSignOut();
    },
    setContext(org) {
      this.setOrgContext({orgId: org.id, orgTypeId: org.orgTypeId});
    },
    orgDisplayName(org) {
      return getOrgDisplayName(org);
    },
    orgTypeDisplayName(org) {
      return getOrgTypeDisplayName(org);
    }
  },
  computed: {
    orgContext() {
      if (this.orgs && this.currentOrgId) {
        const currentOrg = this.orgs.find(x => x.org.id === this.currentOrgId);

        if (currentOrg) {
          return getOrgNameWithOrgTypeCode(currentOrg.org);
        }
      }

      return "";
    },
    nameAndOrgContext() {
      if (this.orgContext.length) {
        return `${this.name}, ${this.orgContext}`;
      } else {
        return this.name;
      }
    },
    ...mapGetters({
      currentOrgId: "contextStore/orgId"
    })
  }
};
</script>