<template>
  <div v-if="claims && claims.length">
    <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{ $t("salaryPanelStrings.title") }}</h2></v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="claims"
        :items-per-page="-1"
        hide-default-footer
      >
        <template
          v-slot:item.date="{ item }"
        >{{item.date | moment($t("commonStrings.dateDisplayFormat"))}}</template>

        <template v-slot:item.claimTypeId="{ item }">{{$t(`enums.salaryTypes.${item.claimTypeId}`)}}</template>

        <template v-slot:item.salaryCost="{ item }">{{ item.salaryCost | toMoney}}</template>

        <template v-slot:item.amount="{ item }">{{ item.amount | toMoney}}</template>

        <template v-slot:item.payingOrgs="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <paying-orgs-label :paying-orgs="item.payingOrgs"></paying-orgs-label>
              </span>
            </template>
            <ul>
              <li v-for="(name, index) in getNames(item.payingOrgs)" :key="index">{{ name }}</li>
            </ul>
          </v-tooltip>
        </template>

        <template
          v-slot:item.comment="{item}"
        >{{ item.comment || $t('previewApplicationPageStrings.emptyValue')}}</template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import readonlyPanel from "@/mixins/readonlyPanel";
import PayingOrgsLabel from "@/views/applications/components/PayingOrgsLabel";

export default {
  name: "SalariesPanel",
  components: {
    PayingOrgsLabel
  },
  props: {
    claims: {
      type: Array,
      required: true
    }
  },
  mixins: [readonlyPanel],
  data() {
    return {
      headers: [
        {
          text: this.$t("salaryPanelStrings.tableHeaders.date"),
          align: "left",
          sortable: false,
          value: "date"
        },

        {
          text: this.$t("salaryPanelStrings.tableHeaders.salaryType"),
          align: "left",
          sortable: false,
          value: "claimTypeId"
        },

        {
          text: this.$t("salaryPanelStrings.tableHeaders.payingOrgs"),
          align: "left",
          sortable: false,
          value: "payingOrgs"
        },

        {
          text: this.$t("salaryPanelStrings.tableHeaders.count"),
          align: "left",
          sortable: false,
          value: "salaryCount"
        },

        {
          text: this.$t("salaryPanelStrings.tableHeaders.salaryCost"),
          align: "left",
          sortable: false,
          value: "salaryCost"
        },

        {
          text: this.$t("salaryPanelStrings.tableHeaders.amount"),
          align: "right",
          sortable: false,
          value: "amount"
        },

        {
          text: this.$t("salaryPanelStrings.tableHeaders.comment"),
          align: "right",
          sortable: false,
          value: "comment"
        }
      ]
    };
  }
};
</script>