<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn
        rounded
        small
        class="primary-inverted-button"
        v-on="on"
        :disabled="!enableAddNew"
        v-if="!immutableSalaryClaimsCollection"
      >
        <v-icon tag="span" left>mdi-plus</v-icon>
        {{ $t("salaryPanelStrings.labels.addButton") }}
      </v-btn>
      <p class="font-italic" v-else>
        {{ $t("salaryPanelStrings.additionDisabled") }}
      </p>
    </template>
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <validation-observer ref="salaryObserver">
          <v-container>
            <v-row>
              <v-col cols="12" md="6" v-if="!currentItem.readOnly">
                <claim-date-picker
                  :claim="currentItem"
                  :label="$t('salaryPanelStrings.tableHeaders.date')"
                  :allowed-dates="
                    (x) => $moment(x).isSameOrBefore($moment(), 'days')
                  "
                  :key="currentIndex"
                ></claim-date-picker>
              </v-col>
              <v-col cols="12" md="6" v-if="!currentItem.readOnly">
                <claim-type-availability-label
                  :claim="currentItem"
                  configuration-type-property="rateType"
                  model-binding-property="claimTypeId"
                  :filterEnabledTypesFunc="filterEnabledSalaryTypes"
                  resourcesProperty="enums.salaryTypes"
                  claimResource="enums.salaryTypes"
                  :permitted-rate-type="permittedSalaryType"
                ></claim-type-availability-label>
              </v-col>
              <v-col cols="12">
                <paying-orgs-picker
                  :claim="currentItem"
                  typeProperty="rateType"
                  configuration-type-property="rateType"
                  claim-type-property="claimTypeId"
                  :filterEnabledTypesFunc="filterEnabledSalaryTypes"
                  :validation-name="
                    $t('salaryPanelStrings.tableHeaders.payingOrgs')
                  "
                  :label="$t('salaryPanelStrings.tableHeaders.payingOrgs')"
                ></paying-orgs-picker>
              </v-col>
              <v-col cols="12" md="6" v-if="!currentItem.readOnly">
                <validation-provider
                  rules="required|integer|min_value:1"
                  v-slot="{ errors }"
                  :name="$t('salaryPanelStrings.tableHeaders.count')"
                >
                  <integer-text-field
                    v-model="currentItem.salaryCount"
                    name="claimCount"
                    :error-messages="errors"
                    :label="$t('salaryPanelStrings.tableHeaders.count')"
                    :key="currentIndex"
                    @input="updateClaimAmount(currentItem)"
                  ></integer-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6" v-if="!currentItem.readOnly">
                <validation-provider
                  rules="required|decimal:2|min_value:0.01"
                  v-slot="{ errors }"
                  :name="$t('salaryPanelStrings.tableHeaders.salaryCost')"
                >
                <decimal-text-field
                    v-model="currentItem.salaryCost"
                    name="claimAmount"
                    :error-messages="errors"
                    :label="$t('salaryPanelStrings.tableHeaders.salaryCost')"
                    :pad-decimals="2"
                    :key="currentIndex"
                    @input="updateClaimAmount(currentItem)"
                  >
                  </decimal-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="12" v-if="!currentItem.readOnly">
                <validation-provider
                  rules="max:200"
                  :name="$t('salaryPanelStrings.tableHeaders.comment')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="currentItem.comment"
                    :error-messages="errors"
                    :label="$t('salaryPanelStrings.tableHeaders.comment')"
                    counter="200"
                    outlined
                    dense
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <amount-label
                  :value="currentItem.amount"
                  :label="$t('salaryPanelStrings.tableHeaders.amount')"
                ></amount-label>
              </v-col>
            </v-row>
          </v-container>
        </validation-observer>
      </v-card-text>

      <v-card-actions class="pa-5 flex-column flex-sm-row">
        <v-spacer></v-spacer>
        <v-btn
          rounded
          :block="$vuetify.breakpoint.xsOnly"
          class="primary-inverted-button mb-2 mb-sm-0"
          small
          @click="cancel"
        >
          {{ $t("salaryPanelStrings.labels.cancelButton") }}
        </v-btn>
        <v-btn
          rounded
          :block="$vuetify.breakpoint.xsOnly"
          class="primary-button ml-0 ml-sm-2"
          small
          @click="save"
        >
          {{ saveLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import validationErrors from "@/mixins/validationErrors";
import ClaimDatePicker from "../components/ClaimDatePicker";
import ClaimTypeAvailabilityLabel from "../components/ClaimTypeAvailabilityLabel";
import PayingOrgsPicker from "../components/PayingOrgsPicker";
import AmountLabel from "@/views/common/components/AmountLabel";
import IntegerTextField from "@/views/common/components/IntegerTextField";
import DecimalTextField from "@/views/common/components/DecimalTextField";
import { calculateSalaryClaimTotal } from "@/utils/claims/salary";
import SalaryTypes from '../../../../enums/SalaryTypes';

export default {
  name: "SalaryEditDialog",
  components: {
    ValidationObserver,
    ValidationProvider,
    ClaimDatePicker,
    ClaimTypeAvailabilityLabel,
    PayingOrgsPicker,
    AmountLabel,
    IntegerTextField,
    DecimalTextField
  },
  mixins: [validationErrors],
  data() {
    return {
      dialog: false
    };
  },
  props: {
    claims: {
      default: () => [],
      type: Array
    },
    editableSalaryClaimPayingOrgs: {
      required: true
    },
    immutableSalaryClaimsCollection: {
      required: true
    },
    currentItem: {
      required: true
    },
    currentIndex: {
      required: true
    },
    enableAddNew:{
      required: true,
      type: Boolean
    }
  },
  watch: {
    currentIndex(val) {
      if (val >= 0) {
        this.dialog = true;
      }
    },
    dialog(val) {
      if (val && this.currentIndex < 0 && this.$refs.salaryObserver) {
        this.$refs.salaryObserver.reset();
      }
    }
  },
  computed: {
    saveLabel() {
      if (this.currentIndex < 0) {
        return this.$t("salaryPanelStrings.labels.insertButton");
      }

      return this.$t("salaryPanelStrings.labels.updateButton");
    },
    formTitle() {
      if (this.currentIndex < 0) {
        return this.$t("salaryPanelStrings.labels.insertEditDialogTitle");
      }

      return this.$t("salaryPanelStrings.labels.updateEditDialogTitle");
    },
    permittedSalaryType() {
      return SalaryTypes.Default;
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    cancel() {
      this.$emit("edit-cancelled");
      this.close();
    },
    async save() {
      const isDataValid = await this.$refs.salaryObserver.validate();

      if (!isDataValid) {
        this.showValidationErrorSnackbar(this.$refs.salaryObserver);
        return;
      }

      if (this.currentIndex >= 0) {
        this.$emit("item-changed", {
          claim: this.currentItem,
          index: this.currentIndex
        });
      } else {
        this.$emit("new-item-added", this.currentItem);
      }

      this.close();
    },
    filterEnabledSalaryTypes(conf) {
      return conf["salaryRates"]["salaryRateTypes"].filter(x => x.enabled);
    },
    updateClaimAmount(claim) {
      this.$set(claim, "amount", calculateSalaryClaimTotal(claim));
    }
  }
};
</script>