<template>
  <v-skeleton-loader :loading="loading" type="card-heading, table-thead, table-row-divider@8">
    <v-card>
      <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{$t('rateConfigurationStrings.salaryTitle')}}</h2></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="8" xl="6">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">{{$t('rateConfigurationStrings.salaryActiveHeader')}}</th>
                      <th class="text-left">{{$t('rateConfigurationStrings.salaryTypeHeader')}}</th>
                      <th class="text-left">{{$t('rateConfigurationStrings.salaryAccountingHeader')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in filteredSalaryRateTypes" :key="item.rateType">
                      <td>
                        <validation-provider rules :vid="getSalaryEnabledValidationId(item.rateType)">
                          <v-checkbox
                            color="primary"
                            v-model="item.enabled"
                            :aria-label="$t('commonStrings.tableAriaLabel',[$t('rateConfigurationStrings.salaryActiveHeader'), $t(`enums.salaryTypes.${item.rateType}`)])"
                          ></v-checkbox>
                        </validation-provider>
                      </td>
                      <td>{{ $t(`enums.salaryTypes.${item.rateType}`) }}</td>
                      <td>
                        <validation-provider
                          rules="finance_account"
                          :name="$t('rateConfigurationStrings.salaryAccountingHeader')"
                          v-slot="{ errors }"
                          :vid="getSalaryAccountingIdValidationId(item.rateType)"
                        >
                          <v-text-field
                            v-model="item.accountingId"
                            outlined
                            dense
                            :error-messages="errors"
                            :aria-label="$t('commonStrings.tableAriaLabel',[$t('rateConfigurationStrings.salaryAccountingHeader'), $t(`enums.salaryTypes.${item.rateType}`)])"
                            :disabled="!item.enabled"
                          ></v-text-field>
                        </validation-provider>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import SalaryTypes from '../../../enums/SalaryTypes';

export default {
  name: "SalarySettings",
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    salaryRates: {
      type: Object,
      required: true
    }
  },
  components: {
    ValidationProvider
  },
  computed: {
    filteredSalaryRateTypes() {
      return this.salaryRates.salaryRateTypes.filter(x => x.rateType === SalaryTypes.Default);
    }
  },
  methods: {
    getSalaryEnabledValidationId(rateType) {
      return `enabled_salaryType_${rateType}`;
    },
    getSalaryAccountingIdValidationId(rateType) {
      return `accountingId_salaryType_${rateType}`;
    }
  }
};
</script>