<template>
  <validation-provider
    rules="required"
    v-slot="{ errors }"
    :name="validationNameOrDefault"
  >
    <v-select
      v-model="claim[modelBindingProperty]"
      :disabled="disabled || !claim.date"
      :items="availableClaimTypes(claim)"
      :error-messages="errors"
      :placeholder="placeholderTextOrDefault"
      :label="label"
      item-value="id"
      name="expenseType"
      outlined
      dense
      @change="resetClaimPayingOrgs"
    >
      <template v-slot:append>
        <v-icon tag="span">mdi-menu-down</v-icon>
      </template>

      <template v-slot:item="data">{{ data.item.nameWithOrgs}}</template>

      <template v-slot:selection="data">{{ data.item.name}}</template>
    </v-select>
  </validation-provider>
</template>

<script>
import { filterAvailableClaimTypes } from "@/utils/claim";
import { ValidationProvider } from "vee-validate";

export default {
  name: "ReceiptClaimTypePicker",
  props: {
    claim: {
      type: Object,
      defaut: () => ({})
    },
    configurationTypeProperty: {
      type: String,
      required: true
    },
    filterEnabledTypesFunc: {
      type: Function,
      required: true
    },
    resourcesProperty: {
      type: String,
      required: true
    },
    modelBindingProperty: {
      type: String,
      required: true
    },
    validationName: {
      type: String
    },
    placeholderText: {
      type: String
    },
    label: {
      type: String
    },
    disabled: Boolean
  },
  computed: {
    placeholderTextOrDefault() {
      if (this.placeholderText) {
        return this.placeholderText;
      }

      return this.$t('claimPickersStrings.placeholders.claimType');
    },
    validationNameOrDefault(){
      if (this.validationName) {
        return this.validationName;
      }

      return this.$t('claimPickersStrings.tableHeaders.claimType');
    }
  },
  components: {
    ValidationProvider
  },
  methods: {
    resetClaimPayingOrgs() {
      this.$delete(this.claim, "payingOrgs");
      this.$set(this.claim, "usesSalaryFraction", false);

      this.$emit("claim-type-change");
    },
    availableClaimTypes(claim) {
      return filterAvailableClaimTypes(
        claim,
        this.configurationTypeProperty,
        this.filterEnabledTypesFunc,
        this.resourcesProperty
      );
    }
  }
};
</script>