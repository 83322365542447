<template>
  <v-data-table
    dense
    hide-default-footer
    :headers="getHeader()"
    :items="claims"
    :items-per-page="-1"
    :mobile-breakpoint="960"
  >
    <template v-slot:item.date="{item}">
        {{ item.date | moment($t('commonStrings.dateDisplayFormat'))}}
    </template>
    <template v-slot:item.claimTypeId="{item}">
        {{ $t(`enums.receiptTypes.${item.claimTypeId}`)}}
    </template>
    <template v-slot:item.usesSalaryFraction="{item}">
      <v-checkbox
        color="primary"
        :label="$t('commonStrings.salaryFractionLabel')"
        v-model="item.usesSalaryFraction"
        disabled
      ></v-checkbox>
    </template>
    <template v-slot:item.payingOrgs="{item}">
      <paying-orgs-label :paying-orgs="item.payingOrgs"></paying-orgs-label>
    </template>
    <template v-slot:item.attachments="{item}">
      {{ $t('receiptsPanelStrings.labels.attachmentCount', [item.attachments.length]) }}
    </template>
    <template v-slot:item.amount="{item}">
      <amount-label :value="item.amount"></amount-label>
    </template>
    <template v-slot:item.editAction="{ item }">
      <v-btn
        rounded
        small
        class="primary-inverted-button my-2"
        @click="onEdit(item)"
      >{{ $t("receiptsPanelStrings.labels.editButton") }}</v-btn>
    </template>
    <template v-slot:item.removeAction="{ item }">
      <v-btn
        rounded
        small
        class="accent-inverted-button my-2"
        @click="onRemove(item)"
      >{{ $t("receiptsPanelStrings.labels.removeButton") }}</v-btn>
    </template>
  </v-data-table>
</template>

<script>
  import AmountLabel from "@/views/common/components/AmountLabel";
  import PayingOrgsLabel from "@/views/applications/components/PayingOrgsLabel";

  export default {
    name: "ReceiptsTable",
    components: {
      AmountLabel,
      PayingOrgsLabel
    },
    props: {
      claims: {
        default: () => [],
        type: Array
      },
      remove: Function,
      canUseSalaryFraction: {
        required: true
      }
    },
    methods: {
      getHeader() {
        const headers = [
          {
            text: this.$t("receiptsPanelStrings.tableHeaders.date"),
            value: "date",
            sortable: false,
            filterable: false
          },
          {
            text: this.$t("receiptsPanelStrings.tableHeaders.expenseType"),
            value: "claimTypeId",
            sortable: false,
            filterable: false
          },
          {
            text: this.$t("receiptsPanelStrings.tableHeaders.payingOrgs"),
            value: "payingOrgs",
            sortable: false,
            filterable: false
          },
          {
            text: this.$t("receiptsPanelStrings.tableHeaders.attachment"),
            value: "attachments",
            sortable: false,
            filterable: false
          },
          {
            text: this.$t("receiptsPanelStrings.tableHeaders.amount"),
            value: "amount",
            sortable: false,
            filterable: false
          },
          {
            text: this.$t("receiptsPanelStrings.tableHeaders.comment"),
            value: "comment",
            sortable: false,
            filterable: false
          },
          {
            value: "editAction",
            sortable: false,
            filterable: false
          },
          {
            value: "removeAction",
            sortable: false,
            filterable: false
          }
        ];
        if (this.canUseSalaryFraction) {
          headers.splice(2, 0, {
            text: this.$t("commonStrings.salaryFraction"),
            value: "usesSalaryFraction",
            sortable: false,
            filterable: false
          });
        }

        return headers;
      },
      onEdit(claim) {
        const index = this.claims.indexOf(claim);
        this.$emit('edit-clicked', {claim, index} );
      },
      onRemove(claim) {
        const index = this.claims.indexOf(claim);
        this.$emit('remove-clicked', {claim, index} );
      }
    }
  };
</script>