const productListSettingsStrings = {
  title: "Balansekontoer",
  helpText: `Balansekontoene benyttes i csv-fil og bilag slik at grunnlaget for regnskapsføring er fullstendig.
   Det ligger forslag til nummer for hovedbokskonto i henhold til Idrettens standard, men de kan endres`,
  tableHeaders: {
    product: "Navn hovedbokskonto",
    account: "Hovedbokskontonummer"
  }
};

export default productListSettingsStrings;