const rateConfigurationSummariesStrings = {
  title: "@:commonStrings.rateConfiguration",
  tableHeaders: {
    fromDate: "Fra dato",
    toDate: "Til dato"
  },
  labels: {
    new: "@:commonStrings.createNew",
    details: "@:commonStrings.details"
  },
  cannotEditForChildOrgInfo: "@:commonStrings.rateConfiguration kan foreløpig kun endres for innlogget organisasjonsledd."
};

export default rateConfigurationSummariesStrings;