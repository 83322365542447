<template>
  <div class="settings">
    <child-org-selection-panel
      :has-unsaved-changes="generalSettingsHasChanges"
      @selected-org-changed="onOrgChanged"
    ></child-org-selection-panel>
    <v-alert type="info" v-if="showWarning">
      {{
        onlyFutureRateConfigurations
          ? $t("backofficeStrings.futureConfigurationsOnlyWarning")
          : $t("backofficeStrings.inconsistentConfigurationWarning")
      }}
    </v-alert>
    <v-tabs v-model="activeTab" centered show-arrows>
      <v-tab href="#generalTab">
        {{ $t("generalSettingsStrings.title") }}
      </v-tab>
      <v-tab href="#rateSummariesTab">
        {{ $t("rateConfigurationSummariesStrings.title") }}
      </v-tab>
      <v-tab href="#financialDimensionsTab">
        {{ $t("financialDimensionStrings.title") }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab">
      <v-tab-item value="generalTab">
        <general-settings
          :selected-org-id="selectedOrgId"
          :selected-org-type-id="selectedOrgTypeId"
          @general-settings-loaded="generalSettingsLoaded = true"
          @has-changes-changed="onHasChangesChanged"
        >
        </general-settings>
      </v-tab-item>
      <v-tab-item value="rateSummariesTab">
        <rate-summaries
          :selected-org-id="selectedOrgId"
          @rate-summaries-loaded="rateSummariesLoaded = true"
        >
        </rate-summaries>
      </v-tab-item>
      <v-tab-item value="financialDimensionsTab">
        <!-- The v-if here causes tab content to be destroyed and mounted again when switching tabs -->
        <financial-dimension-settings
          :selected-org-id="selectedOrgId"
          v-if="activeTab === 'financialDimensionsTab'"
        >
        </financial-dimension-settings>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ChildOrgSelectionPanel from "./components/ChildOrgSelectionPanel";
import RateSummaries from "./components/RateSummaries";
import GeneralSettings from "./components/GeneralSettings";
import FinancialDimensionSettings from "./components/FinancialDimensionSettings";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "SettingsPage",
  data() {
    return {
      activeTab: "generalTab",
      selectedOrgId: null,
      selectedOrgTypeId: null,
      generalSettingsLoaded: false,
      rateSummariesLoaded: false,
      generalSettingsHasChanges: false
    };
  },
  components: {
    ChildOrgSelectionPanel,
    GeneralSettings,
    RateSummaries,
    FinancialDimensionSettings
  },
  computed: {
    ...mapGetters({
      isActive: "settings/orgConfigurationStore/isActive",
      rateSummaries: "settings/rateSummariesStore/rateSummaries"
    }),
    onlyFutureRateConfigurations() {
      const today = this.$moment();

      if (!_.some(this.rateSummaries)) {
        return false;
      }

      return _.every(this.rateSummaries, x => today.isBefore(x.fromDate));
    },
    showWarning() {
      if (!this.generalSettingsLoaded || !this.rateSummariesLoaded) {
        return false;
      }

      //warning returned if general settings and rate configurations are not consistent
      if (
        !this.isActive ||
        !this.rateSummaries ||
        this.rateSummaries.length === 0
      ) {
        return true;
      }

      return this.onlyFutureRateConfigurations;
    }
  },
  methods: {
    onOrgChanged(org) {
      this.selectedOrgId = org?.id ?? null;
      this.selectedOrgTypeId = org?.orgTypeId ?? null;
    },
    onHasChangesChanged(val) {
      this.generalSettingsHasChanges = !!val;
    }
  }
};
</script>
