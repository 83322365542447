const claimPickersStrings = {
  placeholders: {
    payingOrgs: "@:commonStrings.selectOrg",
    claimType: "Velg type"
  },
  tableHeaders: {
    payingOrgs: "Mottakere",
    claimType: "Utleggstype"
  }
};

export default claimPickersStrings;