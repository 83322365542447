<template>
  <text-field-date-picker
    v-model="claim.date"
    @input="resetClaim(claim)"
    :label="$t('commonStrings.date')"
    :allowed-dates="allowedDates"
    :disabled="disabled"
  ></text-field-date-picker>
</template>

<script>
import TextFieldDatePicker from "@/views/common/components/TextFieldDatePicker";

export default {
  name: "ClaimDatePicker",
  props: {
    claim: {
      type: Object,
      required: true
    },
    allowedDates: Function,
    disabled: Boolean
  },
  components: {
    TextFieldDatePicker
  },
  methods: {
    resetClaim(claim) {
      this.$delete(claim, "payingOrgs");
      this.$delete(claim, "claimTypeId");

      this.$emit("claim-date-change");
    }
  }
};
</script>