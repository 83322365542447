<template>
  <v-data-table
    dense
    hide-default-footer
    :headers="getHeader()"
    :items="claims"
    :items-per-page="-1"
    :mobile-breakpoint="960"
  >
    <template v-slot:item.date="{ item }">
      {{ item.date | moment($t("commonStrings.dateDisplayFormat")) }}
    </template>
    <template v-slot:item.claimTypeId="{ item }">
      {{ $t(`enums.vehicleTypes.${item.claimTypeId}`) }}
    </template>
    <template v-if="canUseSalaryFraction" v-slot:item.usesSalaryFraction="{ item }">
      <v-checkbox
        color="primary"
        :label="$t('commonStrings.salaryFractionLabel')"
        v-model="item.usesSalaryFraction"
        :disabled="true"
      ></v-checkbox>
    </template>
    <template v-slot:item.payingOrgs="{ item }">
      <paying-orgs-label :paying-orgs="item.payingOrgs"></paying-orgs-label>
    </template>
    <template v-slot:item.passengers="{ item }">
      <passenger-tooltip :claim="item" :key="item.passengers.join(',')"></passenger-tooltip>
    </template>
    <template v-slot:item.amount="{ item }">
      <amount-label :value="item.amount"></amount-label>
    </template>
    <template v-slot:item.info="{ item }">
      <trip-rate-information-tooltip
        :claim="item"
        v-if="item.hasOwnProperty('amount')"
      ></trip-rate-information-tooltip>
    </template>
    <template v-slot:item.editAction="{ item }">
      <v-btn small rounded class="primary-inverted-button my-2" @click="onEdit(item)">{{
        $t("drivingPanelStrings.labels.editButton")
      }}</v-btn>
    </template>
    <template v-slot:item.removeAction="{ item }">
      <v-btn small rounded class="accent-inverted-button my-2" @click="remove(item)">{{
        $t("drivingPanelStrings.labels.removeButton")
      }}</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import PayingOrgsLabel from "@/views/applications/components/PayingOrgsLabel";
import PassengerTooltip from "@/views/applications/components/TripPassengersWithTooltip";
import TripRateInformationTooltip from "./TripRateInformationTooltip";
import AmountLabel from "@/views/common/components/AmountLabel";

export default {
  name: "TripsTable",
  components: {
    PayingOrgsLabel,
    PassengerTooltip,
    TripRateInformationTooltip,
    AmountLabel
  },
  props: {
    claims: {
      default: () => [],
      type: Array
    },
    remove: Function,
    canUseSalaryFraction: {
      required: true
    }
  },
  computed: {
  },
  methods: {
    getHeader() {
      const headers = [
        {
          text: this.$t("drivingPanelStrings.tableHeaders.date"),
          value: "date",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("drivingPanelStrings.tableHeaders.vehicleType"),
          value: "claimTypeId",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("drivingPanelStrings.tableHeaders.payingOrgs"),
          value: "payingOrgs",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("drivingPanelStrings.tableHeaders.from"),
          value: "from",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("drivingPanelStrings.tableHeaders.to"),
          value: "to",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("drivingPanelStrings.tableHeaders.tripLength"),
          value: "kilometers",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("drivingPanelStrings.tableHeaders.passengers"),
          value: "passengers",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("drivingPanelStrings.tableHeaders.amount"),
          value: "amount",
          sortable: false,
          filterable: false
        },
        {
          value: "info",
          sortable: false,
          filterable: false
        },
        {
          value: "editAction",
          sortable: false,
          filterable: false
        },
        {
          value: "removeAction",
          sortable: false,
          filterable: false
        }
      ];

      if (this.canUseSalaryFraction) {
        headers.splice(2, 0, {
          text: this.$t("commonStrings.salaryFraction"),
          value: "usesSalaryFraction",
          sortable: false,
          filterable: false
        });
      }

      return headers;
    },
    onEdit(claim) {
      const index = this.claims.indexOf(claim);
      this.$emit("edit-clicked", { claim, index });
    }
  }
};
</script>