const receiptsPanelStrings = {
  title: "Utlegg og kvitteringer",
  singularTitle: "Utlegg",
  tableHeaders: {
    routeFrom: "Mottakere",
    routeTo: "Utleggstype",
    attachment: "Vedlegg",
    amount: "@:commonStrings.amount",
    comment: "Merknad",
    expenseType: "Utleggstype",
    payingOrgs: "@:commonStrings.recipients",
    date: "@:commonStrings.date"
  },
  labels: {
    fileAttachment: "Vedlegg",
    addButton: "@:commonStrings.add @.lower:receiptsPanelStrings.singularTitle",
    editButton: "@:commonStrings.edit",
    removeButton: "@:commonStrings.remove",
    cancelButton: "@:commonStrings.cancel",
    insertButton: "@:commonStrings.add",
    updateButton: "@:commonStrings.update",
    attachmentCount: "{0} vedlegg",
    attachmentCountSelected: "{0} vedlegg valgt",
    insertEditDialogTitle: "Nytt @.lower:receiptsPanelStrings.singularTitle",
    updateEditDialogTitle: "Endre @.lower:receiptsPanelStrings.singularTitle"
  },
  attachmentsModal: {
    uploadHeader: "Opplasting av vedlegg",
    selectedAttachmentsHeader: "Valgte vedlegg",
    attachmentListHeader: "Vedlegg",
    uploadSuccessMessage: "Filen ble lastet opp",
    uploadErrorMessage: "Filen kunne ikke lastes opp",
    labels: {
      showButton: "Vis",
      showButtonCount: "Vis {0} vedlegg",
      editButton: "@:commonStrings.edit",
      closeButton: "@:commonStrings.close",
      uploadSuccessCloseLink: "@:commonStrings.close",
      uploadErrorCloseLink: "@:commonStrings.close"

    }
  }
};

export default receiptsPanelStrings;