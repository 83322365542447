<template>
  <div>
    {{label}}
  </div>
</template>

<script>
import OrgApplicationStatusTypes from "@/enums/OrgApplicationStatusTypes";
import ApplicationStatusTypes from "@/enums/ApplicationStatusTypes";
import { getOrgDisplayName } from "@/utils/org";

export default {
    name: "StatusLabel",
    computed: {
      label(){
        if (this.applicationStatus === ApplicationStatusTypes.Approved
        && Object.values(this.applicationOrgs).some(x => x.orgApplicationStatus === OrgApplicationStatusTypes.Paid)) {
          const pendingForPaymentCount = Object.values(this.applicationOrgs).filter(x => x.orgApplicationStatus === OrgApplicationStatusTypes.Approved).length;
          const paidOrgIds = Object.entries(this.applicationOrgs).filter(kvp => kvp[1].orgApplicationStatus === OrgApplicationStatusTypes.Paid).map(kvp => parseInt(kvp[0], 10));
          const paidOrgNames = this.orgs.filter(x => paidOrgIds.includes(x.id)).map(x => getOrgDisplayName(x)).join(", ");

          return this.$t('statusLabelStrings.partialPaymentStatusText', [pendingForPaymentCount, paidOrgNames]);
        } else if (this.applicationStatus === ApplicationStatusTypes.Pending
        && Object.values(this.applicationOrgs).some(x => x.orgApplicationStatus === OrgApplicationStatusTypes.Approved)) {
          const pendingCount = Object.values(this.applicationOrgs).filter(x => x.orgApplicationStatus === OrgApplicationStatusTypes.Pending).length;

          const approvedOrgIds = Object.entries(this.applicationOrgs)
            .filter(kvp => kvp[1].orgApplicationStatus === OrgApplicationStatusTypes.Approved).map(kvp => parseInt(kvp[0], 10));

          const approvedOrgNames = this.orgs.filter(x => approvedOrgIds.includes(x.id)).map(x => getOrgDisplayName(x)).join(", ");

          return this.$t('statusLabelStrings.partialApprovalStatusText', [pendingCount, approvedOrgNames]);
        } else if (this.applicationStatus === ApplicationStatusTypes.Returned) {
          const returnedOrgIds = Object.entries(this.applicationOrgs)
            .filter(kvp => kvp[1].orgApplicationStatus === OrgApplicationStatusTypes.Returned).map(kvp => parseInt(kvp[0], 10));

          const returnedOrgNames = this.orgs.filter(x => returnedOrgIds.includes(x.id)).map(x => getOrgDisplayName(x)).join(", ");

          return this.$t('statusLabelStrings.returnedStatusText', [returnedOrgNames]);
        } else if (this.isDraft) {
          return this.$t('statusLabelStrings.draft');
        } else {
          return this.$t(`enums.applicationStatusTypes.${this.applicationStatus}`);
        }
      }
    },
    props: {
      applicationStatus: ApplicationStatusTypes,
      isDraft: {
        type: Boolean
      },
      applicationOrgs: {
        type: Object
      },
      orgs: {
        type: Array
      }
    }
};
</script>