<template>
  <v-dialog v-model="dialog" max-width="500" :key="currentIndex" persistent>
    <v-card>
      <v-card-title> {{ item.name }} </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select
                :label="
                  $t('senderInformationStrings.editDialog.chosenPaymentMethod')
                "
                item-value="id"
                item-text="text"
                :items="paymentMethods"
                v-model="selectedPaymentMethod"
                dense
                outlined
                :clearable="true"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pa-5 flex-column flex-sm-row">
        <v-spacer></v-spacer>
        <v-btn
          rounded
          class="primary-inverted-button mb-2 mb-sm-0"
          @click="cancel"
          :block="$vuetify.breakpoint.xsOnly"
          small
        >
          {{ $t("commonStrings.cancel") }}
        </v-btn>
        <v-btn
          rounded
          class="primary-button ml-0 ml-sm-2"
          @click="save"
          :block="$vuetify.breakpoint.xsOnly"
          small
        >
          {{ $t("commonStrings.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "SenderInformationDialog",
  components: {},
  data() {
    return {
      dialog: false,
      selectedPaymentMethod: "0"
    };
  },
  props: {
    item: {
      required: true
    },
    currentIndex: {
      required: true
    },
    enabledPaymentMethods: {
      required: true
    }
  },
  watch: {
    currentIndex(val) {
      if (val > 0) {
        this.selectedPaymentMethod = Number(
          this.item.preferredPaymentMethod
        ).toString();
        this.dialog = true;
      } else {
        this.dialog = false;
        this.selectedPaymentMethod = "0";
      }
    }
  },
  computed: {
    paymentMethods() {
      const options = Object.entries(this.enabledPaymentMethods).map(x => ({
        text: this.$t(`enums.paymentMethods.${x[1]}`),
        id: Number(x[1]).toString()
      }));
      return options;
    }
  },
  methods: {
    cancel() {
      this.$emit("edit-cancelled");
    },
    async save() {
      this.$emit("item-changed", {id: this.item.id, selectedPaymentMethod: this.selectedPaymentMethod});
    }
  }
};
</script>