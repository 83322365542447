<template>
  <div v-if="application && application.id">
    <div class="d-print-none">
      <breadcrumb-block>
        <breadcrumb-router-link to="/orgapplications">
          {{ $t("commonStrings.toOverview") }}
        </breadcrumb-router-link>
        <breadcrumb-router-link :to="{
                  name: 'orgApplicationDetails',
                  params: { id: application.id },
                }">
                {{ $t("journalStrings.toDetails") }}
        </breadcrumb-router-link>
      </breadcrumb-block>
    </div>
    <v-alert type="warning" v-if="showWarning">{{
      $t("journalStrings.stillPendingWarning")
    }}</v-alert>
    <v-row>
      <v-col cols="6">
        <person-information
          :initializing="initializing"
          :initApplicationId="application.id"
          :title="$t('journalStrings.sender.title')"
          :outlined="true"
          :labels="false"
        ></person-information>
      </v-col>
      <v-col cols="6">
        <journal-sender :orgId="orgId"></journal-sender>
      </v-col>
    </v-row>
    <v-row>
      <journal-meta-data
        :application="application"
        :orgId="orgId"
      ></journal-meta-data>
    </v-row>
    <v-row>
      <journal-table :categoryRows="claims"></journal-table>
    </v-row>
    <v-row class="d-print-none">
      <v-col>
          <v-btn
            rounded
            class="primary-button"
            :block="$vuetify.breakpoint.xsOnly"
            @click="print"
            >{{ $t("commonStrings.print") }}</v-btn
          >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PersonInformation from "@/views/applications/components/PersonInformation";
import journalSender from "./components/JournalSender";
import journalMetaData from "./components/JournalMetaData";
import journalTable from "./components/JournalClaimsTable";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

import application from "@/mixins/application";
import deductionRates from "@/mixins/deductionRates";

import {
  calculateSalaryOrgShare,
  calculateSalaryOrgAmountFromTotal
} from "@/utils/claims/salary";

import {
  calculateOrgTripDistance,
  calculateVehicleAmountForOrg,
  calculatePassengerAmountForOrg,
  getVehicleRateForOrg,
  getPassengerRateByPassengerCountForOrg
} from "@/utils/claims/trips";

import {
  calculateReceiptShare,
  calculateReceiptOrgAmount
} from "@/utils/claims/receipt";

import {
  getDietRatesForOrgByDay,
  calculateDietAmountForOrg,
  getDietRateName,
  getDietAccountingId,
  getValidStays
} from "@/utils/claims/diet";

import { toNumeric } from "@/utils/numeric";
import { handleError } from "@/utils/errorHandler";

import ApplicationStatusTypes from "@/enums/ApplicationStatusTypes";

import BreadcrumbBlock from "@/views/common/components/BreadcrumbBlock";
import BreadcrumbRouterLink from "@/views/common/components/BreadcrumbRouterLink";

export default {
  name: "JournalDetailsPage",
  data() {
    return {
      initializing: true
    };
  },
  components: {
    PersonInformation,
    journalSender,
    journalMetaData,
    journalTable,
    BreadcrumbBlock,
    BreadcrumbRouterLink
  },
  mixins: [application, deductionRates],
  methods: {
    print() {
      window.print();
    },
    ...mapGetters({
      getRateConfiguration:
        "application/rateConfigurationsStore/rateConfiguration"
    }),
    ...mapActions({
      fetchTaxRates: "currentTaxRatePeriodStore/fetch"
    }),
    getAccountNoForType(categoryType, categoryObject, rateType, day) {
      const rateConfiguration = this.getRateConfiguration()(
        this.orgId,
        day,
        categoryType
      );

      if (!rateConfiguration) {
        return "";
      }

      const first = rateConfiguration[categoryObject].find(
        x => x.rateType === rateType && x.enabled
      );

      return first?.accountingId ?? "";
    },
    getAccountNoForTripType(day) {
      const rateConfiguration = this.getRateConfiguration()(
        this.orgId,
        day,
        "vehicleRates"
      );

      if (!rateConfiguration) {
        return "";
      }
      return rateConfiguration.accountingId;
    },
    getAccountNoForPassenger(day) {
      const rateConfiguration = this.getRateConfiguration()(
        this.orgId,
        day,
        "vehicleRates"
      );

      if (!rateConfiguration) {
        return "";
      }
      return rateConfiguration.passengerAccountingId;
    },
    getPassengerString(trip) {
      if(trip?.passengers){
        return this.$tc("journalStrings.table.passengerCount", trip?.passengers?.length);
      }

      return "";
    },
    getTripTypeDescription(trip) {
      return `${this.$t("commonStrings.from")} ${trip.from}, ${this.$t(
        "commonStrings.to"
      ).toLocaleLowerCase()} ${trip.to}`;
    },
    getCategorySum(arr){
      if(arr) {
        return _.sum(arr.filter(x => x?.amount).map(x => x.amount));
      }

      return 0;
    },
    summarizeCategory(category, arr) {
      return {
        isSummary: true,
        claimCategory: category,
        amount: this.getCategorySum(arr)
      };
    }
  },
  computed: {
    orgId() {
      return Number(this.$route.params.orgid);
    },
    showWarning() {
      return (
        this.application.applicationStatus !==
          ApplicationStatusTypes.Approved &&
        this.application.applicationStatus !== ApplicationStatusTypes.Paid
      );
    },
    salaryClaims() {
      return this.application.claims.salaries
        .filter(x => x.payingOrgs.includes(this.orgId))
        .map(x => {
          return {
            date: this.$moment(x.date),
            accountNo: this.getAccountNoForType(
              "salaryRates",
              "salaryRateTypes",
              x.claimTypeId,
              this.$moment(x.date)
            ),
            claimType: this.$t(`enums.salaryTypes.${x.claimTypeId}`),
            count: `${toNumeric(calculateSalaryOrgShare(x))}`,
            rate: x.salaryCost,
            amount: calculateSalaryOrgAmountFromTotal(x),
            comment: x.comment
          };
        });
    },
    tripClaims() {
      return this.application.claims.trips
        .filter(x => x.payingOrgs.includes(this.orgId))
        .map(x => {
          const date = this.$moment(x.date);
          const count = calculateOrgTripDistance(x, this.orgId);
          const result = [{
            date: date,
            accountNo: this.getAccountNoForTripType(date),
            claimType: this.$t(`enums.vehicleTypes.${x.claimTypeId}`),
            count: `${toNumeric(count || 0)} ${this.$t(
              "journalStrings.table.kilometers"
            )}`,
            rate: getVehicleRateForOrg(x, this.orgId),
            amount: calculateVehicleAmountForOrg(x, this.orgId),
            comment: this.getTripTypeDescription(x)
          }];

          if (x?.passengers?.length) {
            result.push(
              {
                date: date,
                accountNo: this.getAccountNoForPassenger(date),
                claimType: this.$t("journalStrings.table.passenger"),
                count: `${toNumeric(count || 0)} ${this.$t(
                  "journalStrings.table.kilometers"
                )}`,
                rate: getPassengerRateByPassengerCountForOrg(x, this.orgId),
                amount: calculatePassengerAmountForOrg(x, this.orgId),
                comment: this.getPassengerString(x)
              }
            );
          }

          return result;

        }).flat();
    },
    dietClaims() {
      if (
        this.application?.claims?.diet?.payingOrgs?.some(x => x === this.orgId) &&
        this.application?.claims?.diet?.stays?.length
      ) {
        return getValidStays(this.application.claims.diet.stays).map(x => {
          const rates = getDietRatesForOrgByDay(this.orgId, x.date);
          const amount = calculateDietAmountForOrg(
            x,
            this.orgId,
            this.application.claims.diet.payingOrgs,
            this.application.claims.diet.usesSalaryFraction
          );

          return {
            date: this.$moment(x.date),
            accountNo: getDietAccountingId(x, rates),
            claimType: getDietRateName(x),
            count: 1,
            rate: amount,
            amount: amount
          };
        });
      } else {
        return [];
      }
    },
    receiptsClaims() {
      return this.application.claims.receipts
        .filter(x => x.payingOrgs.includes(this.orgId))
        .map(x => {
          return {
            date: this.$moment(x.date),
            accountNo: this.getAccountNoForType(
              "receiptRates",
              "receiptRateTypes",
              x.claimTypeId,
              this.$moment(x.date)
            ),
            claimType: this.$t(`enums.receiptTypes.${x.claimTypeId}`),
            count: `${toNumeric(calculateReceiptShare(x))}`,
            rate: x.amount,
            amount: calculateReceiptOrgAmount(x, this.orgId),
            comment: x.comment
          };
        });
    },
    claims() {
      return [
        this.summarizeCategory(
          this.$t("journalStrings.table.categories.salary"),
          this.salaryClaims
        ),
        ...this.salaryClaims,
        this.summarizeCategory(
          this.$t("journalStrings.table.categories.driving"),
          this.tripClaims
        ),
        ...this.tripClaims,
        this.summarizeCategory(
          this.$t("journalStrings.table.categories.diet"),
          [...this.dietClaims]
        ),
        ...this.dietClaims,
        this.summarizeCategory(
          this.$t("journalStrings.table.categories.receipt"),
          this.receiptsClaims
        ),
        ...this.receiptsClaims
      ];
    }
  },
  async beforeMount() {
    try {
      await this.fetchTaxRates();
    } catch (error) {
      await handleError(error);
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.fetch();
    }

    this.initializing = false;
  }
};
</script>
<style lang="scss">
@media print {
  .v-main {
    padding: 0 !important;
    background-color: white;
  }
  .v-application {
    .v-alert {
      padding: 4px !important;
      margin: 4px !important;
    }
  }

  .v-application h2.text-h2 {
    font-size: 1.3rem !important;
    line-height: 1.3rem !important;
    margin-top: 4px !important;
    margin-bottom: 16px !important;
  }

  .v-sheet--outlined {
    border: none !important;
  }

  .v-card .v-card__text {
    padding: 0px !important;
  }

  .v-card .v-card__text > .row.pb-2 {
    padding-left: 8px;
  }

  .v-card .v-card__title {
    padding: 8px !important;
    padding-bottom: 4px !important;
  }

  .col {
    padding: 6px !important;
    padding-top: 0px !important;
  }

  table,
  table td {
    word-wrap: break-word;
    word-break: normal;
    overflow: hidden;
    overflow-wrap: break-word;
  }
  .v-data-table td,
  .v-data-table th {
    padding: 4px 8px !important;
    font-size: 0.875rem;
    line-height: 0.875rem;
    height: auto !important;
  }
  .v-navigation-drawer {
    display: none !important;
  }
  .v-toolbar {
    display: none !important;
  }
  .v-footer {
    display: none !important;
  }
}
</style>