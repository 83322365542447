import no from "vee-validate/dist/locale/nb_NO";

const validations = {
  ...no.messages,
  finance_account: "{_field_} må være mellom 4 og 5 siffer",
  required_if_any: "{_field_} er obligatorisk",
  decimal: "{_field_}-feltet kan ikke ha desimaler" +
  " | {_field_}-feltet kan kun inneholde tall med opptil {decimals} desimal" +
  " | {_field_}-feltet kan kun inneholde tall med opptil {decimals} desimaler",
  max_value_tax_msg: "{_field_} kan ikke være over trekkfri sats: {formatted_max}",
  integer: "{_field_}-feltet kan kun inneholde heltall",
  name_list: "Hver {_field_} må inneholde minst {min_word_count} ord med minst {min_word_length} tegn",
  max_list_items: "{_field_} kan ikke inneholde mer enn {max_count} elementer",
  max_list_item_length: "Hver {_field_} kan maks være {max_length} tegn",
  max: "{_field_}-feltet kan ikke inneholde mer enn {length} tegn",
  min: "{_field_}-feltet må inneholde minst {length} tegn",
  length: "{_field_}-feltet må inneholde {length} tegn",
  account_number: "Ugyldig kontonummer",
  attachmentRequiredForType: "Minst ett vedlegg er påkrevd for valgt type",
  numeric: "{_field_}-feltet kan kun inneholde siffer",
  isActiveRequiredWhenChildUsesAccount: "{_field_} er obligatorisk når underliggende gruppe bruker klubbens konto",
  is_not: "{_field_} kan ikke være denne verdien",
  date: "{_field_} er ikke en gyldig dato",
  time: "{_field_} er ikke ett gyldig klokkeslett"
};

export default validations;