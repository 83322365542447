<template>
    <v-row v-if="canHaveIntegrations">
    <v-col cols="12">
      <h2 class="text-h2 mt-10 mb-7">
        {{ $t("generalSettingsStrings.integrationSettingsTableTitle") }}
      </h2>
      <v-data-table
        dense
        hide-default-footer
        :items="orgConfiguration.integrationSettings"
        :items-per-page="-1"
        :headers="integrationSettingsTableHeaders"
      >
        <template v-slot:item.integrationConfigurationId="{ item }">
          <validation-provider
            rules="required"
            :name="
              $t(
                'generalSettingsStrings.tableHeaders.integrationConfiguration'
              )
            "
            v-slot="{ errors }"
          >
            <v-select
              :error-messages="errors"
              dense
              outlined
              v-model="item.integrationConfigurationId"
              :items="availableIntegrationConfigurations"
              :label="
                $t(
                  'generalSettingsStrings.placeholders.integrationConfiguration'
                )
              "
            ></v-select>
          </validation-provider>
        </template>

        <template v-slot:item.canUseSalaryFraction="{ item }">
          <v-checkbox
            color="primary"
            :label="
              $t('generalSettingsStrings.tableHeaders.salaryFraction')
            "
            :disabled="!item.integrationConfigurationId"
            v-model="item.canUseSalaryFraction"
          ></v-checkbox>
        </template>
        <template v-slot:item.importSalaryClaimsOnly="{ item }">
          <v-checkbox
            color="primary"
            :label="
              $t(
                'generalSettingsStrings.tableHeaders.importSalaryClaimsOnly'
              )
            "
            :disabled="!item.integrationConfigurationId"
            v-model="item.importSalaryClaimsOnly"
          ></v-checkbox>
        </template>
        <template
          v-slot:item.immutableSalaryClaimsCollection="{ item }"
        >
          <v-checkbox
            color="primary"
            :label="
              $t(
                'generalSettingsStrings.labels.immutableSalaryClaimsCollection'
              )
            "
            :disabled="!item.integrationConfigurationId"
            v-model="item.immutableSalaryClaimsCollection"
          ></v-checkbox>
        </template>
        <template v-slot:item.editableSalaryClaimPayingOrgs="{ item }">
          <v-checkbox
            color="primary"
            :label="
              $t(
                'generalSettingsStrings.tableHeaders.editableSalaryClaimPayingOrgs'
              )
            "
            :disabled="!item.integrationConfigurationId"
            v-model="item.editableSalaryClaimPayingOrgs"
          ></v-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            :disabled="disableCriticalSettings"
            rounded
            small
            class="accent-inverted-button"
            @click="removeIntegration(item)"
            >{{ $t("commonStrings.remove") }}</v-btn
          >
        </template>
      </v-data-table>

      <v-col cols="12">
        <v-btn
          v-if="notUsedIntegrationConfigurations.length"
          rounded
          class="primary-inverted-button"
          @click="addIntegration"
        >
          <v-icon tag="span" left>mdi-plus</v-icon>
          {{ $t("generalSettingsStrings.addIntegration") }}
        </v-btn>
        <em v-else>{{
          $t("generalSettingsStrings.allIntegrationsUsed")
        }}</em>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import OrgTypes from "@/enums/OrgTypes";

export default {
  name: "IntegrationSettings",
  components: {
      ValidationProvider
  },
  data() {
    return {
      integrationSettingsTableHeaders: [
        {
          text: this.$t(
            "generalSettingsStrings.tableHeaders.integrationConfiguration"
          ),
          value: "integrationConfigurationId",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("generalSettingsStrings.tableHeaders.salaryFraction"),
          value: "canUseSalaryFraction",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t(
            "generalSettingsStrings.tableHeaders.importSalaryClaimsOnly"
          ),
          value: "importSalaryClaimsOnly",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t(
            "generalSettingsStrings.tableHeaders.immutableSalaryClaimsCollection"
          ),
          value: "immutableSalaryClaimsCollection",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t(
            "generalSettingsStrings.tableHeaders.editableSalaryClaimPayingOrgs"
          ),
          value: "editableSalaryClaimPayingOrgs",
          sortable: false,
          filterable: false
        },
        {
          value: "actions",
          sortable: false,
          filterable: false
        }
      ]
    };
  },
  props: {
    orgConfiguration: {
      required: true,
      validator: prop => typeof prop === "object" || !prop
    },
    integrationConfigurations: Array,
    orgTypeId: Number,
    disableCriticalSettings: Boolean
  },
  computed: {
    canHaveIntegrations() {
      return this.orgTypeId === OrgTypes.SF;
    },
    notUsedIntegrationConfigurations() {
      const usedIntegrationConfigurationIds = this.orgConfiguration.integrationSettings.map(
        x => x.integrationConfigurationId
      );

      return this.availableIntegrationConfigurations.filter(
        x => !usedIntegrationConfigurationIds.includes(x.value)
      );
    },
    availableIntegrationConfigurations() {
      return this.integrationConfigurations
        .filter(x => x.preferences.mandatoryFederationId)
        .map(function (x) {
          return {
            text: `${x.name} (${x.applicationId})`,
            value: x.id
          };
        });
    }
  },
  methods: {
    addIntegration: function () {
      this.orgConfiguration.integrationSettings.push({
        integrationConfigurationId: null,
        canUseSalaryFraction: false,
        importSalaryClaimsOnly: false,
        immutableSalaryClaimsCollection: false,
        editableSalaryClaimPayingOrgs: false
      });
    },
    removeIntegration: function (item) {
      const index = this.orgConfiguration.integrationSettings.indexOf(item);

      this.orgConfiguration.integrationSettings.splice(index, 1);
    }
  }
};
</script>