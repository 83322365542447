import { mapMutations } from "vuex";

const claimPanel = {
    methods: {
        ...mapMutations({
            showHelpBarWithOptions: "helpBarStore/show"
        }),
        showHelpBar(contentType, expandedSections) {
            this.showHelpBarWithOptions({ contentType, expandedSections });
        }
    }
};


export default claimPanel;