<template>
  <div class="messageAdmin">
    <v-card>
      <v-card-title class="py-0"
        ><h1 class="text-h2 mt-10 mb-7">
          {{ $t("infoMessagesStrings.pageTitle") }}
        </h1></v-card-title
      >
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="messages"
          :no-data-text="this.$t('infoMessagesStrings.noTableData')"
          :no-results-text="this.$t('infoMessagesStrings.noTableResults')"
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <info-message-edit-dialog
                :message="messageToEdit"
                @cancelled="onEditCancel"
                @addNew="onAddNew"
                @saved="onSaved"
              ></info-message-edit-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.displayFromDate="{ item }">
            {{
              item.displayFromDate
                | moment($t("commonStrings.dateDisplayFormat"))
            }}
          </template>
          <template v-slot:item.displayToDate="{ item }">
            {{
              item.displayToDate | moment($t("commonStrings.dateDisplayFormat"))
            }}
          </template>
          <template v-slot:item.editAction="{ item }">
            <v-btn
              small
              rounded
              class="primary-inverted-button my-2"
              @click="onEdit(item)"
              >{{ $t("infoMessagesStrings.editButton") }}</v-btn
            >
            <v-dialog v-model="displayDeleteConfirm" max-width="500" persistent>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  rounded
                  class="accent-inverted-button my-2 ml-2"
                  v-on="on"
                  >{{ $t("infoMessagesStrings.removeButton") }}</v-btn
                >
              </template>
              <v-card>
                <v-card-title>{{
                  $t("infoMessagesStrings.confirmDeleteTitle")
                }}</v-card-title>
                <v-card-text>{{
                  $t("infoMessagesStrings.confirmDeleteText")
                }}</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    class="primary-inverted-button mb-2 mb-sm-0"
                    @click="displayDeleteConfirm = false"
                    :block="$vuetify.breakpoint.xsOnly"
                    small
                  >
                    {{ $t("commonStrings.cancel") }}
                  </v-btn>
                  <v-btn
                    rounded
                    class="accent-inverted-button ml-0 ml-sm-2"
                    @click="onDelete(item)"
                    :block="$vuetify.breakpoint.xsOnly"
                    small
                  >
                    {{ $t("infoMessagesStrings.removeButton") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { api, urls } from "@/utils/api";
import InfoMessageEditDialog from "./InfoMessageEditDialog";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "MessageAdminPage",
  components: { InfoMessageEditDialog },
  data() {
    return {
      displayDeleteConfirm: false,
      messages: [],
      messageToEdit: {},
      headers: [
        {
          text: this.$t("infoMessagesStrings.headers.title"),
          value: "title"
        },
        {
          text: this.$t("infoMessagesStrings.headers.fromDate"),
          value: "displayFromDate"
        },
        {
          text: this.$t("infoMessagesStrings.headers.toDate"),
          value: "displayToDate"
        },
        {
          value: "editAction",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      stateInitiated: "contextStore/initiated",
      urlState: "utilsRestStore/urlState"
    }),
    loading() {
      return this.urlState(urls.v1.infoMessages.get());
    }
  },
  methods: {
    ...mapActions({
      refetchInfoMessages: "infoMessagesStore/refetch"
    }),
    async fetch() {
      try {
        const response = await api.v1.infoMessages.get();

        if (response.status === 200 && response.data) {
          this.messages = response.data;
        }
      } catch (error) {
        await handleError(error);
      }
    },
    setDefaultMessage(id) {
      this.$set(this, "messageToEdit", {
        infoMessageId: id,
        title: "",
        messageText: "",
        infoMessageType: 0,
        readMoreUrl: "",
        displayFromDate: this.$moment().format(
          this.$t("commonStrings.dateValueFormat")
        ),
        displayToDate: this.$moment()
          .add(1, "month")
          .format(this.$t("commonStrings.dateValueFormat")),
        displayOnPage: "",
        recipientType: 0
      });
    },
    async onSaved() {
      this.setDefaultMessage(-1);
      await this.fetch();
      await this.refetchInfoMessages();
    },
    onEdit(message) {
      this.$set(this, "messageToEdit", message);
    },
    onAddNew() {
      this.setDefaultMessage(0);
    },
    onEditCancel() {
      this.setDefaultMessage(-1);
    },
    async onDelete(message) {
      try {
        const response = await api.v1.infoMessages.delete(message.infoMessageId);

        if (response.status === 204) {
          this.$snacks.add(this.$t("infoMessagesStrings.deleted"),
            "success",
            true
          );
          this.displayDeleteConfirm = false;
          await this.fetch();
          await this.refetchInfoMessages();
        }
      } catch (error) {
        await handleError(error);
      }
    }
  },
  watch: {
    stateInitiated: {
      handler: async function (val) {
        if (val) {
          await this.fetch();
        }
      },
      immediate: true
    }
  }
};
</script>