<template>
  <div class="orgapplications">
    <v-card>
      <v-card-title class="py-0"
        ><h1 class="text-h2 mt-10 mb-7">
          {{ $t("orgApplicationsStrings.pageHeader", [currentOrgName]) }}
        </h1></v-card-title
      >
      <v-card-text>
        <template v-if="hasChildOrgs">
          <v-row class="pb-8">
            <v-col>
              <button-filter
                v-model="filters.recipientsFilter"
                :initial="filters.recipientsFilter"
                :options="recipientsFilterOptions"
                :label="$t('orgApplicationsStrings.recipientsFilterLabel')"
                :clearing.sync="clearingRecipientsFilter"
                css-class="flex-column flex-sm-row"
              ></button-filter>
            </v-col>
          </v-row>
        </template>
        <template v-if="canSearchChildOrgs">
            <v-row class="pb-8">
              <v-col>
                <child-org-search
                  :current-org-name="currentOrgName"
                  :initialOrgId="filters.selectedChildOrgId"
                  @org-changed="displayOrgChanged"
                ></child-org-search>
              </v-col>
            </v-row>
        </template>
        <v-row class="pb-8">
          <v-col>
            <button-filter
              v-model="filters.applicationStatusFilter"
              :options="applicationStatusTypes"
              :initial="filters.applicationStatusFilter"
              :clearing.sync="clearingStatusFilter"
              :label="$t('orgApplicationsStrings.applicationStatusFilterLabel')"
              css-class="flex-column flex-sm-row"
            ></button-filter>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-checkbox
              v-model="filters.includeArchivedApplications"
              :label="$t('applicationsStrings.includeArchivedFilterLabel')"
              @change="includeArchivedChanged"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="2">
            <text-field-date-picker
              v-model="filters.fromDateFilter"
              :label="$t('orgApplicationsStrings.fromDateFilterLabel')"
            ></text-field-date-picker>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <text-field-date-picker
              v-model="filters.toDateFilter"
              :label="$t('orgApplicationsStrings.toDateFilterLabel')"
            ></text-field-date-picker>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <decimal-text-field
              v-model="filters.amountFilter"
              name="amountFilter"
              :label="$t('orgApplicationsStrings.amountFilterLabel')"
            ></decimal-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-text-field
              dense
              outlined
              name="applicationNameFilter"
              v-model="filters.applicationNameFilter"
              :label="$t('orgApplicationsStrings.applicationNameFilterLabel')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-text-field
              dense
              outlined
              name="applicationIdFilter"
              v-model="filters.applicationIdFilter"
              :label="$t('orgApplicationsStrings.applicationIdFilterLabel')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-text-field
              dense
              outlined
              name="senderNameFilter"
              v-model="filters.senderNameFilter"
              :label="$t('orgApplicationsStrings.senderNameFilterLabel')"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              rounded
              small
              class="primary-inverted-button"
              @click="clearFilters"
              :block="$vuetify.breakpoint.xsOnly"
              >{{ $t("orgApplicationsStrings.clearFiltersBtn") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title class="py-0"
        ><h2 class="text-h2 mt-10 mb-7">
          {{ $t("orgApplicationsStrings.resultsSubheader", [filteredCount]) }}
        </h2></v-card-title
      >
      <v-card-text>
        <v-data-table
          :options.sync="tableOptions"
          :headers="visibleHeaders"
          :items="applications"
          v-model="selectedApplicationIdsForExport"
          item-key="id"
          :show-select="canCurrentOrgExport"
          :loading="loading"
          :no-data-text="
            this.$t('orgApplicationsStrings.applicationsTableNoDataText')
          "
          :no-results-text="
            this.$t('orgApplicationsStrings.applicationsTableNoResultsText')
          "
          :custom-sort="customSort"
          @pagination="updateFilteredCount"
        >
          <template v-slot:item.createdDate="{ item }">{{
            item.createdDate.format($t("commonStrings.dateDisplayFormat"))
          }}</template>
          <template v-slot:item.application.applicationStatus="{ item }">
            <status-label
              :application-status="item.application.applicationStatus"
              :application-orgs="item.application.orgs"
              :orgs="item.orgs"
            ></status-label>
          </template>
          <template v-slot:item.application.applicationName="{ item }">
            <router-link :to="getApplicationPage(item.application.id)">{{
              item.application.applicationName
            }}</router-link>
          </template>
          <template v-slot:item.recipientInfo="{ item }">{{
            item.recipientInfo.text
          }}</template>
          <template v-slot:item.totalAmount="{ item }">{{
            item.totalAmount | toMoney
          }}</template>
          <template v-slot:top>
            <v-toolbar flat color="white" v-if="canCurrentOrgExport">
              <v-btn
                :loading="isExporting"
                :disabled="exportButtonDisabled || isExporting"
                rounded
                small
                @click="exportApplications"
                class="primary-inverted-button"
                >{{ $t("orgApplicationsStrings.exportButton") }}</v-btn
              >
              <!-- Duplicated exportInfo with different display rules as the simplest method for making it wrap on xs
               since row/col sizes got messy and flex helper classes didn't work.
               Which I assume is because we are not able to set classes on generated toolbar markup -->
              <em class="ml-2 d-none d-sm-block">{{
                $t("orgApplicationsStrings.exportInfo")
              }}</em>
            </v-toolbar>
            <em class="mt-2 d-sm-none" v-if="canCurrentOrgExport">{{
              $t("orgApplicationsStrings.exportInfo")
            }}</em>
          </template>
          <template
            v-slot:item.data-table-select="{
              item,
              isSelected,
              select,
              isMobile
            }"
          >
            <v-checkbox
              v-if="item.selectable"
              color="primary"
              :value="isSelected"
              :label="
                isMobile ? $t('orgApplicationsStrings.exportCheckbox') : ''
              "
              :disabled="!item.selectable"
              @change="select($event)"
            ></v-checkbox>
          </template>
          <template v-slot:item.application.paymentMethod={item}>
            <payment-method-label
              :application-orgs="item.application.orgs"
              :org-ids="[currentOrgId, ...childOrgIds]"
              :orgs="item.orgs"
            ></payment-method-label>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="exportDialog" max-width="500px">
      <v-card>
        <v-card-title>
          {{ $t("orgApplicationsStrings.exportInfoModalStrings.title") }}
        </v-card-title>
        <v-card-text>
          <p>
            {{ $t("orgApplicationsStrings.exportInfoModalStrings.infoP1") }}
          </p>
          <p>
            {{ $t("orgApplicationsStrings.exportInfoModalStrings.infoP2") }}
          </p>
          <p>
            {{ $t("orgApplicationsStrings.exportInfoModalStrings.infoP3") }}
          </p>
        </v-card-text>
        <v-card-actions class="pa-5 flex-column flex-sm-row">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            class="primary-button mb-2 mb-sm-0"
            small
            @click="toggleExportModal"
          >
            {{ $t("commonStrings.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { api, urls } from "@/utils/api";
import { copy } from "@/utils/object";
import OrgApplicationStatusTypes from "@/enums/OrgApplicationStatusTypes";
import PaymentMethods from "@/enums/PaymentMethods";
import TextFieldDatePicker from "@/views/common/components/TextFieldDatePicker";
import ButtonFilter from "@/views/common/components/ButtonFilter";
import StatusLabel from "@/views/common/components/StatusLabel";
import PaymentMethodLabel from "./PaymentMethodLabel";
import { toMoney } from "@/utils/numeric";
import { getTotalApplicationAmount } from "@/utils/claim";
import DecimalTextField from "@/views/common/components/DecimalTextField";
import customSort from "@/mixins/customSort";
import { getOrgDisplayName } from "@/utils/org";
import RecipientTypes from "@/enums/RecipientTypes";
import _ from "lodash";
import Ripple from "vuetify/lib/directives/ripple";
import OrgTypes from "@/enums/OrgTypes";
import { filterConstants } from "@/utils/filterConstants";
import ChildOrgSearch from "./components/ChildOrgSearch";
import { handleError } from "@/utils/errorHandler";
import { OrgApplicationFiltersLocalStorageKey, OrgApplicationTableOptionsLocalStorageKey } from "@/utils/localStorageConstants.js";

export default {
  name: "OrgApplicationsPage",
  directives: {
    Ripple
  },
  components: {
    TextFieldDatePicker,
    ButtonFilter,
    StatusLabel,
    DecimalTextField,
    PaymentMethodLabel,
    ChildOrgSearch
  },
  mixins: [customSort],
  data() {
    return {
      tableOptions: this.getDefaultTableOptions(),
      filters: this.getDefaultFilters(),
      exportDialog: false,
      loading: true,
      applications: [],
      selectedApplicationIdsForExport: [],
      headers: [
        {
          text: this.$t("orgApplicationsStrings.createdDateTableHeader"),
          value: "createdDate",
          filter: value =>
            value.within(
              this.$moment.range(
                this.filters.fromDateFilter,
                this.filters.toDateFilter
              )
            )
        },
        {
          text: this.$t("orgApplicationsStrings.applicationNameTableHeader"),
          value: "application.applicationName",
          filter: value =>
            value
              .toLocaleLowerCase()
              .indexOf(
                this.filters.applicationNameFilter.toLocaleLowerCase()
              ) >= 0
        },
        {
          text: this.$t("orgApplicationsStrings.recipientsTableHeader"),
          value: "recipientInfo",
          sortable: false,
          filter: value =>
            (this.filters.recipientsFilter.includes(
              RecipientTypes.CurrentOrg
            ) &&
              value.currentOrgRecipient) ||
            (this.filters.recipientsFilter.includes(RecipientTypes.ChildOrgs) &&
              value.childOrgRecipientCount > 0)
        },
        {
          text: this.$t("orgApplicationsStrings.statusTableHeader"),
          value: "application.applicationStatus",
          filter: value => this.filters.applicationStatusFilter.includes(value)
        },
        {
          text: this.$t("orgApplicationsStrings.paymentMethodTableHeader"),
          value: "application.paymentMethod"
        },
        {
          text: this.$t("orgApplicationsStrings.senderNameTableHeader"),
          value: "senderName",
          filter: value =>
            !value ||
            this.filters.senderNameFilter
              .split(" ")
              .every(
                x =>
                  value.toLocaleLowerCase().indexOf(x.toLocaleLowerCase()) >= 0
              )
        },
        {
          text: this.$t("orgApplicationsStrings.amountTableHeader"),
          value: "totalAmount",
          align: "right",
          filter: value =>
              Number(this.filters.amountFilter) <= 0 ||
              Number(value) === Number(this.filters.amountFilter)
        },
        {
          text: this.$t("orgApplicationsStrings.applicationIdTableHeader"),
          value: "application.id",
          filter: value =>
            value
              .toLocaleLowerCase()
              .indexOf(this.filters.applicationIdFilter.toLocaleLowerCase()) >=
            0
        }
      ],
      applicationStatusTypes: this.getApplicationStatusTypes(),
      clearingStatusFilter: false,
      clearingRecipientsFilter: false,
      filteredCount: 0,
      childOrgIds: []
    };
  },
  computed: {
    ...mapGetters({
      currentOrgId: "contextStore/orgId",
      currentOrgTypeId: "contextStore/orgTypeId",
      currentOrgs: "currentOrgsStore/orgs",
      urlState: "utilsRestStore/urlState",
      user: "oidcStore/oidcUser"
    }),
    currentOrChildOrgId() {
      if(!this.filters.selectedChildOrgId){
        return this.currentOrgId;
      }else {
        return this.filters.selectedChildOrgId;
      }
    },
    exportButtonDisabled() {
      return (
        !this.selectedApplicationIdsForExport?.length
      );
    },
    isExporting() {
      if (
        this.selectedApplicationIdsForExport?.length
      ) {
        return this.urlState(urls.v1.applications.export());
      }

      return false;
    },
    recipientsFilterOptions() {
      return this.getRecipientsFilterOptions(this.currentOrgName);
    },
    areAllStatusesSelected() {
      return this.applicationStatusTypes.every(x =>
        this.filters.applicationStatusFilter.some(y => y === x.value)
      );
    },
    selectedStatusNames() {
      return this.applicationStatusTypes
        .filter(x =>
          this.filters.applicationStatusFilter.some(y => y === x.value)
        )
        .map(x => x.label)
        .join(", ");
    },
    currentOrgName() {
      let org;

      if (this.currentOrgId && this.currentOrgs) {
        org = this.currentOrgs.find(x => x.org.id === this.currentOrgId);
      } else {
        org = null;
      }

      if (org) {
        return getOrgDisplayName(org.org);
      } else {
        return "";
      }
    },
    hasChildOrgs() {
      return this.childOrgIds.length;
    },
    visibleHeaders() {
      if (this.hasChildOrgs) {
        return this.headers;
      } else {
        return this.headers.filter(x => x.value !== "recipientInfo");
      }
    },
    canCurrentOrgExport() {
      return (
        this.currentOrgId === this.currentOrChildOrgId &&
        this.currentOrgTypeId !== OrgTypes.GR &&
        this.currentOrgTypeId !== OrgTypes.AI
      );
    },
    canSearchChildOrgs() {
      return this.currentOrgTypeId === OrgTypes.SF || this.currentOrgTypeId === OrgTypes.SK || this.currentOrgTypeId === OrgTypes.RE;
    }
  },
  methods: {
    displayOrgChanged(val) {
      this.filters.selectedChildOrgId = val;
    },
    toggleExportModal() {
      this.exportDialog = !this.exportDialog;
    },
    async exportApplications() {
      this.toggleExportModal();
      try {
        const response = await api.v1.applications.export(
          {
            orgId: this.currentOrgId,
            applicationIds: this.selectedApplicationIdsForExport.map(x => x.id)
          },
          { responseType: "blob" }
        );

        if (response.status === 200) {
          const disposition = response.headers["content-disposition"];
          const name = disposition.split("filename*=UTF-8''")[1];
          const decoded = decodeURIComponent(name);
          const url = window.URL.createObjectURL(response.data);
          //insert temporary link in DOM and trigger click to download file
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", decoded);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
          link.remove();

          this.$set(this, "selectedApplicationIdsForExport", []);
        }
      } catch (error) {
        await handleError(error);
      }
    },
    getApplicationPage(id) {
      return {
        name: "orgApplicationDetails",
        params: { id }
      };
    },
    async fetch() {
      try {
        const response = await api.v1.orgs.applicationsWithChildren(
          this.currentOrChildOrgId, {
          params: {
            includeArchived: this.filters.includeArchivedApplications
          }
        }
        );

        if (response.status === 200) {
          let applications;

          if (response.data) {
            applications = response.data;
          } else {
            applications = [];
          }

          this.applications = this.mapApplications(applications);
        }
      } catch (error) {
        await handleError(error);
      }
    },
    async fetchChildOrgs() {
      try {
        const response = await api.v1.orgs.children(this.currentOrChildOrgId);

        if (response.status === 200) {
          let orgs;

          if (response.data) {
            orgs = response.data;
          } else {
            orgs = [];
          }

          this.childOrgIds = orgs.map(x => x.id);

        }
      } catch (error) {
        await handleError(error);
      }
    },
    getApplicationStatusTypes() {
      return Object.values(OrgApplicationStatusTypes).map(x => ({
        value: x,
        label: this.$t(`enums.applicationStatusTypes.${x}`)
      }));
    },
    getExistingFilters() {
      if (this.user?.personid) {
        return JSON.parse(localStorage.getItem(`${OrgApplicationFiltersLocalStorageKey}${this.user.personid}`));
      }

      return null;
    },
    getDefaultFilters() {
        return {
          applicationStatusFilter: this.getApplicationStatusTypes().map(
            x => x.value
          ),
          fromDateFilter: "",
          toDateFilter: "",
          amountFilter: 0,
          applicationNameFilter: "",
          applicationIdFilter: "",
          senderNameFilter: "",
          recipientsFilter: this.getRecipientsFilterOptions().map(x => x.value),
          includeArchivedApplications: false,
          selectedChildOrgId: 0
        };
    },
    getExistingTableOptions() {
      if (this.user?.personid) {
        return JSON.parse(localStorage.getItem(`${OrgApplicationTableOptionsLocalStorageKey}${this.user.personid}`));
      }

      return null;
    },
    getDefaultTableOptions() {
      return {
        page: 1,
        itemsPerPage: filterConstants.orgApplications.defaultItemsPerPage,
        sortBy: ["createdDate"],
        sortDesc: [true]
      };
    },
    clearFilters() {
      this.clearingStatusFilter = true;
      this.clearingRecipientsFilter = true;
      localStorage.removeItem(`${OrgApplicationFiltersLocalStorageKey}${this.user.personid}`);
      this.filters = this.getDefaultFilters();
    },
    getSenderName(person) {
      if (person) {
        return `${person.lastName}, ${person.firstName}`;
      } else {
        return "";
      }
    },
    mapApplications(applications) {
      return applications.map(x => ({
        id: x.application.id,
        selectable: this.isSelectable(x),
        original: copy(x.application),
        application: x.application,
        createdDate: this.$moment(
          x.application.createdDate,
          this.$t("commonStrings.dateValueFormat")
        ).startOf("day"),
        totalAmount: getTotalApplicationAmount(x.application),
        senderName: this.getSenderName(x.senderInformation),
        orgs: x.orgs,
        recipientInfo: this.getRecipientInfo(x)
      }));
    },
    getExportableOrgIds(applicationOrgs) {
      const paymentMethodsRequiringPaymentDate = [
        PaymentMethods.Buypass
      ];

      const approvedOrgs = [];

      for (const kvp of Object.entries(applicationOrgs)) {
        const applicationOrg = kvp[1];
        const orgId = parseInt(kvp[0], 10);

        if (!applicationOrg.paymentMethod) {
          return [];
        }

        const paymentRequired = paymentMethodsRequiringPaymentDate.includes(applicationOrg.paymentMethod);

        if (paymentRequired && !applicationOrg.paymentDate){
          return [];
        }

        approvedOrgs.push(orgId);
      }

      return approvedOrgs;
    },
    isSelectable(application) {
      const orgs = [this.currentOrgId, ...this.childOrgIds];
      const exportableApplicationOrgIds = this.getExportableOrgIds(
        application.application.orgs
      );

      const intersects = _.intersection(orgs, exportableApplicationOrgIds);
      return !!(intersects?.length);
    },
    updateFilteredCount(pageInfo) {
      this.filteredCount = pageInfo.itemsLength;
    },
    getRecipientInfo(application) {
      const currentOrgRecipient = this.isCurrentOrgRecipient(application);
      const childOrgRecipientCount = this.getChildOrgRecipientsCount(
        application
      );

      return {
        currentOrgRecipient,
        childOrgRecipientCount,
        text: this.getRecipientText(currentOrgRecipient, childOrgRecipientCount)
      };
    },
    isCurrentOrgRecipient(application) {
      return application.orgs.filter(x => x.id === this.currentOrgId).length;
    },
    getChildOrgRecipientsCount(application) {
      return application.orgs.filter(x => this.childOrgIds.includes(x.id))
        .length;
    },
    getRecipientTextCurrentOrgSegment(currentOrgRecipient) {
      if (currentOrgRecipient) {
        return this.currentOrgName;
      }

      return "";
    },
    getRecipientTextChildOrgSegment(childOrgRecipientCount) {
      if (childOrgRecipientCount > 0) {
        return this.$t("orgApplicationsStrings.childOrgs", [childOrgRecipientCount]);
      }

      return "";
    },
    getRecipientTextCombinationSegment(currentOrgSegment, childOrgSegment) {
      if (currentOrgSegment.length && childOrgSegment.length) {
        return " + ";
      }

      return "";
    },
    getRecipientText(currentOrgRecipient, childOrgRecipientCount) {
      const currentOrgSegment = this.getRecipientTextCurrentOrgSegment(currentOrgRecipient);
      const childOrgSegment = this.getRecipientTextChildOrgSegment(childOrgRecipientCount);
      const combinationSegment = this.getRecipientTextCombinationSegment(currentOrgSegment, childOrgSegment);

      return `${currentOrgSegment}${combinationSegment}${childOrgSegment}`;
    },
    getRecipientsFilterOptions(orgName) {
      return [
        {
          value: RecipientTypes.CurrentOrg,
          label: orgName
        },
        {
          value: RecipientTypes.ChildOrgs,
          label: this.$t(
            "orgApplicationsStrings.recipientsFilterOptionChildOrgs"
          )
        }
      ];
    },
    async includeArchivedChanged(){
      this.loading = true;
      await this.fetch();
      this.loading = false;
    }
  },
  watch: {
    currentOrgId: {
      handler: async function (val) {
        if (this.loading && val) {

          await this.fetchChildOrgs();
          await this.fetch();

          this.loading = false;
        }
      },
      immediate: true
    },
    async "filters.selectedChildOrgId"(id) {
      if(id > 0 && !this.loading) {
          this.loading = true;

          await this.fetchChildOrgs();
          await this.fetch();

          this.loading = false;
      }
    },
    user: {
      handler: function (val) {
        if (val?.personid) {
          const existingFilters = this.getExistingFilters();

          if (existingFilters) {
            this.filters = existingFilters;
          }

          const existingTableOptions = this.getExistingTableOptions();

          if (existingTableOptions) {
            this.tableOptions = existingTableOptions;
          }
        }
      },
      immediate: true
    },
    filters: {
      handler(val) {
        localStorage.setItem(`${OrgApplicationFiltersLocalStorageKey}${this.user.personid}`, JSON.stringify(val));
      },
      deep: true
    },
    tableOptions(val) {
      localStorage.setItem(`${OrgApplicationTableOptionsLocalStorageKey}${this.user.personid}`, JSON.stringify(val));
    }
  },
  filters: {
    toMoney
  }
};
</script>