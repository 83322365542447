<template>
  <v-skeleton-loader :loading="loading" type="card-heading, table-thead, table-row-divider@8">
    <v-card>
      <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{$t('rateConfigurationStrings.diet.header')}}</h2></v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" lg="4" xl="3">
            <validation-provider
              rules="finance_account"
              :name="$t('rateConfigurationStrings.diet.accountingLabel')"
              :skipIfEmpty="false"
              vid="diet.accountingId"
              v-slot="{ errors }"
            >
              <v-text-field
              outlined
              dense
                v-model="dietRates.accountingId"
                :error-messages="errors"
                :label="$t('rateConfigurationStrings.diet.accountingLabel')"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" lg="4" xl="3">
            <validation-provider
              rules="finance_account"
              :name="$t('rateConfigurationStrings.diet.nightAdditionAccountingLabel')"
              :skipIfEmpty="false"
              vid="diet.nightAdditionAccountingId"
              v-slot="{ errors }"
            >
              <v-text-field
              outlined
              dense
                v-model="dietRates.nightAdditionAccountingId"
                :error-messages="errors"
                :label="$t('rateConfigurationStrings.diet.nightAdditionAccountingLabel')"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="8" xl="6">
            <v-card-subtitle><h3 class="text-h3">{{$t("rateConfigurationStrings.diet.lengthSubheader")}}</h3></v-card-subtitle>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      id="dietLengthEnabledHeader"
                      class="text-left"
                    >{{$t('rateConfigurationStrings.diet.lengthEnabledHeader')}}</th>
                    <th
                      id="dietLengthRateHeader"
                      class="text-left"
                    >{{$t('rateConfigurationStrings.diet.lengthRateHeader')}}</th>
                    <th
                      id="dietLengthAmountHeader"
                      class="text-left"
                    >{{$t('rateConfigurationStrings.diet.lengthAmountHeader')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in dietRates.dietLengthRateTypes" :key="item.rateType">
                    <td>
                      <validation-provider rules :vid="getDietLengthEnabledValidationId(item.rateType)">
                        <v-checkbox
                          color="primary"
                          v-model="item.enabled"
                          :aria-label="$t('commonStrings.tableAriaLabel',[$t('rateConfigurationStrings.diet.lengthEnabledHeader'), $t(`enums.dietLengthTypes.${item.rateType}`)])"
                        ></v-checkbox>
                      </validation-provider>
                    </td>
                    <td>{{$t(`enums.dietLengthTypes.${item.rateType}`)}}</td>
                    <td>
                      <validation-provider
                        :rules="{required_if:getDietLengthEnabledValidationId(item.rateType), integer: true, min_value: item.enabled ? 1 : 0}"
                        v-slot="{ errors }"
                        :name="$t('rateConfigurationStrings.diet.lengthAmountHeader')"
                      >
                        <integer-text-field
                          v-model="item.rate"
                          name="rate"
                          :messages="verifyLengthTypeTaxRatesWarnings(item)"
                          :error-messages="errors"
                          :disabled="!item.enabled"
                          :aria-label="$t('commonStrings.tableAriaLabel',[$t('rateConfigurationStrings.diet.lengthAmountHeader'), $t(`enums.dietLengthTypes.${item.rateType}`)])"
                        >
                        </integer-text-field>
                      </validation-provider>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" lg="8" xl="6">
            <v-card-subtitle><h3 class="text-h3">{{$t("rateConfigurationStrings.diet.staySubheader")}}</h3></v-card-subtitle>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      id="dietStayEnabledHeader"
                      class="text-left"
                    >{{$t('rateConfigurationStrings.diet.stayEnabledHeader')}}</th>
                    <th
                      id="dietStayRateHeader"
                      class="text-left"
                    >{{$t('rateConfigurationStrings.diet.stayRateHeader')}}</th>
                    <th
                      id="dietStayAmountHeader"
                      class="text-left"
                    >{{$t('rateConfigurationStrings.diet.stayAmountHeader')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in dietRates.dietStayRateTypes" :key="item.rateType">
                    <td>
                      <validation-provider rules :vid="getDietStayEnabledValidationId(item.rateType)">
                        <v-checkbox
                          color="primary"
                          v-model="item.enabled"
                          :aria-label="$t('commonStrings.tableAriaLabel',[$t('rateConfigurationStrings.diet.stayEnabledHeader'), $t(`enums.dietStayTypes.${item.rateType}`)])"
                        ></v-checkbox>
                      </validation-provider>
                    </td>
                    <td>{{$t(`enums.dietStayTypes.${item.rateType}`)}}</td>
                    <td>
                      <validation-provider
                        :rules="{required_if:getDietStayEnabledValidationId(item.rateType), integer: true, min_value: item.enabled ? 1 : 0}"
                        v-slot="{ errors }"
                        :name="$t('rateConfigurationStrings.diet.stayAmountHeader')"
                      >
                        <integer-text-field
                          v-model="item.rate"
                          name="rate"
                          :messages="verifyStayTypeTaxRatesWarnings(item)"
                          :error-messages="errors"
                          :disabled="!item.enabled"
                          :aria-label="$t('commonStrings.tableAriaLabel',[$t('rateConfigurationStrings.diet.stayAmountHeader'), $t(`enums.dietStayTypes.${item.rateType}`)])"
                        >
                        </integer-text-field>
                      </validation-provider>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { toMoney } from "@/utils/numeric";
import IntegerTextField from "@/views/common/components/IntegerTextField";

export default {
  name: "DietSettings",
  data: () => ({}),
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    dietRates: {
      type: Object,
      required: true
    },
    dietTaxRates: {
      type: Object,
      required: true
    }
  },
  watch: {},
  components: {
    ValidationProvider,
    IntegerTextField
  },
  computed: {
    isAnyDietEnabled() {
      return (
        this.dietRates.dietStayRateTypes.some(x => x.enabled) ||
        this.dietRates.dietLengthRateTypes.some(x => x.enabled)
      );
    }
  },
  methods: {
    verifyLengthTypeTaxRatesWarnings(item) {
      return this.verifyTaxRatesWarnings(item, this.getLengthTypeMaxTaxRate, this.hasPositiveLengthTypeMaxTaxRate);
    },
    verifyStayTypeTaxRatesWarnings(item) {
      return this.verifyTaxRatesWarnings(item, this.getStayTypeMaxTaxRate, this.hasPositiveStayTypeMaxTaxRate);
    },
    verifyTaxRatesWarnings(item, maxRateMethod, positiveRateMethod){
      if (!item) {
        return [];
      }

      const maxTaxRate = maxRateMethod(item.rateType);

      if (
        positiveRateMethod(item.rateType) &&
        item.rate > maxTaxRate
      ) {
        return this.$t("warnings.exceedTaxRate", [toMoney(maxTaxRate)]);
      }

      // no warnings
      return [];
    },
    getDietLengthEnabledValidationId(rateType) {
      return `enabled_dietLengthType_${rateType}`;
    },
    getDietStayEnabledValidationId(rateType) {
      return `enabled_dietStayType_${rateType}`;
    },
    hasPositiveStayTypeMaxTaxRate(rateType) {
      const maxRate = this.getStayTypeMaxTaxRate(rateType);
      return maxRate && maxRate > 0;
    },
    getStayTypeMaxTaxRate(rateType) {
      if (this.dietTaxRates?.dietStayTypeMaxTaxRates) {
        const res = this.dietTaxRates.dietStayTypeMaxTaxRates.find(
          x => x.claimType === rateType
        );

        if(res) {
          return res.maxTaxRate;
        }
      }
      return null;
    },
    hasPositiveLengthTypeMaxTaxRate(rateType) {
      const maxRate = this.getLengthTypeMaxTaxRate(rateType);
      return maxRate && maxRate > 0;
    },
    getLengthTypeMaxTaxRate(rateType) {
      if (this.dietRates && this.dietTaxRates.dietLengthTypeMaxTaxRates) {
        const res = this.dietTaxRates.dietLengthTypeMaxTaxRates.find(
          x => x.claimType === rateType
        );

        if (res) {
          return res.maxTaxRate;
        }
      }
      return null;
    }
  }
};
</script>