<template>
  <div class="px-2 pb-4">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbBlock"
};
</script>