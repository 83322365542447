<template>
  <div>
    <v-row dense>
      <v-col :xl="2" :md="6" :cols="12">
        <v-row dense>
          <v-col :cols="6">
            <text-field-date-picker
              v-model="diet.fromDate"
              :label="$t('dietPanelStrings.labels.fromDate')"
              :allowed-dates="
                (x) =>
                  (!diet.toDate ||
                    $moment(x).isSameOrBefore(diet.toDate, 'days')) &&
                  $moment(x).isSameOrBefore($moment(), 'days')
              "
            ></text-field-date-picker>
          </v-col>
          <v-col :cols="6">
            <text-field-time-picker
              v-model="diet.fromDate"
              :label="$t('dietPanelStrings.labels.time')"
            ></text-field-time-picker>
          </v-col>
        </v-row>
      </v-col>
      <v-col :xl="2" :md="6" :cols="12">
        <v-row dense>
          <v-col :cols="6">
            <text-field-date-picker
              v-model="diet.toDate"
              :label="$t('dietPanelStrings.labels.toDate')"
              :allowed-dates="
                (x) =>
                  diet.fromDate &&
                  $moment(x).isSameOrAfter(diet.fromDate, 'days') &&
                  $moment(x).isSameOrBefore($moment(), 'days') &&
                  $moment(x).isSameOrBefore(
                    $moment(diet.fromDate).add(30, 'd'),
                    'days'
                  )
              "
            ></text-field-date-picker>
          </v-col>
          <v-col :cols="6">
            <text-field-time-picker
              v-model="diet.toDate"
              :label="$t('dietPanelStrings.labels.time')"
            ></text-field-time-picker>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="12" :sm="6" :md="3" :lg="2" :xl="1">
        <v-row dense>
          <v-col>
            <validation-provider
              rules="required|integer|min_value:15"
              v-slot="{ errors }"
              :name="$t('dietPanelStrings.labels.distance')"
            >
              <integer-text-field
                v-model="diet.distance"
                :error-messages="errors"
                :label="$t('dietPanelStrings.labels.distance')"
              ></integer-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="canUseSalaryFraction"
        :cols="12"
        :sm="6"
        :md="3"
        :lg="2"
        :xl="1"
      >
        <v-row dense>
          <v-col>
            <v-checkbox
              color="primary"
              :label="$t('commonStrings.salaryFractionLabel')"
              :disabled="!salaryFractionSplitAvailable"
              v-model="diet.usesSalaryFraction"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        :cols="12"
        :md="canUseSalaryFraction ? 6 : 9"
        :lg="canUseSalaryFraction ? 8 : 10"
        :xl="3"
      >
        <v-row dense>
          <v-col>
            <validation-provider
              v-if="!diet.usesSalaryFraction"
              rules="required"
              v-slot="{ errors }"
              :name="$t('dietPanelStrings.labels.payingOrgs')"
            >
              <v-select
                multiple
                item-value="id"
                item-text="name"
                chips
                small-chips
                deletable-chips
                :disabled="payingOrgsIsDisabled"
                :items="availablePayingOrgs"
                v-model="diet.payingOrgs"
                :error-messages="errors"
                outlined
                dense
                :label="$t('dietPanelStrings.labels.payingOrgs')"
                name="payingOrgs"
                :placeholder="$t('dietPanelStrings.placeholders.payingOrgs')"
              >
                <template v-slot:append>
                  <v-icon tag="span">mdi-menu-down</v-icon>
                </template>
              </v-select>
            </validation-provider>
            <v-tooltip top v-else color="black">
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" class="my-0">
                  {{ $t("dietPanelStrings.splitBySalaryFraction") }}
                  <v-icon tag="span" class="ml-2">info</v-icon>
                </v-chip>
              </template>
              <ul>
                <li
                  v-for="(parts, orgId) in salaryFractions"
                  :key="orgId"
                  class="mb-1"
                >
                  {{
                    $tc("dietPanelStrings.salaryFractionExplanation", parts, {
                      org: getOrgNameByOrgId(orgId),
                      parts,
                      total: totalFractionsCount,
                    })
                  }}
                </li>
              </ul>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="12" :xl="3">
        <v-row dense>
          <v-col>
            <v-text-field
              v-model="diet.comment"
              dense
              outlined
              :label="$t('dietPanelStrings.labels.comment')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TextFieldDatePicker from "@/views/common/components/TextFieldDatePicker.vue";
import TextFieldTimePicker from "@/views/common/components/TextFieldTimePicker.vue";
import IntegerTextField from "@/views/common/components/IntegerTextField";
import { ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import { copy } from "@/utils/object";
import { getOrgDisplayName } from "@/utils/org";

export default {
  name: "DietInformation",
  components: {
    TextFieldDatePicker,
    TextFieldTimePicker,
    ValidationProvider,
    IntegerTextField
  },
  props: {
    diet: {
      type: Object,
      required: true
    },
    canUseSalaryFraction: {
      required: true
    }
  },
  watch: {
    "diet.usesSalaryFraction"(val) {
      if (val) {
        this.$set(this.diet, "payingOrgs", this.salaryFractionOrgIds);
      } else {
        this.$set(this.diet, "payingOrgs", []);
      }
    },
    salaryFractionOrgIds(val) {
      if (this.diet.usesSalaryFraction) {
        if (val) {
          this.$set(this.diet, "payingOrgs", val);
        } else {
          this.$set(this.diet, "payingOrgs", []);
        }
      }
    }
  },
  methods: {
    getOrgNameByOrgId(orgId) {
      orgId = parseInt(orgId, 10);

      const org = this.currentApplicationOrgs.find(x => x.id === orgId);

      if (org != null) {
        return getOrgDisplayName(org);
      }

      return "";
    }
  },
  computed: {
    ...mapGetters({
      salaryFractions: "application/salaryFractionsStore/fractions",
      payingOrgs: "application/payingOrgsStore/payingOrgs",
      getRateConfiguration:
        "application/rateConfigurationsStore/rateConfiguration",
      currentApplicationOrgs: "application/currentApplicationStore/orgs",
      totalFractionsCount:
        "application/salaryFractionsStore/totalFractionsCount",
      salaryFractionSplitAvailable:
        "application/salaryFractionsStore/salaryFractionSplitAvailable",
      salaryFractionOrgIds: "application/salaryFractionsStore/uniqueOrgIds"
    }),
    payingOrgsIsDisabled() {
      return this.diet.usesSalaryFraction;
    },
    availablePayingOrgs() {
      // from date and to date are mandatory
      if (!this.diet?.fromDate || !this.diet?.toDate) {
        return [];
      }

      const range = this.$moment.range(this.diet.fromDate, this.diet.toDate);

      // if the duration is more than just a couple of days it is usually a problem
      // adding this check, just to avoid excessive calculations
      if (range.diff("days") > 30) {
        return [];
      }

      const daysInRange = [...range.by("day")];
      const that = this;

      const result = [];
      for (const org of this.payingOrgs) {
        const rateConfigs = daysInRange
          .map(x => that.getRateConfiguration(org.id, x, "dietRates"))
          .filter(x => x);

        if (rateConfigs && rateConfigs.length > 0) {
          const orgCopy = copy(org);

          orgCopy.name = getOrgDisplayName(orgCopy);

          if (rateConfigs.length !== daysInRange.length) {
            // useful information to be displayed in
            // dropdown text, yet it is hard to
            // implement it via slots, so should be researched
            orgCopy.dropdownName = this.$t(
              "dietPanelStrings.notAvailableForCompletePeriod",
              [orgCopy.name]
            );

            orgCopy.name = `${orgCopy.name}`;
          } else {
            orgCopy.dropdownName = orgCopy.name;
          }

          result.push(orgCopy);
        }
      }

      return result;
    }
  }
};
</script>