import OrgTypes from "@/enums/OrgTypes";
import i18n from '@/plugins/i18n';

export const getOrgDisplayName = function (org) {
  if (!org) {
    return "";
  }

  if (org.orgTypeId === OrgTypes.GR && org.sportName?.length) {
    return `${org.name} - ${org.sportName}`;
  } else if (org.orgTypeId === OrgTypes.AI && org.orgTypeName?.length) {
    return `${org.name} - ${org.orgTypeName}`;
  } else {
    return org.name;
  }
};

export const getSingleOrMultiSportText = function (isSingleSport) {
  if (isSingleSport) {
    return i18n.t("commonStrings.singleSportClub");
  } else {
    return i18n.t("commonStrings.multiSportClub");
  }
};

export const getOrgTypeDisplayName = function (org) {
  if(!org){
    return "";
  }

  if(org.orgTypeId === OrgTypes.KL) {
    return `${org.orgTypeName} (${getSingleOrMultiSportText(org.isSingleSport)})`;
  }

  return org.orgTypeName;
};

export const getOrgNameWithOrgTypeCode = function (org) {
  if(!org){
    return "";
  }

  return `${getOrgDisplayName(org)} (${org.orgTypeNo})`;

};
