<template>
  <v-alert
    class="blue-lighten-5"
    border="left"
    colored-border
    :color="alertType"
    dense
    prominent
    :type="alertType"
  >
    <strong>{{ statusText }}</strong>
    <v-spacer></v-spacer>
    <span
      v-if="isDurationValid && isDietEligibleForPayment"
    >{{ $t('dietPanelStrings.durationIs') }} {{ durationText }}</span>
  </v-alert>
</template>

<script>
import {
  hasFiveHoursAtNight,
  dietRestrictionConstants
} from "@/utils/claims/diet";
import DietDurationTypes from "@/enums/DietDurationTypes";
export default {
  name: "DietStatus",
  props: {
    fromDate: {
      type: String
    },
    toDate: {
      type: String
    },
    distance: {
      type: [String, Number]
    }
  },
  computed: {
    numericDistance() {
      // v-text-field always converts
      // input data to text so using this
      // computed value to do correct comparison
      return parseInt(this.distance, 10);
    },
    isDurationValid() {
      // milliseconds of duration can be negative
      // only if to date is smaller (in past) than from date
      // then duration period will be invalid, thus
      // accepting positive values only
      return this.duration.asMilliseconds() > 0;
    },
    durationText() {
      return [this.daysPartDurationText, this.hoursPartDurationTexthoursPart, this.minutesPartDurationTextminutesPart].join(" ").trim();
    },
    daysPartDurationText() {
      const daysDuration = this.duration.days();

      if (daysDuration) {
        return this.$tc("dietPanelStrings.daysDuration", daysDuration);
      }

      return "";
    },
    hoursPartDurationText() {
      const hoursDuration = this.duration.hours();

      if (hoursDuration) {
        return this.$tc("dietPanelStrings.hoursDuration", hoursDuration);
      }

      return "";
    },
    minutesPartDurationText() {
      const minutesDuration = this.duration.minutes();

      if (minutesDuration) {
        return this.$tc("dietPanelStrings.minutesDuration", minutesDuration);
      }

      return "";
    },
    duration() {
      const diff = this.$moment(this.toDate).diff(this.$moment(this.fromDate));

      return this.$moment.duration(diff);
    },
    isDietEligibleForPayment() {
      return this.status !== DietDurationTypes.NotEligible;
    },
    alertType() {
      if (!this.isDietEligibleForPayment) {
        return "warning";
      }

      return "info";
    },
    statusText() {
      const durationHours = this.duration.asHours();

      if (
        this.numericDistance <
        dietRestrictionConstants.minimalSufficientDistance
      ) {
        return this.$t("dietPanelStrings.insufficientDistance", [
          dietRestrictionConstants.minimalSufficientDistance
        ]);
      }

      if (durationHours < dietRestrictionConstants.minimalDurationHours) {
        return this.$t("dietPanelStrings.insufficientDuration", [
          dietRestrictionConstants.minimalDurationHours
        ]);
      }

      return `${this.$t("dietPanelStrings.diet")} ${this.$t(
        `enums.dietDurationTypes.${this.status}`
      ).toLocaleLowerCase()}`;
    },
    status() {
      const durationHours = this.duration.asHours();

      if (
        this.numericDistance <
        dietRestrictionConstants.minimalSufficientDistance
      ) {
        return DietDurationTypes.NotEligible;
      }

      if (durationHours < dietRestrictionConstants.minimalDurationHours) {
        return DietDurationTypes.NotEligible;
      }

      if (hasFiveHoursAtNight(this.fromDate, this.toDate)) {
        return DietDurationTypes.WithOvernight;
      }

      if (durationHours < 12) {
        return DietDurationTypes.SixToTwelveHours;
      }

      return DietDurationTypes.Above12Hours;
    }
  }
};
</script>
