const help = {
  None: {
    title: "Idrettsoppgjør",
    content: ""
  },
  Applications: {
    general: {
      title: "Søk og filtrer",
      content:
        `Sjekk alltid at du er innlogget med riktig bruker eller representant for riktig organisasjonsledd.

For å finne detaljer om et krav, trykk på kravet under «Kravet gjelder».`
    }
  },
  Application: {
    claim: {
      title: "Informasjon om kravet",
      content:
        `Hva gjelder dette kravet? Kort og informativt om hva kravet dekker slik at behandler raskt forstår bakgrunnen for kravet.

Alltid angi/sjekk at kravet sendes til korrekt mottaker.

Bruk fortrinnsvis en bankkonto som står i ditt navn. Dersom du kun har disposisjonsrett på kontoen (eller ikke er eier i det hele tatt), kan utbetaling bli stoppet/forsinket.`
    },
    salary: {
      title: "Honorar",
      content:
        `Har du vært dommer, instruktør, trener, e.l. og har krav på et honorar, legg det inn her. Fyll inn alle feltene nøyaktig. 

Dersom kravet dekker flere aktiviteter, legg til ett honorar per aktivitet. Dersom «honorartype» som kan legges inn ikke dekker ditt krav, bruk honorartype «fritekst». 

Mottaker vil behandle eventuell innberetningspliktig utbetalinger og arbeidsgiveravgift i etterkant. Ikke send fødselsnummer (11 sifre) i denne løsningen. 

Dersom du vet at kravet ditt skal lønnsinnberettes for første gang hos mottaker, ta kontakt direkte for informasjon om dette.`
    },
    trips: {
      title: "Kjøregodtgjørelse",
      content:
        `Har du vært på reise og har krav på kjøregodtgjørelse, legg det inn her – total lengde for kjøreturen. Fyll inn alle feltene nøyaktig. 

Dersom kjøringen dekker flere strekk og det er ulikt antall passasjerer, del opp turen i flere deler slik at det samlet stemmer med faktisk kjøring. 

Sjekk at beløpet som kreves stemmer. Dokumentasjon av beregningen finner du ved å trykke på det informasjonsikon. 

Mottaker vil behandle eventuelle innberetningspliktige utbetalinger i etterkant.`
    },
    diet: {
      title: "Diett",
      content:
        `Har du krav på diett, legg det inn her. Vær nøyaktig med tidspunkter. Systemet beregner tidsrommet for reisen som et grunnlag for kravet ditt.

Husk å legge inn antall km fra hjemmet, slik at det kan dokumenteres om kravet er fradragsberettiget (min. 15 km fra reisens utgangspunkt/hjemme) for deg personlig.

Du må også huke av per måltid om du har fått disse påspandert/betalt av andre.

Når varigheten av reisen er beregnet og du har vært borte mer enn ett døgn, legg inn hvor du har overnattet.
Har du overnattet privat, har du krav på nattillegg samt diett for hybel eller pensjonat.

Mottaker vil behandle eventuelle innberetningspliktige utbetalinger i etterkant.`
    },
    receipts: {
      title: "Utlegg og kvitteringer",
      content:
        `Skal du ha refundert for et innkjøp, dekke kostnader i henhold til en avtale, eller har du kjøpt en billett e.l., legg det inn her. Fyll inn alle feltene nøyaktig.

Husk å laste opp kvitteringer der det er påkrevd. Dersom flere personer er involvert (f.eks. i et måltid), dokumenter det.

Antall utleggstyper er begrenset, men vil bli utvidet. Bruk «Andre utlegg» dersom du ikke finner en relevant hovedkategori, og skriv inn hva det gjelder i «Merknad».`
    },
    summary: {
      title: "Oppsummering",
      content:
        `Når du har fylt inn kravene dine over, vil disse oppsummeres her.
         Dersom flere mottakere/organisasjonsleddet skal dekke kravet, vil du se hva hver enhet skal betale deg.

Dersom summen ikke stemmer, sjekk at alle påkrevde felt over er fylt inn. 

Når du er ferdig, sender du enten kravet til godkjenning eller lagrer refusjonen som kladd. Du kan angre innsending dersom mottaker ikke har godkjent kravet.

Det vil komme funksjonalitet for sletting av krav som ikke sendes inn.`
    }
  },
  PersonalSettings: {
    general: {
      title: "E-postvarsling",
      content: `Trykk på checkboks for å slå av eller på varsling per mail (mailadressen som ligger på profilen din i Min Idrett) for de ulike valgene.`
    }
  },
  OrgApplications: {
    general: {
      title: "Søk og filtrer",
      content:
        `For å finne detaljer om et krav, trykk for kravet under «Kravet gjelder».

Listen viser:
Dato: Datoen kravet ble opprettet.
Kravet gjelder: Overskriften i kravet bør være så dekkende som mulig for hva kravet gjelder.
Mottaker: Vises bare på IL-nivå og angir om noen av gruppene i idrettslaget også har mottatt kravet.
Status: Se linken over til web-sidene
Avsender: Navn på den som har et krav.
Beløp (kr): Beløpet på hele kravet. Dersom du ønsker å vite krav for ditt organisasjonsledd, må du trykke deg inn til detaljene.
ID: ID-en er unik for kravet.`
    }
  },
  ApplicationPreview: {
    general: {
      title: "Behandle krav",
      content:
        `På denne siden ligger vedlegg til krav. Disse må være tilgjengelige og lett sporbare ved behov, som dokumentasjon til regnskapet.

Persondata må behandles i henhold til GDPR/NIF sin personvern-policy. Regnskapsbilag må oppbevares i 5 år.`
    }
  },
  OrgSettings: {
    generalSettings: {
      title: "Generelle innstillinger",
      content:
        `Først må ny bruker slå på «Ta i bruk Idrettsoppgjør», samt ta stilling til om undergrupper i et idrettslag skal kunne bruke løsningen.

Man kan så slå på bruken av Buypass for utbetalinger. Informasjon om dette er i egen link.

Er det ønske om at bilag og/eller csv skal inneholde et fullstendig grunnlag for regnskapsføring må kontonummer på balansekontoer legges inn.`
    },
    backoffice: {
      title: "Oppsett refusjonsskjema",
      content:
        `Under «Oppsett refusjonsskjema» kan du for hvert definerte tidsintervall se hvilke satser og typer refusjoner som har vært gyldig.
         Disse fryses når man legger inn nye satser i nyere perioder.
        Dersom man legger inn et krav som har sin opprinnelse fra en tidligere periode, blir dagens satser brukt. 
        Godkjente krav blir heller ikke endret dersom satser endres. Dette må håndteres manuelt/utenom systemet dersom nødvendig. 

Satser som brukes, er satser ved innsendingstidspunktet. Tilsvarende satser fryses når et krav er godkjent.

Innlegging av hovedbokskonto er frivillig. Den blir med i bilag/CSV-fil, men brukes ikke noe videre enda. 

Nattillegg er rent praktisk gruppert under diett. Har du overnattet privat, har du krav på dette samt diett for hybel eller pensjonat.

Idrettsoppgjør kan ikke benyttes for diett ved utenlandsreiser.`
    }
  }
};

export default help;