<template>
  <v-col cols="12">
    <v-card outlined>
      <v-card-title class="py-0">
        <h2 class="text-h2 mt-10 mb-7">{{$t("journalStrings.table.title")}}</h2>
      </v-card-title>
      <v-data-table
        hide-default-footer
        dense
        :headers="headers"
        :items="categoryRows"
        :items-per-page="-1"
        mobile-breakpoint="0"
      >
        <template v-slot:item="{item, headers}">
          <tr v-if="!item.isSummary">
            <td>{{item.claimType}}</td>
            <td>{{item.accountNo}}</td>
            <td>{{item.date | moment($t("commonStrings.dateDisplayFormat")) }}</td>
            <td>{{item.comment}}</td>
            <td>{{item.count}}</td>
            <td v-if="item.rate">{{(item.rate) | toMoney}}</td>
            <td v-else></td>
            <td>{{(item.amount || 0) | toMoney}}</td>
          </tr>

          <tr v-if="item.isSummary && item.amount">
            <td :colspan="headers.length - 2" class="font-weight-medium">{{item.claimCategory}}</td>
            <td style="text-align: right">{{$t("journalStrings.table.categoryTotal")}}</td>
            <td>{{item.amount | toMoney}}</td>
          </tr>
        </template>

        <template v-slot:body.append="{ headers }">
          <tr>
            <td
              :colspan="headers.length - 1"
              style="text-align: right"
            >{{$t("journalStrings.table.total")}}</td>
            <td>{{(total || 0) | toMoney}}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>
<script>
import _ from "lodash";

export default {
  name: "JournalClaimsTable",
  data() {
    return {
      headers: [
        {
          text: this.$t("journalStrings.table.header.type"),
          align: "left",
          sortable: false,
          value: "claimType"
        },
        {
          text: this.$t("journalStrings.table.header.account"),
          align: "left",
          sortable: false,
          value: "accountNo"
        },
        {
          text: this.$t("journalStrings.table.header.date"),
          align: "left",
          sortable: false,
          value: "date"
        },
        {
          text: this.$t("journalStrings.table.header.comment"),
          align: "left",
          sortable: false,
          value: "comment"
        },
        {
          text: this.$t("journalStrings.table.header.count"),
          align: "left",
          sortable: false,
          value: "count"
        },
        {
          text: this.$t("journalStrings.table.header.rate"),
          align: "left",
          sortable: false,
          value: "rate"
        },
        {
          text: this.$t("journalStrings.table.header.amount"),
          align: "left",
          sortable: false,
          value: "amount"
        }
      ]
    };
  },
  computed: {
    total() {
      return _.sum(
        this.categoryRows
          .filter(x => x?.amount && x.isSummary)
          .map(x => x.amount)
      );
    }
  },
  props: {
    categoryRows: {
      type: Array,
      required: true
    }
  }
};
</script>