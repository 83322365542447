<template>
  <div>
    <v-simple-table>
      <thead>
        <th>{{ $t("attachmentListStrings.filenameTableHeader") }}</th>
        <th></th>
        <th v-if="showRemove"></th>
      </thead>
      <tbody>
        <tr v-for="(attachment, index) in attachments" :key="index">
          <td>{{attachment.filename}}</td>
          <td>
            <v-btn
              rounded
              small
              class="primary-inverted-button"
              @click="downloadFile(attachment)"
            >{{ $t("attachmentListStrings.labels.downloadButton") }}</v-btn>
          </td>
          <td v-if="showRemove">
            <v-btn
              rounded
              small
              class="accent-inverted-button"
              @click="removeFile(index)"
            >{{ $t("attachmentListStrings.labels.removeButton") }}</v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "AttachmentList",
  props: {
    showRemove: Boolean,
    attachments: {
      default: () => [],
      type: Array
    }
  },
  methods: {
    downloadFile(attachment) {
      this.$emit("download", attachment);
    },
    removeFile(index) {
      this.attachments.splice(index, 1);
    }
  }
};
</script>