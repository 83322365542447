<template>
  <div>
    <template v-if="toDraftAvailable && createdByCurrentUser">
      <v-btn
        :disabled="isSettingToDraft || isTypingResponse"
        :loading="isSettingToDraft"
        rounded
        :block="$vuetify.breakpoint.xsOnly"
        @click="$emit('draft')"
        class="primary-button ml-0 ml-sm-2 mt-2 mt-sm-0"
      >{{ $t('previewApplicationPageStrings.labels.toDraftButton') }}</v-btn>
    </template>
  </div>
</template>

<script>
export default {
  name: "CommonHandlingPanel",
  props: {
    createdByCurrentUser: Boolean,
    toDraftAvailable: Boolean,
    isSettingToDraft: Boolean,
    isTypingResponse: Boolean
  }
};
</script>