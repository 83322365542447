const HelpBarApplicationSectionTypes = {
    General: 0,
    Salaries: 1,
    Trips: 2,
    Diet: 3,
    Receipts: 4,
    Summary: 5
};


Object.freeze(HelpBarApplicationSectionTypes);

export default HelpBarApplicationSectionTypes;