<template>
  <div>
    <v-row>
      <v-col class="py-0">
        {{ $t("orgApplicationsStrings.childOrgSearch.buttonGroupLabel") }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-btn-toggle
          v-model="useChildOrgSearch"
          class="flex-column flex-sm-row filter-buttons"
          mandatory
          rounded
          @change="onUseChildOrgSearchChange"
        >
          <v-btn
            :value="false"
            :class="
              !useChildOrgSearch
                ? 'filter-button-active mr-2'
                : 'filter-button-inactive mr-2'
            "
            small
          >
            <v-icon
              tag="span"
              class="white--text"
              v-if="!useChildOrgSearch"
              left
              >mdi-check</v-icon
            >
            {{ currentOrgName }}
          </v-btn>
          <v-btn
            :value="true"
            :class="
              useChildOrgSearch
                ? 'filter-button-active mr-2'
                : 'filter-button-inactive mr-2'
            "
            small
          >
            <v-icon tag="span" class="white--text" v-if="useChildOrgSearch" left
              >mdi-check</v-icon
            >
            {{ $t("orgApplicationsStrings.childOrgSearch.childOrgButtonText") }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-autocomplete
          :value="value"
          v-model="value"
          v-if="!value"
          :items="items"
          :loading="loading"
          :search-input.sync="search"
          :filter="filterOrgSearchResults"
          :hide-no-data="loading || !isSearchStringValid(search)"
          :disabled="!useChildOrgSearch"
          hide-selected
          item-text="name"
          item-value="id"
          :label="$t('orgApplicationsStrings.childOrgSearch.autocompleteLabel')"
          :hint="
            $t('orgApplicationsStrings.childOrgSearch.autocompletePlaceholder')
          "
          :no-data-text="$t('orgApplicationsStrings.childOrgSearch.noDataText')"
          return-object
          outlined
          dense
        >
          <template v-slot:selection></template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title
                v-text="orgDisplayName(item)"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="orgTypeDisplayName(item)"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <div v-if="value">
          <span class="font-weight-bold">
            {{ $t("orgApplicationsStrings.childOrgSearch.selectedOrgText") }}
            {{ orgDisplayName(value) }}
          </span>
          <v-btn
            small
            rounded
            class="accent-inverted-button ml-1"
            @click="clearValue"
            >Endre</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { api } from "@/utils/api";
import { mapGetters } from "vuex";
import { handleError } from "@/utils/errorHandler";
import {
  getOrgDisplayName,
  getOrgTypeDisplayName,
  getOrgNameWithOrgTypeCode
} from "@/utils/org";

export default {
  name: "ChildOrgSearch",
  data() {
    return {
      value: null,
      loading: false,
      results: [],
      search: null,
      useChildOrgSearch: false
    };
  },
  props: {
    currentOrgName: String,
    initialOrgId: Number
  },
  computed: {
    items() {
      return [...this.results];
    },
    ...mapGetters({
      currentOrgId: "contextStore/orgId"
    })
  },
  watch: {
    initialOrgId(val) {
      if (!val) {
        this.useChildOrgSearch = false;
        this.clearSearch();
        this.clearValue();
      }
    },
    async search(query) {
      // Items have already been requested
      if (this.loading) {
        return;
      }

      this.results = [];

      if (!this.isSearchStringValid(query)) {
        return;
      }

      this.loading = true;

      await this.getOrgSearchResults(query);

      this.loading = false;
    },
    value(val) {
      this.clearSearch();

      if (val) {
        this.$emit("org-changed", val.id);
      }

      if (!val && !this.useChildOrgSearch) {
        this.$emit("org-changed", this.currentOrgId);
      }
    }
  },
  methods: {
    onUseChildOrgSearchChange() {
      if (!this.useChildOrgSearch) {
        this.value = null;
      }
    },
    clearSearch() {
      this.search = "";
      this.results = [];
    },
    filterOrgSearchResults(_, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    async getOrgSearchResults(query) {
      try {
        const response = await api.v1.orgs.get({
          params: {
            name: query,
            activatedOnly: false,
            federationId: this.currentOrgId
          }
        });

        if (response.status === 200) {
          this.results = response.data;
        }
      } catch (error) {
        await handleError(error);
      }
    },
    async getInitialOrg(orgId) {
      try {
        const response = await api.v1.orgs.getById(orgId);

        if (response.status === 200) {
          this.results = [response.data];
        }
      } catch (error) {
        await handleError(error);
      }
    },
    isSearchStringValid(query) {
      return query && query.trim().length >= 3;
    },
    orgDisplayName(org) {
      return getOrgDisplayName(org);
    },
    orgTypeDisplayName(org) {
      return getOrgTypeDisplayName(org);
    },
    orgNameWithOrgTypeCode(org) {
      return getOrgNameWithOrgTypeCode(org);
    },
    clearValue() {
      this.value = null;
    }
  },
  async mounted() {
    if (this.initialOrgId && this.currentOrgId !== this.initialOrgId) {
      this.loading = true;
      this.useChildOrgSearch = true;
      await this.getInitialOrg(this.initialOrgId);
      if(this.results){
        this.value = this.results[0];
      }
      this.loading = false;
    }
  }
};
</script>