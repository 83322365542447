import { mapGetters } from "vuex";
import DietDeductionTypes from "@/enums/DietDeductionTypes";
const deductionRates = {
    computed: {
        ...mapGetters({
            taxRates: "currentTaxRatePeriodStore/currentPeriod"
        }),
        //This is NOT returning an enum value, it is returning the tax percentage, which is later added up for a total deduction with _.sum,
        //returning zero here allows us to just sum all returned values without having to check for undefined since they are all numbers.
        deductionRates() {
            if (this.taxRates) {
                return {
                    [DietDeductionTypes.Breakfast]: this.taxRates.breakfastDeductionRate,
                    [DietDeductionTypes.Lunch]: this.taxRates.lunchDeductionRate,
                    [DietDeductionTypes.Dinner]: this.taxRates.dinnerDeductionRate
                };
            }

            return {
                [DietDeductionTypes.Breakfast]: 0,
                [DietDeductionTypes.Lunch]: 0,
                [DietDeductionTypes.Dinner]: 0
            };

        }
    }
};

export default deductionRates;