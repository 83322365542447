<template>
  <v-menu offset-y transition="slide-y-transition">
  <template v-slot:activator="{ value, on, attrs }">
    <v-btn v-bind="attrs" v-on="on" text rounded outlined class="font-weight-regular menu-button text-none pl-1 pr-1" :min-width="mobile ? 35 : 110">
      <span v-if="!mobile" class="menu-button pl-3 pr-2">{{ $t(value ? "menuBlock.close" : "menuBlock.menu") }}</span>
      <v-icon :class="mobile ? 'menu-button' : 'menu-button pr-2'">{{ value ? 'mdi-close' : 'mdi-menu' }}</v-icon>
    </v-btn>
  </template>
  <template v-slot:default="data">
    <v-dialog
      v-if="mobile" width="100%" class="menu-dialog" content-class="primary below-menu"
      fullscreen :value="data.value" persistent>
        <div class="primary pa-10" @click="data.value=false">
          <slot></slot>
        </div>
    </v-dialog>
    <div v-else class="primary pa-10" width="300px">
      <slot></slot>
    </div>
  </template>
</v-menu>
</template>

<style scoped lang="scss">
  .menu-button, .menu-button.v-icon, .menu-button.v-btn.v-btn--outlined.v-btn--text {
    color: white;
    border-color: white;
  }

  .menu-button.v-btn:not(.v-btn--round).v-size--default {
    height: 36px;
    min-width: 64px;
    padding: 0 16px;
  }

  .menu.button.v-btn.v-size--default {
    font-size: 1rem;
  }
</style>

<style lang="scss">
  //cannot be scoped as it's not part of main component
  //would be good to make value dynamic, but apparently not possible while using v-dialog
  .v-dialog.below-menu.v-dialog--fullscreen {
    top: 80px;
  }
</style>

<script>
export default {
  name: "MenuBlock",
  props: {
    lang: String
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    }
  }
};
</script>