<template>
  <div>
      <div class="py-2 font-weight-bold">
        {{ $t("previewApplicationPageStrings.approvalPanel.nextActionApproval") }}
      </div>
      <div class="py-2">
        <span v-if="userCanApprove">
          {{ $t("previewApplicationPageStrings.approvalPanel.youCanApprove", [handledOrgName]) }}
        </span>
        <span v-else>
          {{ $t("previewApplicationPageStrings.approvalPanel.youCannotApprove", [handledOrgName])}}
          <permissions-modal
            :link-text="$t('previewApplicationPageStrings.linkToRequiredPermissions')"
            :title="$t('previewApplicationPageStrings.approvalPanel.permissionsModalTitle')"
            :function-type-names="permittedFunctionTypeNames">
          </permissions-modal>
        </span>
      </div>
      <template v-if="userCanApprove">
        <div class="py-2">
          <v-btn
            rounded
            class="primary-inverted-button"
            @click="rejectClicked"
            :disabled="isApproving || isRejecting || isTypingResponse"
            :loading="isRejecting"
            :block="$vuetify.breakpoint.xsOnly"
          >{{ $t("previewApplicationPageStrings.approvalPanel.rejectButton") }}</v-btn>
          <v-btn
            rounded
            class="primary-button ml-0 ml-sm-2 mt-2 mt-sm-0"
            @click="approveClicked"
            :disabled="isApproving || isRejecting || isTypingResponse"
            :loading="isApproving"
            :block="$vuetify.breakpoint.xsOnly"
          >{{ $t("previewApplicationPageStrings.approvalPanel.approveButton") }}</v-btn>
        </div>
        <confirm-reject-application-dialog
          :visible="isRejectDialogVisible"
          @cancel="rejectDialogCancelled"
          @confirm="rejectDialogConfirmed"
        ></confirm-reject-application-dialog>
      </template>
    </div>
</template>

<script>
import { urls } from "@/utils/api";
import { mapGetters } from "vuex";
import { approvalPermittedFunctionTypes } from "@/utils/permissions";
import PermissionsModal from "./PermissionsModal";
import ConfirmRejectApplicationDialog from "./ConfirmRejectApplicationDialog.vue";

export default {
  name: "ApprovalPanel",
  components: {
    PermissionsModal,
    ConfirmRejectApplicationDialog
  },
    data() {
      return {
        isRejectDialogVisible: false
      };
    },
  props: {
    handledOrgId: Number,
    handledOrgName: String,
    userCanApprove: Boolean,
    isTypingResponse: Boolean
  },
  computed: {
    ...mapGetters({
      urlState: "utilsRestStore/urlState"
    }),
    isApproving() {
      if (this.$route.params.id) {
        return this.urlState(urls.v1.applicationOrgs.approve(this.$route.params.id, this.handledOrgId));
      }
      return false;
    },
    isRejecting() {
      if (this.$route.params.id) {
        return this.urlState(urls.v1.applicationOrgs.reject(this.$route.params.id, this.handledOrgId));
      }
      return false;
    },
    permittedFunctionTypeNames() {
      return approvalPermittedFunctionTypes().map(x => x.name);
    }
  },
  methods: {
    rejectClicked() {
      this.isRejectDialogVisible = true;
    },
    approveClicked() {
      this.$emit('application-approved');
    },
    rejectDialogCancelled() {
      this.isRejectDialogVisible = false;
    },
    rejectDialogConfirmed() {
      this.$emit('application-rejected');
      this.isRejectDialogVisible = false;
    }
  }
};
</script>