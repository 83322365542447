<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{$t('summaryPanelStrings.title')}}</h2>
          <v-icon
            class="ml-3"
            tag="span"
            @click.stop="onHelpClick"
          >help_outline</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-treeview :items="items" hoverable open-on-click :open.sync="expandedItems">
                <template #label="{item}">
                  <strong>{{item.name}}</strong>
                  <small class="ml-2">{{item.details}}</small>
                </template>
                <template #append="{item, open}">
                  <template v-if="!open">{{item.amount | toMoney}}</template>
                </template>
                <template #prepend="{item}">
                  <template v-if="item.icon">
                    <v-icon tag="span">{{item.icon}}</v-icon>
                  </template>
                </template>
              </v-treeview>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-treeview :items="totalAmountItem">
                <template #label="{item}">
                  <p class="title ma-0">{{item.name}}</p>
                </template>
                <template #append="{item}">
                  <strong>{{item.amount | toMoney}}</strong>
                </template>
              </v-treeview>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import claimPanel from "@/mixins/claimPanel";
import HelpBarContentTypes from "@/enums/HelpBarContentTypes";
import HelpBarApplicationSectionTypes from "@/enums/HelpBarApplicationSectionTypes";
import { getSalaryClaimsForOrg } from "@/utils/claims/salary";
import { getTripClaimsForOrg } from "@/utils/claims/trips";
import { getDietClaimsForOrg } from "@/utils/claims/diet";
import { getReceiptClaimsForOrg } from "@/utils/claims/receipt";
import { getOrgDisplayName } from "@/utils/org";

export default {
  name: "SummaryPanel",
  mixins: [claimPanel],
  data() {
    return {
      expandedItems: []
    };
  },
  computed: {
    ...mapGetters({
      payingOrgs: "application/payingOrgsStore/payingOrgs",
      currentOrgId: "contextStore/orgId"
    }),
    items() {
      return this.payingOrgs.map(function(org) {
        const orgSummary = {
          id: org.id,
          name: getOrgDisplayName(org),
          children: [],
          amount: null
        };

        // salaries
        const salaryIdPrefix = `${org.id}_1`;
        const salariesForOrg = getSalaryClaimsForOrg(
          org.id,
          this.claims,
          salaryIdPrefix
        );
        if (salariesForOrg?.length) {
          orgSummary.children.push({
            icon: "work",
            id: salaryIdPrefix,
            name: this.$t("summaryPanelStrings.salaryTitle"),
            children: salariesForOrg,
            amount: _.sum(salariesForOrg.map(x => x.amount))
          });
        }

        const tripsIdPrefix = `${org.id}_2`;
        const tripsForOrg = getTripClaimsForOrg(
          org.id,
          this.claims,
          tripsIdPrefix
        );
        if (tripsForOrg?.length) {
          orgSummary.children.push({
            icon: "directions_car",
            id: tripsIdPrefix,
            name: this.$t("summaryPanelStrings.tripsTitle"),
            children: tripsForOrg,
            amount: _.sum(tripsForOrg.map(x => x.amount))
          });
        }

        const dietaryStaysIdPrefix = `${org.id}_3`;
        const dietaryStaysForOrg = getDietClaimsForOrg(
          org.id,
          this.claims,
          dietaryStaysIdPrefix
        );
        if (dietaryStaysForOrg?.length) {
          orgSummary.children.push({
            icon: "room_service",
            id: dietaryStaysIdPrefix,
            name: this.$t("summaryPanelStrings.dietTitle"),
            children: dietaryStaysForOrg,
            amount: _.sum(dietaryStaysForOrg.map(x => x.amount))
          });
        }

        const receiptIdPrefix = `${org.id}_4`;
        const receiptsForOrg = getReceiptClaimsForOrg(
          org.id,
          this.claims,
          receiptIdPrefix
        );
        if (receiptsForOrg?.length) {
          orgSummary.children.push({
            icon: "receipt",
            id: receiptIdPrefix,
            name: this.$t("summaryPanelStrings.receiptsTitle"),
            children: receiptsForOrg,
            amount: _.sum(receiptsForOrg.map(x => x.amount))
          });
        }

        orgSummary.amount = _.sum(orgSummary.children.map(x => x.amount));

        return orgSummary;
      }, this).filter(x => x.children?.length);
    },
    totalAmountItem() {
      const amount = _.sum(
        Object.values(this.claims)
          .flat()
          .filter(x => x?.payingOrgs?.length && x.amount > 0 && !x.isDeleted)
          .map(x => parseFloat(x.amount))
      );

      return [
        {
          name: this.$t("summaryPanelStrings.totalAmount"),
          amount: amount
        }
      ];
    }
  },
  methods: {
    onHelpClick() {
      this.showHelpBar(HelpBarContentTypes.Application, [HelpBarApplicationSectionTypes.Summary]);
    }
  },
  watch: {
    items() {
      this.expandedItems = [this.currentOrgId, `${this.currentOrgId}_1`, `${this.currentOrgId}_2`, `${this.currentOrgId}_3`, `${this.currentOrgId}_4`];
    }
  },
  props: {
    claims: {
      type: Object,
      required: true
    }
  }
};
</script>