<template>
  <div v-if="diet">
    <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{ $t("dietPanelStrings.title") }}</h2></v-card-title>
    <v-card-text>
      <v-row>
        <v-col :cols="12" :md="6">
          <v-simple-table>
            <tbody>
              <tr>
                <td>{{ $t("dietPanelStrings.labels.fromDate")}}</td>
                <td>{{ diet.fromDate | moment(`${$t("commonStrings.dateTimeDisplayFormat")}`) }}</td>
              </tr>
              <tr>
                <td>{{ $t("dietPanelStrings.labels.toDate")}}</td>
                <td>{{ diet.toDate | moment(`${$t("commonStrings.dateTimeDisplayFormat")}`) }}</td>
              </tr>
              <tr>
                <td>{{ $t("dietPanelStrings.labels.distance")}}</td>
                <td>{{ diet.distance }} {{$t("dietPanelStrings.kilometers")}}</td>
              </tr>
              <tr>
                <td>{{ $t("dietPanelStrings.labels.comment")}}</td>
                <td>{{ diet.comment || $t('previewApplicationPageStrings.emptyValue')}}</td>
              </tr>
              <tr>
                <td>{{ $t("dietPanelStrings.labels.payingOrgs")}}</td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <td v-on="on">
                      <paying-orgs-label :paying-orgs="diet.payingOrgs"></paying-orgs-label>
                    </td>
                  </template>
                  <ul>
                    <li v-for="(name, index) in getNames(diet.payingOrgs)" :key="index">{{ name }}</li>
                  </ul>
                </v-tooltip>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>

        <v-col :cols="12" :md="6">
          <v-simple-table>
            <thead>
              <tr>
                <th>{{$t("dietPanelStrings.stayTableHeaders.date")}}</th>
                <th>{{$t("dietPanelStrings.stayTableHeaders.stayType")}}</th>
                <th>{{$t("dietPanelStrings.stayTableHeaders.meals")}}</th>
                <th>{{$t("dietPanelStrings.stayTableHeaders.description")}}</th>
                <th class="text-right">{{$t("dietPanelStrings.stayTableHeaders.amount")}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(stay, index) in diet.stays" :key="index">
                <td>{{ stay.date | moment(`${$t("commonStrings.dateDisplayFormat")}`) }}</td>
                <td>{{getDietTypeName(stay)}}</td>
                <td>{{stay.dietDeductionIds.map(x => $t(`enums.dietDeductionTypes.${x}`)).join(', ')}}</td>
                <td>{{stay.comment || $t('previewApplicationPageStrings.emptyValue')}}</td>
                <td class="text-right">{{ stay.amount | toMoney }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="float-right">
            {{ $t("dietPanelStrings.labels.total") }}:
            <amount-label :value="diet.amount"></amount-label>
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import AmountLabel from "@/views/common/components/AmountLabel";
import readonlyPanel from "@/mixins/readonlyPanel";
import { getDietRateName } from "@/utils/claims/diet";
import PayingOrgsLabel from "@/views/applications/components/PayingOrgsLabel";

export default {
  name: "DietPanel",
  components: {
    AmountLabel,
    PayingOrgsLabel
  },
  mixins: [readonlyPanel],
  props: {
    diet: {
      type: Object,
      validator: prop => typeof prop === "object" || prop === null
    }
  },
  methods: {
    getDietTypeName(stay) {
      return getDietRateName(stay);
    }
  }
};
</script>