<template>
  <v-data-table
    dense
    hide-default-footer
    :headers="headers"
    :items="stays"
    :items-per-page="-1"
  >
    <template v-slot:item="{item, isMobile}">
      <diet-stay-row
        :stay="item"
        :stayDurationTypes="stayDurationTypes"
        :payingOrgs="payingOrgs"
        :salary-fraction="dietUsesSalaryFraction"
        :isMobile="isMobile"
      ></diet-stay-row>
    </template>
  </v-data-table>
</template>

<script>
import {
  getDaysInPeriod,
  dietRestrictionConstants,
  getStayDurationTypes
} from "@/utils/claims/diet";
import dietStayRow from "./DietStayRow";
import _ from "lodash";
import { copy } from "@/utils/object";

export default {
  name: "DietStays",
  components: {
    dietStayRow: dietStayRow
  },
  data: function() {
    return {
      headers: [
        {
          text: this.$t("dietPanelStrings.stayTableHeaders.date"),
          value: "date",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("dietPanelStrings.stayTableHeaders.stayType"),
          value: "stayType",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("dietPanelStrings.stayTableHeaders.nightAddition"),
          value: "hasNightAddition",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("dietPanelStrings.stayTableHeaders.amount"),
          value: "amount",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("dietPanelStrings.stayTableHeaders.meals"),
          value: "dietDeductionIds",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("dietPanelStrings.stayTableHeaders.description"),
          value: "stayDescription",
          sortable: false,
          filterable: false
        }
      ]
    };
  },
  props: {
    fromDate: {
      required: true,
      type: String
    },
    toDate: {
      required: true,
      type: String
    },
    payingOrgs: {
      required: true,
      type: Array
    },
    stays: {
      required: true,
      type: Array
    },
    distance: {
      required: true,
      type: Number
    },
    dietUsesSalaryFraction: {
      type: Boolean
    }
  },
  watch: {
    fromDate() {
      this.updateDurationDays(this.durationDays);
    },
    toDate() {
      this.updateDurationDays(this.durationDays);
    },
    payingOrgs() {
      this.updateDurationDays(this.durationDays);
    },
    distance() {
      this.updateDurationDays(this.durationDays);
    }
  },
  computed: {
    durationDays() {
      if (
        this.distance < dietRestrictionConstants.minimalSufficientDistance ||
        !this.payingOrgs.length
      ) {
        return [];
      }

      return getDaysInPeriod(this.fromDate, this.toDate);
    },

    //Is used as a lookup in DietStayRow, it returns a dictionary of {dayOfyear: DietDurationType}.
    stayDurationTypes() {
      return getStayDurationTypes(this.fromDate, this.toDate);
    }
  },
  methods: {
    //We want to calculate the days you can claim based on a from and to date,
    //but we don't want to do it in a computed so we can maintain our stays collection on the diet.
    //Say you start with 23 - 25, fill out some data, then change it to 22 - 24,
    //This should then add 22, and remove 24.

    updateDurationDays(days) {
      let stays = copy(this.stays);
      const newDays = days.map(x => this.$moment(x));
      const existingDates = stays.map(x => this.$moment(x.date));

      //Gives us the diff of new vs existing days, if there is any we push them to stays with default values
      const daysToAdd = _.differenceWith(
        newDays,
        existingDates,
        (x, y) => x.dayOfYear() === y.dayOfYear()
      );

      if (daysToAdd?.length) {
        for (const day of daysToAdd) {
          stays.push({
            date: day.format(),
            dietStayTypeId: null,
            dietLengthTypeId: null,
            dietDeductionIds: [],
            hasNightAddition: null
          });
        }
      }

      //Basically does the reverse of the diff above, showing us if there was any days in the existing list that are no longer there.
      //We then pull from the array based on the key
      if (existingDates?.length) {
        const daysToRemove = _.differenceWith(
          existingDates,
          newDays,
          (x, y) => x.dayOfYear() === y.dayOfYear()
        );

        if (daysToRemove?.length) {
          stays = _.pullAllWith(
            stays,
            daysToRemove,
            (stay, day) =>
              this.$moment(stay.date).dayOfYear() === day.dayOfYear()
          );
        }
      }

      stays = _.sortBy(stays, s => s.date);
      this.stays.splice(0, this.stays.length);
      this.stays.push(...stays);
    }
  },
  async beforeMount() {
    this.updateDurationDays(this.durationDays);
  },
  beforeDestroy() {
    this.stays.splice(0, this.stays.length);
  }
};
</script>

