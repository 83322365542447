const summaryPanelStrings = {
  title: "Oppsummering",
  totalAmount: "Totalt",
  salaryTitle: "@:salaryPanelStrings.title",
  receiptsTitle: "@:receiptsPanelStrings.title",
  tripsTitle: "@:drivingPanelStrings.title",
  dietTitle: "@:dietPanelStrings.title",
  of: "av",
  total: "totalt",
  payingOrgs: "mottakere",
  salaryFractionExplanation: "{amount} × {parts}/{total} del av honorarbrøk | {amount} × {parts}/{total} deler av honorarbrøk"
};

export default summaryPanelStrings;