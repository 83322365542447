import { mapActions } from "vuex";

const integrationSettings = {
    data() {
        return {
            integrationSettings: {}
        };
    },
    methods: {
        ...mapActions({
            getOrgConfiguration: 'settings/orgConfigurationStore/getOrgConfiguration'
        }),
        async setIntegrationSettings(orgId, functionalityId) {
            if (!orgId || !functionalityId) {
                return;
            }

            const orgConfiguration = await this.getOrgConfiguration(orgId);

            const orgIntegrationSettings = orgConfiguration?.integrationSettings?.find(x => x.applicationId === functionalityId);

            if (orgIntegrationSettings) {
                this.integrationSettings = orgIntegrationSettings;
            }
        }
    }
};

export default integrationSettings;