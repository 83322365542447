<template>
  <div class="text-left red-underline-animation pb-4">
    <router-link :to="to" class="inverted--text opaque-underline">
      <slot></slot>&rarr;
    </router-link>
  </div>
</template>

<script>
export default {
  name: "MenuRouterLink",
  props: {
    to: {
      type: [String, Object],
      required: true
    }
  }
};
</script>