const startPageStrings = {
  pageTitle: "Velkommen til Idrettsoppgjør",
  pageHeader: "Velkommen til Idrettsoppgjør!",
  orgSelectionInfo: {
    common: "Når du skal behandle krav for en organisasjon er det viktig at du har valgt riktig organisasjonsledd. ",
    withIcon1: "Du kan se hvilket organisasjonsledd som er aktivt ved å velge ikonet ",
    withIcon2: ", her kan du også bytte om nødvendig.",
    withoutIcon: "Du kan se hvilket organisasjonsledd som er aktivt øverst, ved siden av navnet ditt. Her kan du også bytte om nødvendig."
  },
  menuInfo: {
    shared: "Du finner alle funksjoner du har tilgang til i menyen øverst til høyre",
    common: "Her er noen av de viktigste:",
    withoutIcon: "@:(startPageStrings.menuInfo.shared).",
    withIcon1: "@:(startPageStrings.menuInfo.shared), ved å velge ikonet ",
    withIcon2: "."
  },
  pageGuide: {
    panelHeader: "Slik finner du fram:",
    applicationsPage: "Her kan du opprette og sende inn et nytt krav. Her finner du også oversikt over alle krav du har opprettet.",
    personalSettingsPage: "Innstillinger for å tilpasse hvilke hendelser du vil varsles om på e-post finner du nå i Min idrett.",
    orgApplicationsPage: "Her kan du se krav som er mottatt til behandling av ditt organisasjonsledd, og godkjenne og utbetale disse kravene. Bilag kan også hentes herfra.",
    orgApplicationsPageApproverOnly: "Her kan du se krav som er mottatt til behandling av ditt organisasjonsledd, og godkjenne disse kravene. Bilag kan også hentes herfra.",
    backofficePage: `Her kan du aktivere Idrettsoppgjør for ditt organisasjonsledd, tilpasse hvilke typer krav som skal kunne sendes inn,
     og hvilke refusjonssatser som skal benyttes.`,
    backofficePageFederationInfo: "For krav som er sendt inn fra Turneringsadmin administreres innstillingene av særforbundet."
  },
  personalSettingsLink: "Gå til varsler i Min idrett"
};

export default startPageStrings;