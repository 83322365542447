<template>
    <div v-if="filteredApplicationOrgs">
        <template v-if="!areMultiple">
            {{ label }}
        </template>
        <template v-else>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <p v-on="on">{{$t("paymentMethodLabelStrings.multiplePaymentMethods")}}</p>
                </template>
                <ul v-for="(filtered, idx) in filteredApplicationOrgs" :key="idx">
                    <li> {{ getOrgName(filtered.orgId)}} - {{toLabel(filtered.applicationOrg.paymentMethod)}} </li>
                </ul>
            </v-tooltip>
        </template>
    </div>
</template>

<script>
import { getOrgDisplayName } from "@/utils/org";
import _ from "lodash";

export default {
    name: "PaymentMethodLabel",
    props: {
        applicationOrgs: Object,
        orgIds: Array,
        orgs: Array
    },
    computed: {
        areMultiple() {
            return this.filteredApplicationOrgs?.length > 1;
        },
        filteredApplicationOrgs() {
            const applicationOrgIds = Object.keys(this.applicationOrgs).map(x => parseInt(x, 10));
            const filteredOrgIds = _.intersection(this.orgIds, applicationOrgIds);

            return filteredOrgIds.map(function (orgId) {
                return {
                    orgId,
                    applicationOrg: this.applicationOrgs[orgId]
                };
            }, this);
        },
        label() {
            if (this.filteredApplicationOrgs.length > 0 && !this.areMultiple) {
                const {applicationOrg} = this.filteredApplicationOrgs[0];

                const paymentMethod = applicationOrg.paymentMethod;

                return this.toLabel(paymentMethod);
            }

            return "";
        }
    },
    methods: {
        toLabel(value) {
            if (this.$te(`enums.paymentMethods.${value}`)) {
                return this.$t(`enums.paymentMethods.${value}`);
            }

            return this.$t(`commonStrings.notDefined`);
        },
        getOrgName(orgId) {
            const org = this.orgs.find(x => x.id === orgId);

            return getOrgDisplayName(org);
        }
    }
};
</script>