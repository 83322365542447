<template>
  <div>
    <v-row v-if="label">
      <v-col class="py-0">
        {{label}}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
      <v-btn-toggle
        rounded
        v-model="value"
        dense
        multiple
        :mandatory="mandatory"
        @change="update"
        :class="[cssClass, {'full-width': $vuetify.breakpoint.xsOnly}, 'filter-buttons']"
        style="background-color:inherit"
      >
        <v-btn
          v-for="item in myOptions"
          :key="item.value"
          :value="item.value"
          :block="$vuetify.breakpoint.xsOnly"
          small
          class="mr-2 mb-2"
        >
          <v-icon
            tag="span"
            class="inverted--text"
            v-if="isCheckedItem(item.value)"
            left
            >mdi-check</v-icon>
          {{ item.label }}
        </v-btn>
      </v-btn-toggle>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
  .full-width {
    width: 100%;
  }
</style>

<script>
export default {
  name: "ButtonFilter",
  data() {
    return {
      value: this.initial,
      myOptions: this.options
    };
  },
  props: {
    options: Array,
    initial: Array,
    label: String,
    mandatory: Boolean,
    cssClass: String,
    clearing: Boolean
  },
  methods: {
    update: function (e) {
      this.$emit("input", e);
    },
    clear: function () {
      this.value = this.initial;
      this.$emit("input", this.value);
    },
    isCheckedItem(itemValue) {
      if(this.value) {
        return this.value.includes(itemValue);
      }

      return false;
    }
  },
  watch: {
    options(val) {
      this.myOptions = val;
    },
    clearing(val) {
      if (!val) {
        return;
      }

      this.clear();
      this.$emit("update:clearing", false);
    }
  },
  computed: {
    buttonClass() {
      if (this.cssClass?.includes("flex-md-row")) {
        return "mt-2 mt-md-0";
      }

      if (this.cssClass?.includes("flex-sm-row")) {
        return "mt-2 mt-sm-0";
      }

      return "";
    }
  }
};
</script>