const dietPanelStrings = {
  title: "Diett",
  notAvailableForCompletePeriod: "{0} (ikke tilgjengelig i hele perioden)",
  moreInformationRequired: "Flere detaljer vil vises når tidsrom, mottakere og avstand fra hjemmet er korrekt utfylt",
  labels: {
    addButton: "@:commonStrings.add @.lower:dietPanelStrings.title",
    removeButton: "@:commonStrings.remove",
    fromDate: "Avreise",
    toDate: "Hjemkomst",
    time: "kl",
    distance: "Antall km fra hjemmet",
    comment: "@:commonStrings.comment",
    payingOrgs: "@:commonStrings.recipients",
    total: "Totalt i diett"
  },
  stayTableHeaders: {
    date: "@:commonStrings.date",
    stayType: "Overnatting",
    amount: "@:commonStrings.amount",
    meals: "Måltidsfradrag",
    description: "Beskrivelse",
    nightAddition: "Nattillegg"
  },
  placeholders: {
    payingOrgs: "Velg mottakere",
    description: "Navn på sted og adresse"
  },
  insufficientDistance: "Diett kan ikke utbetales pga utilstrekkelig avstand (Minste avstand {0} kilometer)",
  insufficientDuration: "Diett kan ikke utbetales pga utilstrekkelig varighet (Minste varighet {0} timer)",
  daysDuration: "{n} dag | {n} dager",
  hoursDuration: "{n} time | {n} timer",
  minutesDuration: "{n} minutt | {n} minutter",
  durationIs: "Varigheten er",
  diet: "@:dietPanelStrings.title",
  kilometers: "km",
  splitBySalaryFraction: "@:payingOrgsPickerStrings.splitBySalaryFraction",
  salaryFractionExplanation: "@:payingOrgsPickerStrings.salaryFractionExplanation"
};

export default dietPanelStrings;