<template>
  <v-skeleton-loader
    :loading="initializing"
    type="table-heading,table-cell@2, divider"
  >
    <v-card class="text-left fill-height">
      <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{$t('previewApplicationPageStrings.general.title')}}</h2></v-card-title>
      <v-card-text>
        <v-row class="pb-2">
          <v-col
            cols="12"
            xs="12"
            sm="4"
            md="2"
            lg="3"
            xl="2"
            class="py-0 font-weight-bold"
            >{{
              $t("previewApplicationPageStrings.general.applicationNameLabel")
            }}</v-col
          >
          <v-col cols="12" xs="12" sm="8" md="10" lg="9" xl="10" class="py-0">{{
            application.applicationName
          }}</v-col>
        </v-row>
        <v-row class="pb-2">
          <v-col
            cols="12"
            xs="12"
            sm="4"
            md="2"
            lg="3"
            xl="2"
            class="py-0 font-weight-bold"
            >{{
              $t("previewApplicationPageStrings.general.createdDateLabel")
            }}</v-col
          >
          <v-col cols="12" xs="12" sm="8" md="10" lg="9" xl="10" class="py-0">{{
            (application.createdDate || $moment()) | moment($t("commonStrings.dateDisplayFormat"))
          }}</v-col>
        </v-row>
        <v-row class="pb-2">
          <v-col
            cols="12"
            xs="12"
            sm="4"
            md="2"
            lg="3"
            xl="2"
            class="py-0 font-weight-bold"
            >{{
              $t("previewApplicationPageStrings.general.accountNumberLabel")
            }}</v-col
          >
          <v-col cols="12" xs="12" sm="8" md="10" lg="9" xl="10" class="py-0">{{
            application.accountNumber
          }}</v-col>
        </v-row>
        <v-row class="pb-2">
          <v-col
            cols="12"
            xs="12"
            sm="4"
            md="2"
            lg="3"
            xl="2"
            class="py-0 font-weight-bold"
            >{{ $t("previewApplicationPageStrings.general.idLabel") }}</v-col
          >
          <v-col cols="12" xs="12" sm="8" md="10" lg="9" xl="10" class="py-0">{{
            application.id
          }}</v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
export default {
  name: "GeneralDetailsPanel",
  props: {
    initializing: Boolean,
    application: {
      type: Object,
      validator: prop => typeof prop === "object" || prop === null,
      required: true
    }
  }
};
</script>

