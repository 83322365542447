<template>
  <div class="text-field-date-picker">
    <v-menu
      v-model="dateOpen"
      :close-on-content-click="false"
      :nudge-right="20"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{}" v-if="disabled">
        <v-text-field
          v-model="dateDisplay"
          :label="label"
          prepend-inner-icon="event"
          disabled
          outlined
          dense
          :placeholder="$t('datePickerStrings.placeholders.date')"
        ></v-text-field>
      </template>
      <template v-slot:activator="{}" v-else>
        <validation-provider rules="date" :name="label" v-slot="{ errors }">
          <v-text-field
            :error-messages="errors"
            v-mask="'##.##.####'"
            v-model="textDate"
            :label="label"
            prepend-inner-icon="event"
            outlined
            dense
            :placeholder="$t('datePickerStrings.placeholders.date')"
            @click:prepend-inner="dateOpen = true"
            @blur="updateFromText"
          ></v-text-field>
        </validation-provider>
      </template>
      <v-date-picker
        v-model="internalDate"
        @input="update"
        :allowed-dates="allowedDates"
        :disabled="disabled"
        :first-day-of-week="1"
        locale="no-NO"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider
  },
  name: "TextFieldDatePicker",
  data: function () {
    return {
      textDate: "",
      internalDate: "",
      dateOpen: false
    };
  },
  methods: {
    update: function () {
      this.dateOpen = false;
      this.textDate = this.dateDisplay;
      this.$emit("input", this.dateValue);
    },
    updateFromText() {
      if (!this.textDate) {
        this.$emit("input", "");
        return;
      }

      const date = this.$moment(
        this.textDate,
        this.$t("commonStrings.dateDisplayFormat")
      );

      if (!date.isValid()) {
        return;
      }

      const objTime = {
        year: date.year(),
        month: date.month(),
        date: date.date(),
        hour: 0,
        minute: 0
      };

      if (this.value) {
        const initial = this.$moment(
          this.value,
          this.$t("commonStrings.dateValueFormat")
        );

        objTime.hour = initial.hour();
        objTime.minute = initial.minute();
      }

      const newDate = this.$moment(objTime);

      this.internalDate = newDate.format(
        this.$t("commonStrings.dateValueFormat")
      );
      this.textDate = this.dateDisplay;
      this.$emit("input", this.dateValue);
    }
  },
  props: {
    value: String,
    label: String,
    allowedDates: Function,
    disabled: Boolean
  },
  computed: {
    dateDisplay: function () {
      if (!this.internalDate) {
        return null;
      }
      return this.$moment(
        this.internalDate,
        this.$t("commonStrings.dateValueFormat")
      ).format(this.$t("commonStrings.dateDisplayFormat"));
    },
    dateValue: function () {
      const date = this.$moment(
        this.internalDate,
        this.$t("commonStrings.dateValueFormat")
      );
      const objTime = {
        year: date.year(),
        month: date.month(),
        date: date.date()
      };

      if (this.value) {
        const initial = this.$moment(
          this.value,
          this.$t("commonStrings.dateValueFormat")
        );

        objTime.hour = initial.hour();
        objTime.minute = initial.minute();
      }

      const newDate = this.$moment(objTime);

      return newDate.format(this.$t("commonStrings.dateValueFormat"));
    }
  },
  mounted: function () {
    if (this.value) {
      this.internalDate = this.$moment(
        this.value,
        this.$t("commonStrings.dateValueFormat")
      ).format(this.$t("commonStrings.dateValueFormat"));
      this.textDate = this.dateDisplay;
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.internalDate = this.$moment(
          value,
          this.$t("commonStrings.dateValueFormat")
        ).format(this.$t("commonStrings.dateValueFormat"));
        this.textDate = this.dateDisplay;
      } else {
        this.internalDate = null;
        this.textDate = "";
      }
    }
  }
};
</script>