<template>
  <v-row :class="$vuetify.breakpoint.mobile ? 'pt-10' : 'mx-0 mt-0 mb-n10 welcome-background'" align="center" justify="center">
    <v-col>
    <h1 :class="['welcome-title', {'inverted--text': !$vuetify.breakpoint.mobile}]">
      {{ $t("startPageStrings.pageHeader") }}
    </h1>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
  @import "~@nifnpm/vue-cli-plugin-vuetify-preset/preset/mixins.scss";

  @media screen {
    .welcome-background {
      @include gradient-image(var(--v-primary-base), url("~@/assets/relay.jpg"));
      height:180px;
    }
    .welcome-title {
      font-weight: lighter;
      font-size: 2.75rem;
      text-align: center;
    }
  }
</style>

<script>
export default {
  name: "WelcomeBanner"
};
</script>