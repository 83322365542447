const senderInformationStrings = {
  name: "Avsenderinformasjon",
  pageTitle: "@:senderInformationStrings.name",
  resultsSubheader: "Søkeresultater ({0})",
  noDataText: "Ingen data funnet",
  noResultText: "Ingen resultater",
  undefinedPaymentMethod: "Ukjent",
  headers: {
    name: "Navn",
    edit: "Rediger",
    paymentMethod: "Betalingsmetode"
  },
  labels: {
    editButton: "Rediger"
  },
  editDialog: {
    chosenPaymentMethod: "Velg betalingsmetode"
  },
  successfullySaved: "Avsenderinformasjon ble lagret"
};

export default senderInformationStrings;