const InfoMessageTypes = {
    Unknown: 0,
    Info: 1,
    Warning: 2,
    Error: 3,
    Success: 4
};

Object.freeze(InfoMessageTypes);

export default InfoMessageTypes;