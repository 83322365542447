<template>
  <v-text-field
    v-model="displayValue"
    :name="name"
    :error-messages="errorMessages"
    :disabled="disabled"
    :aria-label="ariaLabel"
    :label="label"
    :hint="hint"
    :messages="messages"
    inputmode="decimal"
    outlined
    dense
    @input="update"
    @blur="cleanInputChars"
  >
  <template v-slot:message="data" v-if="messages && messages.length">
    <span class="warning--text">{{ data.message }}</span>
  </template>
  </v-text-field>
</template>
<script>
export default {
    name: "DecimalTextField",
  data() {
    return {
      displayValue: ""
    };
  },
  props: {
    value: {
      validator: prop => typeof prop === "number" || typeof prop === "string" || prop === null || prop === undefined,
      required: true
    },
    errorMessages: {
      validator: prop => typeof prop === "string" || prop === null || prop === undefined || Array.isArray(prop)
    },
    disabled: Boolean,
    ariaLabel: String,
    name: String,
    label: String,
    hint: String,
    messages: {
      validator: prop => typeof prop === "string" || prop === null || prop === undefined || Array.isArray(prop)
    },
    padDecimals: Number
  },
  computed: {
    dataValue() {
      return this.displayValue.replace(',', '.');
    }
  },
  methods: {
    update: function() {
      this.$emit("input", this.dataValue);
    },
    cleanInputChars() {
      const regex = /[^0-9\-.,]/gi;
      this.displayValue = this.displayValue.toString().replace(regex, '');

      this.displayValue = this.formatForDisplay(this.dataValue);
      this.$emit("input", this.dataValue);
    },
    getExistingDecimals(numberValue) {
      if (numberValue.toString().indexOf(".") > -1) {
        return numberValue.toString().split(".")[1].length || 0;
      }
      return 0;
    },
    getPaddedValue(numberValue, existingDecimals) {
      if (this.padDecimals && this.padDecimals > existingDecimals) {
         return numberValue.toFixed(this.padDecimals);
      }

      return numberValue.toString();
    },
    formatForDisplay(value) {
      const numberValue = parseFloat(value);

      if (numberValue) {
        const existingDecimals = this.getExistingDecimals(numberValue);
        const paddedValue = this.getPaddedValue(numberValue, existingDecimals);
        return paddedValue.replace('.', ',');
      }

      return value;
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.displayValue = value.toString().replace('.', ',');
        return;
      }

      if (this.displayValue) {
        this.displayValue = "";
      }
    }
  },
  mounted(){
    if(this.value){
      this.displayValue = this.formatForDisplay(this.value);
    }
  }
};
</script>