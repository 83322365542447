import { compare } from "fast-json-patch";
import { mapMutations, mapActions, mapGetters } from "vuex";
import { api, urls } from "@/utils/api";
import { copy } from "@/utils/object";
import integrationSettings from "@/mixins/integrationSettings";
import store from "@/store";
import PaymentMethods from "@/enums/PaymentMethods";
import { handleError } from "@/utils/errorHandler";

const application = {
    data() {
        return {
            initializing: true,
            originalState: {},
            isTypingResponse: false,
            application: {
                id: null,
                applicationName: "",
                personId: 0,
                accountNumber: null,
                claims: {
                    receipts: [],
                    trips: [],
                    salaries: [],
                    diet: null
                },
                orgs: {},
                isDraft: null,
                comments: [],
                logEvents: [],
                federationId: null,
                functionalityId: null,
                rateConfigurations: null
            }
        };
    },
    mixins: [integrationSettings],
    computed: {
        ...mapGetters({
            currentApplication: "application/currentApplicationStore/application",
            urlState: "utilsRestStore/urlState"
        }),
        canUseSalaryFraction() {
            return this.integrationSettings['canUseSalaryFraction'];
        },
        importSalaryClaimsOnly() {
            return this.integrationSettings['importSalaryClaimsOnly'];
        },
        immutableSalaryClaimsCollection() {
            return this.integrationSettings['immutableSalaryClaimsCollection'];
        },
        editableSalaryClaimPayingOrgs() {
            return this.integrationSettings['editableSalaryClaimPayingOrgs'];
        },
        isSettingToDraft() {
            if (this.application.id) {
                return this.urlState(urls.v1.applications.setToDraft(this.application.id));

            }
            return false;
        }
    },
    methods: {
        setIsTypingResponse(val) {
            this.isTypingResponse = !!val;
        },
        ...mapActions({
            fetchRateConfigurationsForApplication:
                "application/rateConfigurationsStore/fetchByApplicationId"
        }),
        ...mapMutations({
            setPayingOrgs: "application/payingOrgsStore/setPayingOrgs",
            clearRateSettings: "application/rateConfigurationsStore/clear",
            setCurrentApplicationIsDraft: "application/currentApplicationStore/setCurrentApplicationIsDraft"
        }),
        async fetchOrgData() {
            try {
                const response = await api.v1.applications.orgs(this.$route.params.id);

                if (response.status === 200 && response.data) {
                    return response.data;
                } else {
                    return [];
                }
            } catch (error) {
                await handleError(error);
                return [];
            }
        },
        //This might have to be changed later if we start reusing the application object imediately, currently we navigate and reload.
        //The issue discoved that prompted this change was that we were setting isDraft directly in sendClicked,
        //which then led to applicationPageComponentName in ApplicationDetailsPage changing the component being used.
        //The validator observer is in the edit component which is removed and takes $refs.observer with it, leading to validation throwing exceptions and diet.stays being removed.
        getApplicationWithDraftState: function (app) {
            const applicationCopy = copy(app);
            if (applicationCopy.rateConfigurations) {
                applicationCopy.isDraft = false;
            }
            return applicationCopy;
        },
        async fetch() {
            try {

                //If 200 OK we fetch config, they are needed before calculations
                await this.fetchRateConfigurationsForApplication(this.$route.params.id);
                //Then we can apply data
                this.originalState = copy(this.currentApplication);
                this.application = copy(this.currentApplication);
                //And set paying orgs
                const payingOrgs = await this.fetchOrgData();

                this.setPayingOrgs(payingOrgs);
                await this.setIntegrationSettings(this.application.federationId, this.application.functionalityId);

            } catch (error) {
                await handleError(error);
            }
        },
        async updateApplication(route) {
            try {
                const applicationToUpdate = this.getApplicationWithDraftState(this.application);
                const patchDoc = compare(
                    this.originalState,
                    applicationToUpdate
                );
                const response = await api.v1.applications.patch(
                    this.application.id,
                    patchDoc
                );

                if (response.status === 204) {
                    this.$snacks.add(this.$t("editApplicationStrings.saveAlerts.saved"), "success", true);
                    this.$router.push(route);
                }
            } catch (error) {
                await handleError(error);
            }
        },
        async applicationAction(action, orgId, method) {
            try {
                const response = await api.v1.applicationOrgs[action](this.$route.params.id, orgId);

                if (response.status === 200 && response.data) {
                    this.handleApplicationActionSuccessResponse(method, action, response);
                }
            } catch (error) {
                await handleError(error);
            }
        },
        handleApplicationActionSuccessResponse(method, action, response) {
            if (method) {
                switch (method) {
                    case PaymentMethods.Manual:
                        this.$snacks.add(this.$t(`editApplicationStrings.saveAlerts.${action}`), "success", true);
                        break;
                    case PaymentMethods.Buypass:
                        this.$snacks.add(this.$t(`editApplicationStrings.saveAlerts.payBuypass`), "success", true);
                        break;
                    default:
                        break;
                }
            }

            if (!response.data.isDraft) {
                this.originalState = copy(response.data);
                this.application = copy(response.data);
            } else {
                this.$router.push("/orgapplications");
            }
        },
        async setPaymentMethodAction(orgId, paymentMethodId, setAsPreferredMethod) {
            try {

                const request = {
                    paymentMethod: paymentMethodId,
                    setAsPreferredMethod: setAsPreferredMethod
                };
                const response = await api.v1.applicationOrgs.setPaymentMethod(this.$route.params.id, orgId, request);

                if (response.status === 200 && response.data) {
                    this.$snacks.add(this.$t("editApplicationStrings.saveAlerts.setPaymentMethod"), "success", true);

                    if (!response.data.isDraft) {
                        this.originalState = copy(response.data);
                        this.application = copy(response.data);
                    } else {
                        this.$router.push("/orgapplications");
                    }
                }
            } catch (error) {
                await handleError(error);
            }
        },
        async resetPaymentMethodAction(orgId) {
            try {
                const response = await api.v1.applicationOrgs.resetPaymentMethod(this.$route.params.id, orgId);

                if (response.status === 200 && response.data) {
                    this.$snacks.add(this.$t("editApplicationStrings.saveAlerts.resetPaymentMethod"), "success", true);

                    this.originalState = copy(response.data);
                    this.application = copy(response.data);
                }
            } catch (error) {
                await handleError(error);
            }
        },
        async setToDraft() {
            try {
                const response = await api.v1.applications.setToDraft(this.application.id);

                if (response.status === 204) {
                    this.$snacks.add(this.$t("editApplicationStrings.saveAlerts.reclaimed"), "success", true);
                    this.setCurrentApplicationIsDraft(true);
                    window.scrollTo(0, 0);
                }
            } catch (error) {
                await handleError(error);
            }
        },
        async appendReply(reply) {
            this.application.comments.push(reply);
            this.originalState.comments.push(reply);
        }
    },
    destroyed() {
        this.clearRateSettings();
    },
    watch: {
        "application": {
            deep: true,
            handler: function (app) {
                store.commit("application/currentApplicationStore/setApplication", app);
            }
        }
    }
};

export default application;