<template>
  <v-container>
    <v-row class="py-0 py-md-16 ml-sm-8 ml-md-0">
      <v-col cols="12" sm="10" md="7" lg="6" xl="4" class="sm-pa-0">
        <h1>{{ $t("publicPageStrings.senderHeader") }}</h1>
        <v-row>
          <v-col class="font-weight-light body-1">
            {{ $t("publicPageStrings.senderBody") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="secondary"
              :href="senderLoginUrl"
              x-large
              rounded
              :block="$vuetify.breakpoint.smAndDown"
              elevation="3"
              class="secondary--border px-10 px-md-6 my-10 my-md-6"
              :class="$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4'">
              {{ $t("publicPageStrings.senderLoginButton") }}
            </v-btn>
          </v-col>
        </v-row>
        <h1 class="mt-8">{{ $t("publicPageStrings.recipientHeader") }}</h1>
        <v-row>
          <v-col class="font-weight-light body-1">
            {{ $t("publicPageStrings.recipientBody") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="secondary"
              to="/start"
              x-large
              rounded
              :block="$vuetify.breakpoint.smAndDown"
              elevation="3"
              class="secondary--border px-10 px-md-6 my-10 my-md-6"
              :class="$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4'">
              {{ $t("publicPageStrings.recipientLoginButton") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider
              v-if="$vuetify.breakpoint.smAndDown"
              width="33%"
              class="secondary mt-8 mb-4 mx-auto"
              style="border-bottom-width: 2px"
            ></v-divider>
            <p :class="$vuetify.breakpoint.smAndDown ? 'text-center caption' : 'font-weight-bold text-h4'">
              {{ $t('publicPageStrings.getStarted') }}
              <span class="red-underline-animation d-none d-md-inline-flex">
                <a :href="registerUrl"
                  class="inverted--text opaque-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ $t('publicPageStrings.getStartedLink') }} &rarr;
                </a>
              </span>
            </p>
            <v-btn v-if="$vuetify.breakpoint.smAndDown"
              outlined dark rounded block x-large
              :href="registerUrl"
              class="px-10 px-md-6 my-10 my-md-6"
              :class="$vuetify.breakpoint.smAndDown ? 'text-h5' : ' text-h4'">
              {{ $t('publicPageStrings.getStartedLink2') }}
            </v-btn>
            <p :class="$vuetify.breakpoint.smAndDown ? 'text-center caption' : 'font-weight-light body-1'">
              {{ $t('publicPageStrings.registrationCaptionText') }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PublicPage",
  computed: {
    registerUrl() {
      const sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl;

      return sanitizeUrl(process.env.VUE_APP_SIGNUP_URL);
    },
    senderLoginUrl() {
      const sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl;

      return sanitizeUrl(process.env.VUE_APP_SENDER_LOGIN_URL);
    }
  }
};
</script>

<style lang="scss" scoped>
  .v-btn {
    &:focus:before {
      opacity: .24;
    }

    &:hover:before {
      opacity: .08;
    }
  }
</style>