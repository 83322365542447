<template>
  <div v-if="claims && claims.length">
    <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{ $t("receiptsPanelStrings.title") }}</h2></v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="claims"
        :items-per-page="-1"
        hide-default-footer
      >
        <template
          v-slot:item.date="{ item }"
        >{{item.date | moment($t("commonStrings.dateDisplayFormat"))}}</template>

        <template
          v-slot:item.claimTypeId="{ item }"
        >{{$t(`enums.receiptTypes.${item.claimTypeId}`)}}</template>

        <template v-slot:item.amount="{ item }">{{ item.amount | toMoney}}</template>

        <template
          v-slot:item.comment="{item}"
        >{{ item.comment || $t('previewApplicationPageStrings.emptyValue')}}</template>

        <template v-slot:item.attachments="{ item }">
          <span class="white-space-nowrap">
            <receipt-attachments-modal
              :attachments="item.attachments"
              :application-id="applicationId">
            </receipt-attachments-modal>
          </span>
        </template>

        <template v-slot:item.payingOrgs="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <paying-orgs-label :paying-orgs="item.payingOrgs"></paying-orgs-label>
              </span>
            </template>
            <ul>
              <li v-for="(name, index) in getNames(item.payingOrgs)" :key="index">{{ name }}</li>
            </ul>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import readonlyPanel from "@/mixins/readonlyPanel";
import ReceiptAttachmentsModal from "./ReceiptAttachmentsModal";
import { mapGetters } from "vuex";
import PayingOrgsLabel from "@/views/applications/components/PayingOrgsLabel";

export default {
  name: "ReceiptsPanel",
  components: {
    ReceiptAttachmentsModal,
    PayingOrgsLabel
  },
  props: {
    claims: {
      type: Array,
      required: true
    },
    applicationId: {
      type: String,
      required: true
    },
    personId: {
      type: Number,
      required: true
    }
  },
  mixins: [readonlyPanel],
  data() {
    return {
      headers: [
        {
          text: this.$t("receiptsPanelStrings.tableHeaders.date"),
          align: "left",
          sortable: false,
          value: "date"
        },

        {
          text: this.$t("receiptsPanelStrings.tableHeaders.expenseType"),
          align: "left",
          sortable: false,
          value: "claimTypeId"
        },

        {
          text: this.$t("receiptsPanelStrings.tableHeaders.payingOrgs"),
          align: "left",
          sortable: false,
          value: "payingOrgs"
        },

        {
          text: this.$t("receiptsPanelStrings.tableHeaders.attachment"),
          align: "left",
          sortable: false,
          value: "attachments"
        },

        {
          text: this.$t("receiptsPanelStrings.tableHeaders.comment"),
          align: "left",
          sortable: false,
          value: "comment"
        },

        {
          text: this.$t("receiptsPanelStrings.tableHeaders.amount"),
          align: "right",
          sortable: false,
          value: "amount"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      currentOrgId: "contextStore/orgId",
      user: "oidcStore/oidcUser"
    })
  }
};
</script>