import { mapGetters, mapActions } from "vuex";

const buypassMerchants = {
  data() {
    return {
      buypassOrgId: null
    };
  },
  methods: {
    ...mapActions({
      fetch: "buypassOrgsStore/fetch"
    }),
    async fetchBuypassOrgsIfRequired() {
      if (!this.buypassOrgsLoaded && this.buypassOrgId && this.orgsLoaded) {
        await this.fetch({
          personId: this.user.personid,
          buypassId: this.user.buypassid
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      buypassOrgs: "buypassOrgsStore/orgs",
      buypassOrgsLoaded: "buypassOrgsStore/loaded",
      orgsLoaded: "currentOrgsStore/loaded",
      getOrg: "currentOrgsStore/org",
      user: "oidcStore/oidcUser"
    }),
    isBuypassAdminForCurrentOrg() {
      if (!this.buypassOrgId || !this.buypassOrgsLoaded) {
        return false;
      }

      const org = this.getOrg(this.buypassOrgId);

      if (!org?.org?.orgNumber) {
        return false;
      }

      return this.buypassOrgs?.findIndex(x => x?.orgNumber === org.org.orgNumber) > -1;
    }
  },
  watch: {
    orgsLoaded: {
      async handler(val) {
        if (val) {
          await this.fetchBuypassOrgsIfRequired();
        }
      },
      immediate: true
    },
    buypassOrgId: {
      async handler(val) {
        if (val) {
          await this.fetchBuypassOrgsIfRequired();
        }
      },
      immediate: true
    }
  }
};

export default buypassMerchants;