<template>
  <div>
    <h1 class="text-h1 mb-6 mt-10">Error in authentication</h1>
  </div>
</template>

<script>
export default {
  name: 'OidcCallbackError'
};
</script>
