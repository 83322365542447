import urljoin from 'url-join';
import Vue from '@/main';
const utilsRestStore = {
    namespaced: true,
    state: {
        baseUrl: urljoin(process.env.VUE_APP_API_URL, 'api', 'v1'),
        activeRequest: {}
    },
    mutations: {
        setActiveRequest(state, urlState) {
            if (urlState?.url) {
                const obj = {};
                obj[urlState.url] = urlState.state;
                const merged = { ...state.activeRequest, ...obj };
                Vue.$set(state, "activeRequest", merged);
            }
        }
    },
    actions: {
        setActiveRequest(context, payload){
            context.commit("setActiveRequest", payload);
        }
    },
    getters: {
        baseUrl: state => state.baseUrl,
        urlState: state => url => {
            if (state.baseUrl) {
                return state.activeRequest[urljoin(state.baseUrl, url)];
            }
            return null;
        }
    }
};

export default utilsRestStore;