import { api } from "@/utils/api";
import Vue from '@/main';
import _ from "lodash";
import store from "@/store";

const getFilteredConfigs = function (orgIds, responseData) {
    if (orgIds.length) {
        return responseData.filter(x => !orgIds.includes(x.orgId));
    }

    return responseData;
};

const rateConfigurationsStore = {
    namespaced: true,
    state: {
        lookup: {}
    },
    mutations: {
        addRateConfiguration(state, rateConfiguration) {
            if (state.lookup[rateConfiguration.orgId]) {
                state.lookup[rateConfiguration.orgId].push(rateConfiguration);
            } else {
                Vue.$set(state.lookup, rateConfiguration.orgId, [rateConfiguration]);
            }
        },
        removeRateConfigurationGroup(state, orgId) {
            delete state.lookup[orgId];
        },
        clear(state) {
            state.lookup = {};
        }
    },
    actions: {
        async fetchByOrgId(context, orgId) {
            const federationId = context.rootGetters["application/currentApplicationStore/federationId"];

            if (federationId) {
                orgId = federationId;
            }

            const fetchedOrgIds = Object.keys(context.state.lookup).map(x => Number(x));

            //request should be made only if we have not fetched org ids
            if (fetchedOrgIds.includes(orgId)) {
                return;
            }

            const response = await api.v1.orgs.rateConfigurations(orgId);

            if (response.status === 200) {
                if (!response.data) {
                    throw Vue.$t("errorMessages.missingDataFromServer");
                }

                response.data.forEach(x => context.commit("addRateConfiguration", x));
            }
        },
        //While fetchByOrgId and the rateConfiguration getter is checking against federationId here, this method does the same server side.
        async fetchByApplicationId(context, applicationId) {
            const response = await api.v1.applications.rateConfigurations(applicationId);

            if (response.status === 200) {
                if (!response.data) {
                    throw Vue.$t("errorMessages.missingDataFromServer");
                }

                const fetchedOrgIds = Object.keys(context.state.lookup).map(x => Number(x));

                const newConfigs = getFilteredConfigs(fetchedOrgIds, response.data);

                for (const config of newConfigs) {
                    context.commit("addRateConfiguration", config);
                    await store.dispatch("application/currentApplicationStore/addRelatedOrg", config.orgId);
                }
            }
        }
    },
    getters: {
        rateConfiguration: (state, _getters, _rootState, rootGetters) => (orgId, date, rateConfigurationGroup) => {
            const federationId = rootGetters["application/currentApplicationStore/federationId"];

            if (federationId) {
                orgId = federationId;
            }

            if (!state.lookup[orgId] || state.lookup[orgId].length === 0) {
                return null;
            }

            const rateConfiguration = _(state.lookup[orgId])
                .filter(x => Vue.$moment(x.fromDate).isSameOrBefore(date))
                .sort(x => x.fromDate)
                .last();

            if (!rateConfiguration) {
                return null;
            }

            if (rateConfigurationGroup) {
                return rateConfiguration[rateConfigurationGroup];
            }

            return rateConfiguration;
        }
    }
};

export default rateConfigurationsStore;