<template>
  <div>
    <p>
      <span class="remove-underline-animation">
        <a href="https://www.idrettsforbundet.no/digital/idrettsoppgjor/"
          target="_blank"
          rel="noopener noreferrer"
          class="secondary-variant--text"
        >{{ $t("publicPageStrings.readMore") }}&rarr;</a>
      </span>
    </p>
    <v-expansion-panels
      flat
      hover
      focusable
      tile
      :value="expandedSections"
      multiple
      v-if="segmentCount > 1"
    >
      <v-expansion-panel
        v-for="(segment, index) of contentSegments"
        :key="index"
      >
        <v-expansion-panel-header>{{ segment.title }}</v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4" style="white-space: pre-wrap">{{
          segment.content
        }}</v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div
      v-else-if="contentSegments"
      v-for="(segment, index) of contentSegments"
      :key="index"
    >
      <h3 class="text-h3 mt-8 mb-5">{{ segment.title }}</h3>
      <p style="white-space: pre-wrap">{{ segment.content }}</p>
    </div>
  </div>
</template>

<script>
import HelpBarContentTypes from "@/enums/HelpBarContentTypes";
import { mapGetters } from "vuex";

export default {
  name: "ApplicationHelpContent",
  computed: {
    ...mapGetters({
      contentType: "helpBarStore/contentType",
      expandedSections: "helpBarStore/expandedSections"
    }),
    segmentCount() {
      return Object.keys(this.contentSegments).length;
    },
    contentSegments() {
      //Might break if enum position is not the same as enum value, or if enum names doesn't match in the i18n.js file
      const segments = this.$t(`help.${Object.entries(HelpBarContentTypes)[this.contentType][0]}`);
      return segments;
    }
  }
};
</script>