//nif implementation of axios requests
import store from '@/store';
import axios from 'axios';
import urljoin from 'url-join';

const getUrl = path => {
    const baseUrl = store.getters['utilsRestStore/baseUrl'];

    if (!baseUrl) {
        throw new Error("Missing base path");
    }

    return urljoin(baseUrl, path);
};

const getConfig = config => {
    const token = store.getters['oidcStore/oidcAccessToken'];

    const currentOrgId = store.getters['contextStore/orgId'];

    const defaultHeaders = {
            Authorization: `Bearer ${token}`,
            'nif-org-id': currentOrgId
    };

    // defaultHeaders is merged with headers from incoming
    // config, if keys are overlapping incoming
    // config will be with priority

    const mergedHeaders = {...defaultHeaders, ...config.headers};

    const result = { ...config, headers: mergedHeaders };

    if (!result?.headers?.Authorization) {
        throw new Error("Missing Authorization token");
    }
    return result;
};

export const get = async function (path, config = {}) {
    const url = getUrl(path);
    const requestConfig = getConfig(config);
    return await axios.get(url, requestConfig);
};

export const post = async function (path, body, config = {}) {
    const url = getUrl(path);
    const requestConfig = getConfig(config);
    return await axios.post(url, body, requestConfig);
};

export const put = async function (path, body, config = {}) {
    const url = getUrl(path);
    const requestConfig = getConfig(config);
    return await axios.put(url, body, requestConfig);
};

export const patch = async function (path, body, config = {}) {
    const url = getUrl(path);
    const requestConfig = getConfig(config);
    return await axios.patch(url, body, requestConfig);
};

export const del = async function (path, config = {}) {
    const url = getUrl(path);
    const requestConfig = getConfig(config);
    return await axios.delete(url, requestConfig);
};