<template>
<div>
  <span :class="labelClass">{{ this.labelText }}</span>
</div>
</template>

<script>
import { filterAvailableClaimTypes } from "@/utils/claim";

export default {
  name: "ReceiptClaimTypePicker",
  props: {
    claim: {
      type: Object,
      defaut: () => ({})
    },
    configurationTypeProperty: {
      type: String,
      required: true
    },
    filterEnabledTypesFunc: {
      type: Function,
      required: true
    },
    resourcesProperty: {
      type: String,
      required: true
    },
    modelBindingProperty: {
      type: String,
      required: true
    },
    permittedRateType: {
      type: Number
    }
  },
  computed: {
    labelText() {
      if (!this.claim?.date) {
        return this.$t("claimTypeAvailabilityLabelStrings.noDateSelected");
      }
      if (!this.isPermittedRateTypeAvailableOnSelectedDate) {
        return this.$t("claimTypeAvailabilityLabelStrings.noSalariesOnDate");
      }

      return "";
    },
    labelClass() {
      if (this.claim?.date && !this.isPermittedRateTypeAvailableOnSelectedDate) {
        return "error--text";
      }

      return "";
    },
    isPermittedRateTypeAvailableOnSelectedDate() {
      return this.availableClaimTypes(this.claim).filter(x => x.id === this.permittedRateType).length > 0;
    }
  },
  watch: {
    isPermittedRateTypeAvailableOnSelectedDate(val) {
      if (val) {
        this.$set(this.claim, this.modelBindingProperty, this.permittedRateType);

      } else {
        this.$set(this.claim, this.modelBindingProperty, null);
      }

      this.resetClaimPayingOrgs();
    }
  },
  methods: {
    resetClaimPayingOrgs() {
      this.$delete(this.claim, "payingOrgs");

      this.$emit("claim-type-change");
    },
    availableClaimTypes(claim) {
      return filterAvailableClaimTypes(
        claim,
        this.configurationTypeProperty,
        this.filterEnabledTypesFunc,
        this.resourcesProperty
      );
    }
  }
};
</script>