import Vue from "@/main";
import { toMoney } from "@/utils/numeric";
import { calculateSalaryFractionCoefficientForOrg, getSalaryFractionsCountForOrg, getTotalSalaryFractionsCount } from "@/utils/claims/salary";

export const calculateReceiptShare = function (receiptClaim) {
    if (receiptClaim?.payingOrgs?.length) {
        return 1 / receiptClaim.payingOrgs.length;
    }

    return 0;
};

export const calculateReceiptOrgAmount = function (receiptClaim, orgId) {
    if (receiptClaim.usesSalaryFraction) {
        return receiptClaim.amount * calculateSalaryFractionCoefficientForOrg(orgId);
    }

    if (receiptClaim?.amount && receiptClaim?.payingOrgs?.length) {
        return receiptClaim.amount / receiptClaim.payingOrgs.length;
    }

    return receiptClaim?.amount;
};

export const getReceiptExplanationForOrg = function (orgId, claim) {
    if (claim.usesSalaryFraction) {
        const parts = getSalaryFractionsCountForOrg(orgId);
        const totalParts = getTotalSalaryFractionsCount();

        if (parts !== totalParts) {
            return Vue.$tc("summaryPanelStrings.salaryFractionExplanation", parts, { parts, amount: toMoney(claim.amount), total: totalParts });
        } else {
            return toMoney(claim.amount);
        }
    } else if (claim.payingOrgs.length > 1){
        return `${toMoney(claim.amount)} / ${claim.payingOrgs.length} ${Vue.$t(
            "summaryPanelStrings.payingOrgs"
        )}`;
    } else {
        return toMoney(claim.amount);
    }
};

export const getReceiptClaimsForOrg = function (orgId, claims, prefix) {
    const orgClaims = claims.receipts.filter(
        x => x?.payingOrgs?.includes(orgId)
    );

    return orgClaims.map(function (x, index) {
        return {
            id: [prefix, index].join("_"),
            name: Vue.$t(`enums.receiptTypes.${x.claimTypeId}`),
            details: getReceiptExplanationForOrg(orgId, x),
            amount: calculateReceiptOrgAmount(x, orgId)
        };
    });
};