<template>
  <v-card outlined class="fill-height">
    <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{$t("journalStrings.receiver.title")}}</h2></v-card-title>
    <v-card-text>
      <v-row class="pb-2">
        <v-col cols="12" xs="12" sm="8" md="10" lg="9" xl="10" class="py-0">
          {{ orgName }}
        </v-col>
      </v-row>
      <v-row v-if="currentOrgInfo.addressLine1" class="pb-2">
        <v-col cols="12" xs="12" sm="8" md="10" lg="9" xl="10" class="py-0">
          {{ currentOrgInfo.addressLine1 }}
        </v-col>
      </v-row>
      <v-row v-if="currentOrgInfo.addressLine2" class="pb-2">
        <v-col cols="12" xs="12" sm="8" md="10" lg="9" xl="10" class="py-0">
          {{ currentOrgInfo.addressLine2 }}
        </v-col>
      </v-row>
      <v-row class="pb-2">
        <v-col cols="12" xs="12" sm="8" md="10" lg="9" xl="10" class="py-0">
          {{ `${currentOrgInfo.postCode} ${currentOrgInfo.city}` }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { getOrgDisplayName } from "@/utils/org";

export default {
  name: "JournalSender",
  props: {
    orgId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      payingOrgs: "application/payingOrgsStore/payingOrgs"
    }),
    currentOrgInfo() {
      const orgs = this.payingOrgs.filter(x => x.id === this.orgId);

      if (orgs?.length) {
        return orgs[0];
      }

      return {};
    },
    orgName() {
      return getOrgDisplayName(this.currentOrgInfo);
    }
  }
};
</script>