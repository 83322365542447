const VehicleTypes = {
    Car: 1,
    ElectricalCar: 2,
    Snowmobile: 3,
    BoatWithEngine: 4,
    MotorbikeAbove125: 5,
    MotorbikeBelow125: 6
};

Object.freeze(VehicleTypes);

export default VehicleTypes;
