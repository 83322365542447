<template>
  <v-app :class="['theme--frontpage', {'splash-background': !$vuetify.breakpoint.mobile}]">
    <v-app-bar :height="$vuetify.breakpoint.smAndDown ? 100 : 160" absolute elevation="0" flat tile clipped-left app color="transparent">
      <v-container>
        <v-row>
          <v-col>
            <v-img src="@/assets/IO.svg" id="io-logo" :alt="$t('appLayoutStrings.toolbarTitle')" width="300" height="70"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main>
      <slot />
    <app-footer></app-footer>
    </v-main>
  </v-app>
</template>

<script>
import AppFooter from './components/AppFooter';

export default {
  name: "PublicLayout",
  components: {
    AppFooter
  }
};
</script>

<style lang="scss">
@import "~@nifnpm/vue-cli-plugin-vuetify-preset/preset/mixins.scss";

.theme--frontpage {
  .v-main {
    background: inherit;
  }
  .frontpage-title {
    font-size: 2.75rem !important;
  }

  .frontpage-font-size {
    font-size: 18px !important;
  }

  ul.checkmarked {
    list-style: none;
  }

  ul.checkmarked li {
    margin-bottom: 0.7rem;
  }

  ul.checkmarked li:before {
    content: "✓";
    margin-right: 0.8rem;
    font-size: 14px;
  }

  &.v-application {
    background: var(--v-primary-base);
    color: var(--v-inverted-base);

    &.splash-background {
      @include gradient-image(var(--v-primary-base), url("~@/assets/relay.jpg"));
    }
  }

  .frontpage-button {
    box-shadow: 0px 11px 6px var(--v-primary-base) !important;
  }
}
</style>