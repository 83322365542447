import { copy } from "@/utils/object";
import Vue from '@/main';
import { api } from "@/utils/api";
import { handleError } from "@/utils/errorHandler";

const orgConfigurationStore = {
    namespaced: true,
    state: {
        orgConfiguration: {
            isActive: null
        },
        orgConfigurationCache: {}
    },
    mutations: {
        setOrgConfiguration(state, orgConfiguration) {
            if (orgConfiguration) {
                state.orgConfiguration = copy(orgConfiguration);
                state.orgConfigurationCache[orgConfiguration.orgId] = copy(orgConfiguration);
            }
        },
        setOrgConfigurationInCache(state, orgConfiguration) {
            if (orgConfiguration) {
                state.orgConfigurationCache[orgConfiguration.orgId] = copy(orgConfiguration);
            }
        }
    },
    getters: {
        cache: state => state.orgConfigurationCache,
        isActive: state => state.orgConfiguration.isActive
    },
    actions: {
        async fetch(context, params) {
            if (context.state.orgConfigurationCache[params.orgId]) {
                return;
            }

            const response = await api.v1.orgs.orgConfigurations(params.orgId);
            if (response.status === 200) {
                if (!response.data) {
                    Vue.$t("errorMessages.missingDataFromServer");
                }
                context.commit("setOrgConfiguration", response.data.orgConfiguration);
            } else {
                throw response;
            }
        },
        async getOrgConfiguration(context, orgId) {
            if (context.state.orgConfigurationCache[orgId]) {
                return context.state.orgConfigurationCache[orgId];
            }

            try {
                const response = await api.v1.orgs.orgConfigurations(orgId);
                if (response.status === 200) {

                    if (!response.data) {
                        Vue.$t("errorMessages.missingDataFromServer");
                        return null;
                    }
                    context.commit("setOrgConfigurationInCache", response.data.orgConfiguration);
                    return response.data.orgConfiguration;
                } else {
                    throw response;
                }
            } catch (error) {
                await handleError(error);
            }

            return null;
        }
    }
};

export default orgConfigurationStore;