<template>
  <span class="body-2 red-underline-animation px-2">
    <router-link :to="to">&larr;<slot></slot></router-link>
  </span>
</template>

<script>
export default {
  name: "BreadcrumbRouterLink",
  props: {
    to: {
      type: [String, Object],
      required: true
    }
  }
};
</script>