const commonStrings = {
  "save": "Lagre",
  "appName": "Idrettsoppgjør",
  "createNew": "Opprett ny",
  "return": "Tilbake",
  "close": "Lukk",
  "edit": "Rediger",
  "remove": "Fjern",
  "add": "Legg til",
  "cancel": "Avbryt",
  "update": "Oppdater",
  "proceed": "Fortsett",
  "date": "Dato",
  "from": "Fra",
  "to": "Til",
  "amount": "Beløp (kr)",
  "tableAriaLabel": "{0} for {1}",
  "yes": "Ja",
  "no": "Nei",
  "view": "Vis",
  "comment": "Merknad",
  "dateDisplayFormat": "DD.MM.YYYY",
  "dateValueFormat": "YYYY-MM-DD HH:mm:ssZZ",
  "timeDisplayFormat": "HH:mm",
  "monthYearDisplayFormat": "MMM YYYY",
  "dateTimeDisplayFormat": "@:commonStrings.dateDisplayFormat @:commonStrings.timeDisplayFormat",
  "rateConfiguration": "Oppsett refusjonsskjema",
  "recipients": "Mottakere",
  "decimal": "desimal",
  "decimals": "desimaler",
  "details": "Detaljer",
  "signOut": "Logg ut",
  "next": "Neste",
  "applicationDetails": "Kravdetaljer",
  "sender": "Avsender",
  "singleSportClub": "Særidrettslag",
  "multiSportClub": "Fleridrettslag",
  "salaryFraction": "Honorarbrøk",
  "salaryFractionLabel": "Del på @.lower:commonStrings.salaryFraction",
  "clear": "Nullstill",
  "clearFilters": "@:commonStrings.clear filter",
  "financeAccount": "Hovedbokskonto",
  "print": "Skriv ut",
  "month": "Måned",
  "count": "Antall",
  "toOverview": "Til oversikt",
  "notDefined": "Ukjent",
  "readMore": "Les mer",
  "passenger": "Passasjer",
  "passengers": "@:(commonStrings.passenger)er",
  "passengerCount": "{count} @.lower:commonStrings.passenger | {count} @.lower:commonStrings.passengers",
  "selectOrg": "Velg organisasjonsledd",
  "orgAutocompleteHint": "Oppgi minst 3 bokstaver for å søke, velg deretter ønsket organisasjon fra liste",
  "noOrgsFound": "Ingen organisasjoner ble funnet",
  "childOrgs": "Underliggende organisasjonsledd"
};

export default commonStrings;