<template>
  <span class="remove-underline-animation">
    <router-link :class="linkClass" :to="to"><slot></slot>&rarr;</router-link>
  </span>
</template>

<script>
export default {
  name: "InlineRouterLink",
  props: {
    to: {
      type: [String, Object],
      required: true
    },
    linkClass: {
      type: String,
      default: "secondary-variant--text"
    }
  }
};
</script>