const statisticsStrings = {
  name: "Statistikk",
  pageTitle: "@:statisticsStrings.name",
  pageHeader: "@:statisticsStrings.name",
  newOrgsByMonth: {
    panelHeader: "Nye organisasjonsledd som har mottatt krav pr måned",
    tableHeaders: {
      month: "@:commonStrings.month",
      count: "@:commonStrings.count nye org.ledd i måned",
      accumulatedTotal: "Akkumulert antall org.ledd totalt"
    }
  },
  approvalsByMonth: {
    panelHeader: "Godkjente krav pr måned",
    tableHeaders: {
      month: "@:commonStrings.month",
      count: "@:commonStrings.count krav i måned, {0}",
      yearAccumulatedTotal: "@:commonStrings.count krav hittil i året, {0}"
    }
  }
};

export default statisticsStrings;