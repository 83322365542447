import Vue from 'vue';
import Vuex from 'vuex';

import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import { oidcSettings } from '../config/oidc';

import utilsRestStore from './modules/utilsRestStore';
import contextStore from './modules/contextStore';
import applicationPayingOrgsStore from './modules/application/payingOrgsStore';
import applicationRateConfigurationsStore from './modules/application/rateConfigurationsStore';
import currentApplicationStore from './modules/application/currentApplicationStore';
import applicationSalaryFractionsStore from './modules/application/salaryFractionsStore';
import orgConfigurationStore from './modules/settings/orgConfigurationStore';
import rateSummariesStore from './modules/settings/rateSummariesStore';
import currentTaxRatePeriodStore from './modules/application/currentTaxRatePeriodStore';
import errorStore from './modules/errorStore';
import helpBarStore from './modules/helpBarStore';
import currentOrgsStore from './modules/currentOrgsStore';
import functionTypes from './modules/functionTypesStore';
import infoMessagesStore from './modules/infoMessagesStore';
import buypassOrgsStore from "./modules/buypassOrgsStore";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        oidcStore: vuexOidcCreateStoreModule(
            oidcSettings,
            {
                namespaced: true,
                dispatchEventsOnWindow: true
            }
        ),
        functionTypes: functionTypes,
        utilsRestStore: utilsRestStore,
        contextStore: contextStore,
        currentTaxRatePeriodStore: currentTaxRatePeriodStore,
        errorStore: errorStore,
        helpBarStore: helpBarStore,
        currentOrgsStore: currentOrgsStore,
        infoMessagesStore: infoMessagesStore,
        buypassOrgsStore: buypassOrgsStore,
        application: {
            namespaced: true,
            modules: {
                currentApplicationStore,
                'payingOrgsStore': applicationPayingOrgsStore,
                'rateConfigurationsStore': applicationRateConfigurationsStore,
                'salaryFractionsStore': applicationSalaryFractionsStore
            }
        },
        settings: {
            namespaced: true,
            modules: {
                orgConfigurationStore,
                rateSummariesStore
            }
        }
    },
    strict: debug
});
