const rateConfigurationStrings = {
  name: "@:commonStrings.rateConfiguration",
  pageTitle: "@:rateConfigurationStrings.name",
  pageHeader: "@:rateConfigurationStrings.name fra og med {0}",
  fromDateLabel: "Gyldig fra dato",
  receiptTitle: "Utlegg og kvitteringer",
  receiptTypeTableActiveHeader: "Aktiv",
  receiptTypeTableTypeHeader: "Type utlegg",
  receiptTypeTableRequiresAttachmentHeader: "Kvittering påkrevd",
  receiptTypeTableAccountingHeader: "@:commonStrings.financeAccount",
  saveSuccessAlert: "Innstillingene ble lagret",
  vehicleTitle: "Kjøregodtgjørelse",
  vehicleTypeTableEnabledHeader: "Aktiv",
  vehicleTypeTableTypeHeader: "Type kjøretøy",
  vehicleTypeTableRateHeader: "Sats (kr/km)",
  vehicleAccountingIdLabel: "@:commonStrings.financeAccount",
  vehiclePassengerAccountingIdLabel: "@:commonStrings.financeAccount @.lower:commonStrings.passenger",
  vehicleTrailerAccountingIdLabel: "@:commonStrings.financeAccount tilhenger",
  vehicleMinDrivingDistanceLabel: "Min. ant. km",
  vehiclePassengerRateLabel: "@:commonStrings.passenger kr/km",
  vehicleTrailerRateLabel: "Tilhenger kr/km",
  salaryTitle: "Honorar",
  salaryActiveHeader: "Aktiv",
  salaryTypeHeader: "Type honorar",
  salaryAccountingHeader: "@:commonStrings.financeAccount",
  diet: {
    header: "Diettgodtgjørelse",
    accountingLabel: "@:commonStrings.financeAccount",
    nightAdditionAccountingLabel: "@:commonStrings.financeAccount nattillegg",
    lengthSubheader: "Diett uten overnatting",
    staySubheader: "Diett med overnatting",
    deductionSubheader: "Diettfradrag",
    lengthEnabledHeader: "Aktiv",
    lengthRateHeader: "Varighet",
    lengthAmountHeader: "Sats (kr)",
    stayEnabledHeader: "Aktiv",
    stayRateHeader: "Type overnatting",
    stayAmountHeader: "Sats (kr/overnatting)",
    deductionEnabledHeader: "Aktiv",
    deductionRateHeader: "Fradrag",
    deductionPercentage: "Prosent"
  },
  existingConfigNotFound: "Eksisterende oppsett ble ikke funnet",
  fillDefaultsButton: "Fyll ut med idrettens standard"
};

export default rateConfigurationStrings;