<template>
  <div>
    <div class="py-2 font-weight-bold">
      {{ $t("previewApplicationPageStrings.payoutPanel.nextActionPayment") }}
    </div>
    <div class="py-2">
      <span v-if="userCanPay">
        {{ canPayText }}
      </span>
      <span v-else>
        {{ cannotPayText }}
        <permissions-modal
          :link-text="$t('previewApplicationPageStrings.linkToRequiredPermissions')"
          :start-text="$t('previewApplicationPageStrings.payoutPanel.buypassPermissionsModalStartText')"
          :title="permissionsModalTitle"
          :end-text="permissionsModalEndText"
          :function-type-names="permittedFunctionTypeNames">
        </permissions-modal>
      </span>
    </div>
    <div class="py-2">
      <span v-if="isApprovedByAll">
        {{ $t("previewApplicationPageStrings.allOrgsHaveApproved")}}
      </span>
      <span v-else>
        {{ $t("previewApplicationPageStrings.allOrgsHaveNotApproved", [approvedOrgsCount, totalOrgsCount])}}
      </span>
    </div>
    <div class="py-2">
      <span>{{ $t("previewApplicationPageStrings.payoutPanel.chosenPaymentMethod")}}</span> <span class="font-weight-bold">{{chosenPaymentMethodText}}</span>
    </div>
    <template v-if="isApprovedByAll && userCanPay">
      <div class="py-2">
          <v-btn v-if="userCanReset"
            rounded
            class="primary-inverted-button mr-0 mr-sm-2 mt-2 mt-sm-0"
            @click="$emit('payment-reset', handledOrgId)"
            :disabled="isPaying || isResetting || isTypingResponse"
            :loading="isResetting"
            :block="$vuetify.breakpoint.xsOnly"
          >{{ $t("previewApplicationPageStrings.resetPaymentButton") }}</v-btn>
        <v-btn
          rounded
          class="primary-button mr-0 mr-sm-2 mt-2 mt-sm-0"
          @click="$emit('application-payout-requested', paymentMethod)"
          :disabled="isPaying || isResetting || isTypingResponse"
          :loading="isPaying"
          :block="$vuetify.breakpoint.xsOnly"
        >{{ payoutButtonLabel }}</v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import { urls } from "@/utils/api";
import { mapGetters } from "vuex";
import PermissionsModal from "./PermissionsModal";
import { payoutPermittedFunctionTypes } from "@/utils/permissions";
import PaymentMethods from "@/enums/PaymentMethods";

export default {
  name: "PayoutPanel",
  components: { PermissionsModal },
  props: {
    handledOrgId: Number,
    approvedOrgsCount: Number,
    totalOrgsCount: Number,
    isApprovedByAll: Boolean,
    handledOrgName: String,
    userCanPay: Boolean,
    userCanReset: Boolean,
    paymentMethod: Number,
    isTypingResponse: Boolean
  },
  computed: {
    ...mapGetters({
      urlState: "utilsRestStore/urlState"
    }),
    isPaying() {
      if (this.$route.params.id) {
        return this.urlState(urls.v1.applicationOrgs.pay(this.$route.params.id, this.handledOrgId));
      }
      return false;
    },
    isResetting() {
      if (this.$route.params.id) {
        return this.urlState(urls.v1.applicationOrgs.resetPaymentMethod(this.$route.params.id, this.handledOrgId));
      }
      return false;
    },
    permittedFunctionTypeNames() {
      return payoutPermittedFunctionTypes().map(x => x.name);
    },
    chosenPaymentMethodText(){
      return this.$t(`enums.paymentMethods.${this.paymentMethod}`);
    },
    payoutButtonLabel() {
      if(this.paymentMethod === PaymentMethods.Manual) {
        return this.$t("previewApplicationPageStrings.payoutPanel.manualPayoutButton");
      }
      if(this.paymentMethod === PaymentMethods.Buypass) {
        return this.$t("previewApplicationPageStrings.payoutPanel.buypassPayoutButton");
      }
      return "";
    },
    permissionsModalTitle() {
      if(this.paymentMethod === PaymentMethods.Manual) {
        return this.$t("previewApplicationPageStrings.payoutPanel.manualPermissionsModalTitle");
      }
      if(this.paymentMethod === PaymentMethods.Buypass) {
        return this.$t("previewApplicationPageStrings.payoutPanel.buypassPermissionsModalTitle");
      }
      return "";
    },
    permissionsModalEndText() {
      if(this.paymentMethod === PaymentMethods.Manual) {
        return this.$t("previewApplicationPageStrings.payoutPanel.manualPermissionsModalEndText");
      }
      if(this.paymentMethod === PaymentMethods.Buypass) {
        return this.$t("previewApplicationPageStrings.payoutPanel.buypassPermissionsModalEndText");
      }
      return "";
    },
    canPayText(){
      if(this.paymentMethod === PaymentMethods.Manual) {
        return this.$t("previewApplicationPageStrings.payoutPanel.youCanPayout", [this.handledOrgName]);
      }
      if(this.paymentMethod === PaymentMethods.Buypass) {
        return this.$t("previewApplicationPageStrings.payoutPanel.youCanPayoutBuypass", [this.handledOrgName]);
      }
      return "";
    },
    cannotPayText(){
      if(this.paymentMethod === PaymentMethods.Manual) {
        return this.$t("previewApplicationPageStrings.payoutPanel.youCannotPayout", [this.handledOrgName]);
      }
      if(this.paymentMethod === PaymentMethods.Buypass) {
        return this.$t("previewApplicationPageStrings.payoutPanel.youCannotPayoutBuypass", [this.handledOrgName]);
      }
      return "";
    }
  }
};
</script>