<template>
  <tr :class="{'v-data-table__mobile-table-row': isMobile}">
    <td v-if="!isMobile">{{stay.date| moment($t("commonStrings.dateDisplayFormat"))}}</td>
    <td v-else class="v-data-table__mobile-row">
      <div class="v-data-table__mobile-row__header">{{$t("dietPanelStrings.stayTableHeaders.date")}}</div>
      <div
        class="v-data-table__mobile-row__cell"
      >{{stay.date| moment($t("commonStrings.dateDisplayFormat"))}}</div>
    </td>
    <td class="truncated" v-if="!isMobile">
      <span v-if="!durationIsOvernight && isEligible">{{$t(`enums.dietLengthTypes.${lengthType}`)}}</span>
      <span v-else-if="!isEligible">{{$t(`enums.dietDurationTypes.${stayDurationType}`)}}</span>
      <validation-provider
        v-if="durationIsOvernight"
        :rules="stayTypeValidationRules"
        v-slot="{errors}"
        :name="$t('dietPanelStrings.stayTableHeaders.stayType')"
      >
        <v-select
          :items="availableStayTypesDropdown"
          v-model="stay.dietStayTypeId"
          :error-messages="errors"
          item-value="id"
          name="stays"
          :placeholder="$t('dietPanelStrings.stayTableHeaders.stayType')"
          clearable
          dense
          outlined
        >
          <template v-slot:append>
            <v-icon tag="span">mdi-menu-down</v-icon>
          </template>
          <template v-slot:item="data">{{data.item.nameWithOrgs}}</template>
          <template v-slot:selection="data">{{data.item.name}}</template>
        </v-select>
      </validation-provider>
    </td>
    <td v-else class="v-data-table__mobile-row">
      <div
        class="v-data-table__mobile-row__header"
      >{{$t("dietPanelStrings.stayTableHeaders.stayType")}}</div>
      <div class="v-data-table__mobile-row__cell">
        <span
          v-if="!durationIsOvernight && isEligible"
        >{{$t(`enums.dietLengthTypes.${lengthType}`)}}</span>
        <span v-else-if="!isEligible">{{$t(`enums.dietDurationTypes.${stayDurationType}`)}}</span>
        <validation-provider
          v-if="durationIsOvernight"
          :rules="stayTypeValidationRules"
          v-slot="{errors}"
          :name="$t('dietPanelStrings.stayTableHeaders.stayType')"
        >
          <v-select
            :items="stayTypes"
            v-model="stay.dietStayTypeId"
            :error-messages="errors"
            name="stays"
            :placeholder="$t('dietPanelStrings.stayTableHeaders.stayType')"
            clearable
            outlined
            dense
          ></v-select>
        </validation-provider>
      </div>
    </td>
    <td class="truncated" v-if="!isMobile">
      <v-checkbox
        color="primary"
        :label="$t('dietPanelStrings.stayTableHeaders.nightAddition')"
        v-model="stay.hasNightAddition"
        :disabled="disabledNightAddition"
      ></v-checkbox>
    </td>
    <td v-else class="v-data-table__mobile-row">
      <div
        class="v-data-table__mobile-row__header"
      >{{$t("dietPanelStrings.stayTableHeaders.nightAddition")}}</div>
      <div class="v-data-table__mobile-row__cell">
        <v-checkbox
          color="primary"
          :label="$t('dietPanelStrings.stayTableHeaders.nightAddition')"
          v-model="stay.hasNightAddition"
          :disabled="disabledNightAddition"
        ></v-checkbox>
      </div>
    </td>
    <td v-if="!isMobile" class="text-right">
      <amount-label :value="stay.amount"></amount-label>
    </td>
    <td v-else class="v-data-table__mobile-row">
      <div
        class="v-data-table__mobile-row__header"
      >{{$t("dietPanelStrings.stayTableHeaders.amount")}}</div>
      <div class="v-data-table__mobile-row__cell">
        <amount-label :value="stay.amount"></amount-label>
      </div>
    </td>
    <td v-if="!isMobile" class="truncated">
      <v-select
        v-model="stay.dietDeductionIds"
        :items="deductionTypes"
        :placeholder="$t('dietPanelStrings.stayTableHeaders.meals')"
        multiple
        outlined
        dense
        :disabled="disabledDeductions"
      ></v-select>
    </td>
    <td v-else class="v-data-table__mobile-row">
      <div
        class="v-data-table__mobile-row__header"
      >{{$t("dietPanelStrings.stayTableHeaders.meals")}}</div>
      <div class="v-data-table__mobile-row__cell">
        <v-select
          v-model="stay.dietDeductionIds"
          :items="deductionTypes"
          :placeholder="$t('dietPanelStrings.stayTableHeaders.meals')"
          multiple
          outlined
          dense
          :disabled="disabledDeductions"
        ></v-select>
      </div>
    </td>
    <td v-if="!isMobile">
      <v-text-field
        :placeholder="$t('dietPanelStrings.placeholders.description')"
        type="text"
        v-model="stay.stayDescription"
        :disabled="!isEligible"
        outlined
        dense
      ></v-text-field>
    </td>
    <td v-else class="v-data-table__mobile-row">
      <div
        class="v-data-table__mobile-row__header"
      >{{$t("dietPanelStrings.stayTableHeaders.description")}}</div>
      <div class="v-data-table__mobile-row__cell">
        <v-text-field
          :placeholder="$t('dietPanelStrings.placeholders.description')"
          type="text"
          v-model="stay.stayDescription"
          :disabled="!isEligible"
          outlined
          dense
        ></v-text-field>
      </div>
    </td>
  </tr>
</template>

<script>
import DietDeductionTypes from "@/enums/DietDeductionTypes";
import DietStayTypes from "@/enums/DietStayTypes";
import DietDurationTypes from "@/enums/DietDurationTypes";
import DietLengthTypes from "@/enums/DietLengthTypes";
import AmountLabel from "@/views/common/components/AmountLabel";
import { ValidationProvider } from "vee-validate";
import { toPercent } from "@/utils/numeric";
import deductionRates from "@/mixins/deductionRates";
import { calculateDietAmountForStay } from "@/utils/claims/diet";
import { filterAvailableClaimTypes } from "@/utils/claim";

export default {
  name: "DietStayRow",
  components: {
    AmountLabel,
    ValidationProvider
  },
  props: {
    stay: {
      type: Object,
      required: true
    },
    payingOrgs: {
      required: true,
      type: Array
    },
    stayDurationTypes: {
      type: Object,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: false
    },
    salaryFraction: {
      type: Boolean
    }
  },
  mixins: [deductionRates],
  methods: {
    updateStayAmount() {
      this.$set(
        this.stay,
        "amount",
        calculateDietAmountForStay(
          this.stay,
          this.payingOrgs,
          this.salaryFraction
        )
      );
    },
    updateDietLengthTypeId() {
      this.$set(this.stay, "dietLengthTypeId", this.lengthType);
    },
    filterEnabledDietStayTypes(conf) {
      return conf["dietRates"]["dietStayRateTypes"].filter(
        x => x.enabled && x.rate
      );
    },
    filterEnabledDietLengthTypes(conf) {
      return conf["dietRates"]["dietLengthRateTypes"].filter(
        x => x.enabled && x.rate
      );
    },
    durationTypeHasValidLengthType(durationType) {
      switch (durationType) {
        case DietDurationTypes.SixToTwelveHours:
          return this.availableLengthTypes.some(
            x => x.id === DietLengthTypes.SixToTwelveHours
          );
        case DietDurationTypes.Above12Hours:
          return this.availableLengthTypes.some(
            x => x.id === DietLengthTypes.Above12Hours
          );
        default:
          return false;
      }
    },
    getAsPercentIfNotZero(val) {
      if (val) {
        return`(${toPercent(val/100)})`;
      }

      return "";
    },
    getDeductionTypePercentage(val, taxRates) {
      switch (val) {
          case DietDeductionTypes.Breakfast:
            return this.getAsPercentIfNotZero(taxRates.breakfastDeductionRate);
          case DietDeductionTypes.Lunch:
            return this.getAsPercentIfNotZero(taxRates.lunchDeductionRate);
          case DietDeductionTypes.Dinner:
            return this.getAsPercentIfNotZero(taxRates.dinnerDeductionRate);
            default:
            return "";
        }
    }
  },
  watch: {
    "stay.dietStayTypeId": function (val) {
      if (val) {
        this.$set(this.stay, "dietLengthTypeId", null);

        if (val === DietStayTypes.Hotel) {
          this.$set(this.stay, "hasNightAddition", null);
        }
      } else if (this.stay.hasNightAddition) {
          this.$set(this.stay, "dietDeductionIds", []);
      }

      this.updateStayAmount();
    },
    "stay.dietLengthTypeId": function (val) {
      if (val) {
        this.$set(this.stay, "dietStayTypeId", null);
      }

      this.updateStayAmount();
    },
    "stay.dietDeductionIds": function () {
      this.updateStayAmount();
    },
    "stay.hasNightAddition": function (val) {
      if (val && !this.stay.dietStayTypeId) {
        this.$set(this.stay, "dietDeductionIds", []);
      }

      this.updateStayAmount();
    },
    stayDurationType(val) {
      if (!val || val === DietDurationTypes.NotEligible) {
        this.$set(this.stay, "dietDeductionIds", []);
        this.$set(this.stay, "dietLengthTypeId", null);
        this.$set(this.stay, "dietStayTypeId", null);
        this.$set(this.stay, "hasNightAddition", null);
        return;
      }

      this.updateDietLengthTypeId();
      this.updateStayAmount();
    },
    payingOrgs() {
      this.updateStayAmount();
    }
  },
  computed: {
    availableStayTypes() {
      return filterAvailableClaimTypes(
        this.stay,
        "rateType",
        this.filterEnabledDietStayTypes,
        "enums.dietStayTypes",
        this.payingOrgs
      );
    },
    availableLengthTypes() {
      return filterAvailableClaimTypes(
        this.stay,
        "rateType",
        this.filterEnabledDietLengthTypes,
        "enums.dietLengthTypes",
        this.payingOrgs
      );
    },
    availableStayTypesDropdown() {
      return this.availableStayTypes.filter(
        x => x.id !== DietStayTypes.NightAddition
      );
    },
    hasValidNightAdditionRateOnDay() {
      return this.availableStayTypes.some(
        x => x.id === DietStayTypes.NightAddition
      );
    },
    stayTypeValidationRules() {
      if (this.stay.hasNightAddition) {
        return "";
      }

      return "required";
    },
    disabledDeductions() {
      return (
        (!this.stay.dietStayTypeId &&
          !this.stay.dietLengthTypeId &&
          this.stay.hasNightAddition) ||
        this.stayDurationType === DietDurationTypes.NotEligible
      );
    },
    disabledNightAddition() {
      if (!this.hasValidNightAdditionRateOnDay) {
        return true;
      }

      return (
        this.stay.dietStayTypeId === DietStayTypes.Hotel ||
        !this.durationIsOvernight ||
        !this.isEligible
      );
    },
    deductionTypes() {
      const that = this;
      return Object.values(DietDeductionTypes).map(function (val) {
        const label = that.$t(`enums.dietDeductionTypes.${val}`);
        const percentage = that.getDeductionTypePercentage(val, that.taxRates);

        return {
          text: `${label} ${percentage}`,
          value: val
        };
      });
    },
    stayDurationType() {
      const type = this.stayDurationTypes[
        this.$moment(this.stay.date).dayOfYear()
      ];
      if (type?.dietDurationType) {
        if (type.dietDurationType === DietDurationTypes.WithOvernight) {
          return type.dietDurationType;
        }

        if(this.durationTypeHasValidLengthType(type.dietDurationType)){
          return type.dietDurationType;
        }
      }

      return DietDurationTypes.NotEligible;
    },
    lengthType() {
      switch (this.stayDurationType) {
        case DietDurationTypes.SixToTwelveHours:
          return DietLengthTypes.SixToTwelveHours;
        case DietDurationTypes.Above12Hours:
          return DietLengthTypes.Above12Hours;
        default:
          return null;
      }
    },
    durationIsOvernight() {
      return this.stayDurationType === DietDurationTypes.WithOvernight;
    },
    isEligible() {
      return this.stayDurationType !== DietDurationTypes.NotEligible;
    }
  },
  beforeMount() {
    this.updateDietLengthTypeId();
    this.updateStayAmount();
  },
  beforeDestroy() {
    this.$set(this.stay, "dietLengthTypeId", null);
    this.$set(this.stay, "dietStayTypeId", null);
    this.$set(this.stay, "stayDescription", null);
    this.$set(this.stay, "dietDeductionIds", []);
  }
};
</script>

<style scoped>
.truncated {
  max-width: 200px;
}
</style>