import SalaryTypes from "./SalaryTypes";
import ReceiptTypes from "./ReceiptTypes";

const AccountDefaults = {
  salaries: [
    {
      rateType: SalaryTypes.Default,
      accountingId: "5014"
    }
  ],
  receipts: [
    {
      rateType: ReceiptTypes.Meal,
      accountingId: "7162"
    },
    {
      rateType: ReceiptTypes.Custom,
      accountingId: "7790"
    },
    {
      rateType: ReceiptTypes.Stay,
      accountingId: "7144"
    },
    {
      rateType: ReceiptTypes.Flight,
      accountingId: "7141"
    },
    {
      rateType: ReceiptTypes.OtherTravel,
      accountingId: "7145"
    },
    {
      rateType: ReceiptTypes.Fuel,
      accountingId: "7000"
    },
    {
      rateType: ReceiptTypes.GiftsAndPrizes,
      accountingId: "7420"
    },
    {
      rateType: ReceiptTypes.HealthAndMedical,
      accountingId: "6585"
    },
    {
      rateType: ReceiptTypes.SportsEquipment,
      accountingId: "6580"
    },
    {
      rateType: ReceiptTypes.Communication,
      accountingId: "5210"
    },
    {
      rateType: ReceiptTypes.OfficeSupplies,
      accountingId: "6800"
    },
    {
      rateType: ReceiptTypes.Subscriptions,
      accountingId: "6480"
    },
    {
      rateType: ReceiptTypes.ComputerSupplies,
      accountingId: "6545"
    },
    {
      rateType: ReceiptTypes.MembershipFees,
      accountingId: "7400"
    },
    {
      rateType: ReceiptTypes.EntryFees,
      accountingId: "7410"
    },
    {
      rateType: ReceiptTypes.Education,
      accountingId: "6860"
    }
  ],
  diet: {
    accountingId: "7150",
    nightAdditionAccountingId: "7150"
  },
  vehicle: {
    accountingId: "7100",
    passengerAccountingId: "7100",
    trailerAccountingId: "7100"
  }
};

Object.freeze(AccountDefaults);

export default AccountDefaults;
