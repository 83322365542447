<template>
  <span>
    <v-dialog
      v-if="attachments.length"
      v-model="dialog"
      max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn rounded small class="primary-inverted-button" v-on="on">{{
          $t("receiptsPanelStrings.attachmentsModal.labels.showButtonCount", [attachments.length])
        }}</v-btn>
      </template>
      <v-card>
        <v-card-title>
          {{ $t("receiptsPanelStrings.attachmentsModal.attachmentListHeader") }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col>
              <attachment-list
                :attachments="attachments"
                @download="downloadFile"
              ></attachment-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-btn rounded class="primary-button" @click="close" small>{{
            $t("receiptsPanelStrings.attachmentsModal.labels.closeButton")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <span v-else>{{ $t('previewApplicationPageStrings.emptyValue') }}</span>
  </span>
</template>

<script>
import AttachmentList from "@/views/common/components/AttachmentList";
import { api } from "@/utils/api";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "ReceiptAttachmentsModal",
  components: {
    AttachmentList
  },
  data() {
    return {
      dialog: false
    };
  },
  props: {
    attachments: {
      default: () => [],
      type: Array
    },
    applicationId: String
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    async downloadFile(attachment) {
      try {
        const config = {
          responseType: "arraybuffer"
        };

        const response = await api.v1.applications.attachments(
          this.applicationId,
          attachment.attachmentId,
          config
        );

        if (response.status === 200) {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"]
          });

          //insert temporary link in DOM and trigger click to download file
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = attachment.filename;
          link.click();
        }
      } catch (error) {
        await handleError(error);
      }
    }
  }
};
</script>