<template>
  <v-text-field
    v-model="displayValue"
    :name="name"
    :error-messages="errorMessages"
    :disabled="disabled"
    :aria-label="ariaLabel"
    :label="label"
    :messages="messages"
    inputmode="numeric"
    @input="update"
    @blur="cleanInputChars"
    outlined
    dense
  >
  <template v-slot:message="data" v-if="messages && messages.length">
    <span class="warning--text">{{ data.message }}</span>
  </template>
  </v-text-field>
</template>
<script>
export default {
  name: "IntegerTextField",
  data() {
    return {
      displayValue: ""
    };
  },
  props: {
    value: {
      validator: prop => typeof prop === "number" || typeof prop === "string" || prop === null || prop === undefined,
      required: true
    },
    errorMessages: {
      validator: prop => typeof prop === "string" || prop === null || prop === undefined || Array.isArray(prop)
    },
    disabled: Boolean,
    ariaLabel: String,
    name: String,
    label: String,
    messages: {
      validator: prop => typeof prop === "string" || prop === null || prop === undefined || Array.isArray(prop)
    }
  },
  methods: {
    update: function() {
      this.$emit("input", this.displayValue);
    },
    cleanInputChars(){
      const regex = /[^0-9-]/gi;

      this.displayValue = this.displayValue.toString().replace(regex, '');

      const integerValue = parseInt(this.displayValue, 10);
      if (integerValue){
        this.displayValue = integerValue.toString();
      }

      this.$emit("input", this.displayValue);
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.displayValue = value;
      }
    }
  },
  mounted(){
      if (this.value) {
        this.displayValue = this.value;
      }
  }
};
</script>