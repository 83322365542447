const LogEventTypes = {
    ApplicationCreated: 0,
    ApplicationSendForApproval: 1,
    ApplicationRejectedByOrg: 2,
    ApplicationApprovedByOrg: 3,
    ApplicationApprovedForPayment: 4,
    ApplicationPaidByOrg: 5,
    ApplicationPaymentReset: 6
};


Object.freeze(LogEventTypes);

export default LogEventTypes;